import { GridRenderCellParams } from '@mui/x-data-grid';

import { User, Invoice } from 'openapi';

import { ListHoverGridCell } from 'components/shared/gridCells/ListHoverGridCell';

import {
  COLUMNS_DEFAULT_OPTIONS,
  ACTIONS,
  AMOUNT_MAX,
  AMOUNT_MIN
} from 'utils/constants/constants';
import { formatNumberWithSpaces, formatMoney } from 'utils/helpers/moneyHelper';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getAmountApprovalsColumns = (translate: TranslateFunction) => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'value',
      headerName: `${translate('labels.amount')} BGN`,
      minWidth: 150,
      maxWidth: 200,
      renderCell: ({ value }: { value: number }) => (
        <>{formatNumberWithSpaces(value)}</>
      )
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvers',
      headerName: translate('labels.approvers'),
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const approversList = row.approvers.map(
          (approver: User) => approver.name
        );
        return <ListHoverGridCell list={approversList} />;
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      align: 'right',
      headerAlign: 'right',
      sortable: false
    }
  ];
};

export const getCounterpartiesApprovalsColumns = (
  translate: TranslateFunction
) => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'name',
      headerName: translate('labels.name'),
      minWidth: 250,
      maxWidth: 500
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvers',
      headerName: translate('labels.approvers'),
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const approversList = row.approvers.map(
          (approver: User) => approver.name
        );
        return <ListHoverGridCell list={approversList} />;
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvalThreshold',
      headerName: translate('labels.autoApprovalThreshold'),
      flex: 0.5,
      valueFormatter: (params: any) =>
        params.value
          ? formatMoney(params.value as number)
          : translate('labels.disabled'),
      minWidth: 120,
      maxWidth: 150
    }
  ];
};

export const validateAmount = (value: number | undefined) => {
  if (
    value === undefined ||
    value === null ||
    value === 0 ||
    value.toString() === '0' ||
    value.toString() === ''
  ) {
    return true;
  }

  const isInRange = value >= AMOUNT_MIN && value <= AMOUNT_MAX;

  return isInRange || 'error';
};

export const getApprovalModalContent = (
  isInvoiceApproved: boolean,
  invoice: Invoice,
  translate: any
) => {
  if (isInvoiceApproved) {
    return translate('messages.returnInvoiceConfirmation');
  }
  const { invoiceApprovalNeeded } = invoice;

  // Check if each required approval is obtained
  const isAmountApproved =
    invoiceApprovalNeeded?.isAmount || invoice.isAmountApproved;
  const isCounterpartyApproved =
    invoiceApprovalNeeded?.isCounterparty || invoice.isCounterpartyApproved;
  const isExpenseTypeApproved =
    invoiceApprovalNeeded?.isExpenseType || invoice.isExpenseTypeApproved;

  // The invoice can be moved to the next status if all required approvals are obtained

  if (isAmountApproved && isCounterpartyApproved && isExpenseTypeApproved) {
    return translate('messages.approveInvoiceConfirmation');
  }

  // Array to hold the types that need approval
  const typesToApprove: string[] = [];

  // Check each approval type and add to the array if needed
  if (invoiceApprovalNeeded?.isAmount) {
    typesToApprove.push(translate('labels.amount'));
  }

  if (invoiceApprovalNeeded?.isCounterparty) {
    typesToApprove.push(translate('labels.counterparty'));
  }

  if (invoiceApprovalNeeded?.isExpenseType) {
    typesToApprove.push(translate('labels.expenseType'));
  }

  // Join the types into a string, handling commas and 'and' for readability
  const typesString = typesToApprove.join(', ');

  // Use the translation key with the types string
  return translate('messages.approveTheFollowing', { types: typesString });
};

export const getApprovalStatus = (
  isApproved: boolean,
  approvedBy: string | null | undefined,
  approvers: string[] | undefined,
  translate: (key: string) => string
): { approvalText: string; approvalValue: string } => {
  if (isApproved) {
    if (approvedBy) {
      // Case: Approved By
      const approvalText = `${translate('labels.approvedBy')}: ${approvedBy}`;
      const approvalValue = approvedBy;
      return { approvalText, approvalValue };
    }
    // Case: Auto Approved
    const autoApproved = translate('labels.autoApproved');
    return { approvalText: autoApproved, approvalValue: autoApproved };
  }
  // Case: Awaiting Approval From
  const approversList = approvers?.join(', ') || '-';
  const approvalText = `${translate(
    'labels.awaitingApprovalFrom'
  )}: ${approversList}`;
  return { approvalText, approvalValue: approversList };
};
