import { createBrowserHistory } from 'history';
import { useContext } from 'react';

import { showErrorToastMessage } from 'components/shared/Toast/Toast';

import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';

import { ID_ERROR } from 'utils/constants/identifiers';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { Error } from 'utils/interfaces/ErrorProps';
import { AppRoutesEnum } from 'utils/routes';

export const useErrorResultHandler = () => {
  const { handleLogout } = useContext(TokenExpirationModalContext);

  const handleErrorResponse = async (responseError: any) => {
    const customHistory = createBrowserHistory();

    const error = responseError[ID_ERROR]?.error as Error;

    switch (responseError) {
      case HttpStatusCodes.Forbidden:
        customHistory.replace(AppRoutesEnum.HOME);
        return showErrorToastMessage(error.detail);
      case HttpStatusCodes.Unauthorized:
        return handleLogout();
      default:
        return showErrorToastMessage(error.detail);
    }
  };

  return {
    handleErrorResponse
  };
};
