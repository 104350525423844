/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginResponse } from '../models/LoginResponse';
import type { RegisterResponse } from '../models/RegisterResponse';
import type { UserListResponse } from '../models/UserListResponse';
import type { UserManage } from '../models/UserManage';
import type { UserManageListResponse } from '../models/UserManageListResponse';
import type { UserManageResponse } from '../models/UserManageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * This is a user registration method
     * @returns RegisterResponse CREATED
     * @throws ApiError
     */
    public static register(): CancelablePromise<RegisterResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/register',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a method returning user's login data
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public static login(): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/login',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                417: `EXPECTATION FAILED`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns list of approvers' by company id
     * @param id The company id
     * @returns UserListResponse OK
     * @throws ApiError
     */
    public static getApproversByCompanyId(
        id: number,
    ): CancelablePromise<UserListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/approvers',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get all users
     * @returns UserManageListResponse OK
     * @throws ApiError
     */
    public static getAllUsers(): CancelablePromise<UserManageListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create user
     * @param requestBody The user object
     * @returns UserManageResponse CREATED
     * @throws ApiError
     */
    public static createUser(
        requestBody: UserManage,
    ): CancelablePromise<UserManageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple users
     * @param userIds List of user ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteUsersByIds(
        userIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users',
            query: {
                'userIds': userIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update user
     * @param id The user id
     * @param requestBody The user object
     * @returns UserManageResponse OK
     * @throws ApiError
     */
    public static updateUserById(
        id: number,
        requestBody: UserManage,
    ): CancelablePromise<UserManageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of users in a give company by id
     * @param companyId The company id
     * @returns UserListResponse OK
     * @throws ApiError
     */
    public static getAllUsersAndSuperUsersByCompanyId(
        companyId: number,
    ): CancelablePromise<UserListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/users',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
