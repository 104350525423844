import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList
} from '@mui/material';

interface PopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: (event: MouseEvent | TouchEvent) => void;
  children: React.ReactNode;
}

export const CustomPopper: React.FC<PopperProps> = ({
  open,
  anchorEl,
  handleClose,
  children
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{ zIndex: '10' }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom'
          }}
        >
          <Paper elevation={4}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open}>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
