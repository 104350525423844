import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  MobileStepper,
  Typography
} from '@mui/material';

interface StepperProps {
  steps: Array<string | number>;
  activeStepIndex: number;
  setActiveStep: (step: number) => void;
}

export const Stepper = ({
  steps,
  activeStepIndex,
  setActiveStep
}: StepperProps) => {
  return (
    <Box
      sx={{
        margin: 'auto',
        width: '20%',
        '.MuiMobileStepper-dots': { display: 'none' }
      }}
    >
      <Grid container columns={1} sx={{ position: 'relative' }}>
        <Typography
          variant="h2"
          sx={{
            position: 'absolute',
            top: '50%',
            width: '100%',
            transform: 'translateY(-50%)'
          }}
        >
          {steps[activeStepIndex]}
        </Typography>
        <MobileStepper
          sx={{ width: '100%' }}
          steps={steps.length}
          position="static"
          activeStep={activeStepIndex}
          nextButton={
            <IconButton
              onClick={() => setActiveStep(activeStepIndex + 1)}
              color="primary"
              disabled={activeStepIndex === steps.length - 1}
            >
              <KeyboardArrowRight fontSize="large" />
            </IconButton>
          }
          backButton={
            <IconButton
              onClick={() => setActiveStep(activeStepIndex - 1)}
              disabled={activeStepIndex === 0}
              color="primary"
            >
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
          }
        />
      </Grid>
    </Box>
  );
};
