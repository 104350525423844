/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CounterpartyApprovalListResponse } from '../models/CounterpartyApprovalListResponse';
import type { CounterpartyApprovalPatch } from '../models/CounterpartyApprovalPatch';
import type { CounterpartyApprovalResponse } from '../models/CounterpartyApprovalResponse';
import type { CounterpartyCreateUpdate } from '../models/CounterpartyCreateUpdate';
import type { CounterpartyExistCheckResponse } from '../models/CounterpartyExistCheckResponse';
import type { CounterpartyFiltersResponse } from '../models/CounterpartyFiltersResponse';
import type { CounterpartyPageResponse } from '../models/CounterpartyPageResponse';
import type { CounterpartyQueryFilters } from '../models/CounterpartyQueryFilters';
import type { CounterpartyResponse } from '../models/CounterpartyResponse';
import type { IdList } from '../models/IdList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CounterpartyService {

    /**
     * Create a new counterparty for company
     * @param id The company id
     * @param requestBody The counterparty object
     * @returns CounterpartyResponse CREATED
     * @throws ApiError
     */
    public static createCounterparty(
        id: number,
        requestBody: CounterpartyCreateUpdate,
    ): CancelablePromise<CounterpartyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/counterparties',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple counterparties for company
     * @param id The company id
     * @param counterpartyIds List of counterparty ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteCounterpartiesByIds(
        id: number,
        counterpartyIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}/counterparties',
            path: {
                'id': id,
            },
            query: {
                'counterpartyIds': counterpartyIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of filtered counterparties by company id
     * @param id The company id
     * @returns CounterpartyFiltersResponse OK
     * @throws ApiError
     */
    public static getCounterpartyFiltersByCompanyId(
        id: number,
    ): CancelablePromise<CounterpartyFiltersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/counterparties/filters',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of filtered counterparties by company id
     * @param id The company id
     * @param requestBody The counterparty object
     * @returns CounterpartyPageResponse OK
     * @throws ApiError
     */
    public static getCounterpartiesByCompanyIdAndFilters(
        id: number,
        requestBody: CounterpartyQueryFilters,
    ): CancelablePromise<CounterpartyPageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/counterparties/filters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update counterparty for company
     * @param id The company id
     * @param counterpartyId The counterparty id
     * @param requestBody The counterparty object
     * @returns CounterpartyResponse OK
     * @throws ApiError
     */
    public static updateCounterparty(
        id: number,
        counterpartyId: number,
        requestBody: CounterpartyCreateUpdate,
    ): CancelablePromise<CounterpartyResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}/counterparties/{counterpartyId}',
            path: {
                'id': id,
                'counterpartyId': counterpartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get the list of approvals for a counterparty
     * @param companyId The company id
     * @returns CounterpartyApprovalListResponse OK
     * @throws ApiError
     */
    public static getCounterpartyApprovals(
        companyId: number,
    ): CancelablePromise<CounterpartyApprovalListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/counterparties/approvals',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get the approval for a counterparty
     * @param companyId The company id
     * @param counterpartyId The counterparty id
     * @returns CounterpartyApprovalResponse OK
     * @throws ApiError
     */
    public static getCounterpartyApprovalById(
        companyId: number,
        counterpartyId: number,
    ): CancelablePromise<CounterpartyApprovalResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/counterparties/approvals/{counterpartyId}',
            path: {
                'companyId': companyId,
                'counterpartyId': counterpartyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update the approval for a counterparty
     * @param companyId The company id
     * @param counterpartyId The counterparty id
     * @param requestBody The counterparty approval object
     * @returns CounterpartyApprovalResponse OK
     * @throws ApiError
     */
    public static updateCounterpartyApprovalById(
        companyId: number,
        counterpartyId: number,
        requestBody: CounterpartyApprovalPatch,
    ): CancelablePromise<CounterpartyApprovalResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/companies/{companyId}/counterparties/approvals/{counterpartyId}',
            path: {
                'companyId': companyId,
                'counterpartyId': counterpartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns whether the counterparty exists or not
     * @param companyId The company id
     * @param name The counterparty name
     * @param registrationNumber The counterparty registration number
     * @param vatNumber The counterparty vat number
     * @returns CounterpartyExistCheckResponse OK
     * @throws ApiError
     */
    public static checkCounterpartyExist(
        companyId: number,
        name: string,
        registrationNumber: string,
        vatNumber: string,
    ): CancelablePromise<CounterpartyExistCheckResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/counterparties/exist-check',
            path: {
                'companyId': companyId,
            },
            query: {
                'name': name,
                'registrationNumber': registrationNumber,
                'vatNumber': vatNumber,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method set a counterparty as validated
     * @param companyId The company id
     * @param requestBody The expense type id
     * @returns void
     * @throws ApiError
     */
    public static validateCounterparties(
        companyId: number,
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/counterparties/validate',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
