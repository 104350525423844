/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentChannel {
    ALTERNATIVE = 'ALTERNATIVE',
    BANK = 'BANK',
    CASH = 'CASH',
    COMPANY_CARD = 'COMPANY_CARD',
}
