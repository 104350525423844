import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Tooltip
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CompanyIban } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { formatMoney } from 'utils/helpers/moneyHelper';
import { ExportForPaymentForm } from 'utils/interfaces/ExportForPaymentForm';

interface SelectIbanFormProps {
  readonly companyIbans: CompanyIban[];
  readonly onSubmit: (values: ExportForPaymentForm) => void;
}

export const SelectIbanForm = ({
  companyIbans,
  onSubmit
}: SelectIbanFormProps) => {
  const { translate } = useTranslations();

  const ibanOptions = useMemo(() => {
    return companyIbans.filter((iban) => iban.isExportable);
  }, [companyIbans]);

  const { control, formState, handleSubmit, setValue } =
    useForm<ExportForPaymentForm>({
      mode: 'onChange'
    });

  useEffect(() => {
    if (ibanOptions.length === 1 && ibanOptions[0].id) {
      setValue('ibanId', ibanOptions[0].id, { shouldValidate: true });
    }
  }, [ibanOptions, setValue]);

  const exportButtonTooltip = useMemo(() => {
    if (!formState.isValid) {
      return translate('errors.ibanSelected');
    }

    return '';
  }, [formState.isValid, translate]);

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="ibanId"
          control={control}
          rules={{
            required: translate('errors.iban')
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} sx={{ width: '100%' }}>
              <InputLabel>{`${translate('labels.ibanSelect')}*`}</InputLabel>
              <Select
                {...field}
                sx={{ textAlign: 'left' }}
                label={`${translate('labels.ibanSelect')}*`}
                error={!!error}
                value={field.value || ''}
                fullWidth
              >
                {ibanOptions &&
                  ibanOptions.map((iban: CompanyIban) => (
                    <MenuItem key={iban.iban} value={iban.id}>
                      {iban.balance
                        ? `${iban.iban} (${formatMoney(iban.balance)} ${
                            iban.currency
                          })`
                        : iban.iban}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {error && translate('errors.ibanSelected')}
              </FormHelperText>
            </FormControl>
          )}
        />
        <Tooltip title={exportButtonTooltip}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
            <Button
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
            >
              {translate('buttons.export')}
            </Button>
          </Box>
        </Tooltip>
      </form>
    </Box>
  );
};
