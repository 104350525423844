import { GridValueFormatterParams } from '@mui/x-data-grid';
import moment from 'moment';

import { ACTIONS, COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getUserManagementColumns = (translate: TranslateFunction) => [
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: 'name',
    headerName: translate('labels.name')
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: 'email',
    headerName: translate('labels.email')
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: 'role',
    headerName: translate('labels.roles'),
    flex: 0.4
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: 'companyIds',
    headerName: translate('labels.companies')
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: 'lastActiveAt',
    headerName: translate('labels.lastActiveAt'),
    valueFormatter: ({ value }: GridValueFormatterParams<string | undefined>) =>
      value ? moment(value).fromNow() : '-',
    flex: 0.5
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: ACTIONS,
    headerName: '',
    sortable: false,
    flex: 0.1
  }
];
