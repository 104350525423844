import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, Box, ThemeProvider, createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { useUserController } from 'api/controllers/UserController';

import { AppRoutes } from 'components/AppRoutes/AppRoutes';
import { Header } from 'components/Header/Header';
import { Sidebar } from 'components/Sidebar/Sidebar';

import { CompaniesProvider } from 'context/CompanyContext';
import PermissionsProvider from 'context/PermissionsContext';
import { TokenExpirationModalProvider } from 'context/TokenExpirationModalProvider';
import {
  TranslationsProvider,
  useTranslations
} from 'context/TranslationContext';

import { LANGUAGE_TO_LOCALE } from 'utils/constants/constants';
import { PermissionsObject } from 'utils/interfaces/Permissions';
import { mapPermissions } from 'utils/mappers/permissions';

import {
  appStyle,
  displayWrapper,
  expandedSidebarGrid,
  retractedSidebarGrid,
  routesWrapper
} from 'styles/components/AppStyle';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH
} from 'styles/components/Common';
import lightTheme from 'styles/theme/light-theme';

import { userAtom } from 'state/state';

export const App: React.FC = () => {
  const [permissions, setPermissions] = useState<PermissionsObject | null>(
    null
  );
  const [user, setUser] = useAtom(userAtom);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(true);

  const { currentLanguage } = useTranslations();
  const themeWithLocale = createTheme(
    lightTheme,
    locales[LANGUAGE_TO_LOCALE[currentLanguage] as keyof typeof locales]
  );

  const { userLogin } = useUserController();

  const getUserInfo = useCallback(async () => {
    const userData = await userLogin();

    setUser(userData);
    setPermissions(mapPermissions(userData.permissions));
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    permissions && (
      <>
        <CssBaseline />
        <PermissionsProvider initialPermissions={permissions}>
          <TranslationsProvider>
            <CompaniesProvider>
              <ThemeProvider theme={themeWithLocale}>
                <TokenExpirationModalProvider>
                  <Box sx={appStyle}>
                    <ToastContainer newestOnTop />
                    <Box
                      sx={
                        isSidebarExpanded
                          ? { ...displayWrapper, ...expandedSidebarGrid }
                          : { ...displayWrapper, ...retractedSidebarGrid }
                      }
                    >
                      <Box>
                        <Sidebar
                          setSidebarExpanded={setIsSidebarExpanded}
                          isSidebarExpanded={isSidebarExpanded}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: isSidebarExpanded
                            ? `calc(100dvw - ${SIDEBAR_EXPANDED_WIDTH})`
                            : `calc(100dvw - ${SIDEBAR_CLOSED_WIDTH})`,
                          transition: '0.5s'
                        }}
                      >
                        <Header />
                        <Box sx={routesWrapper}>
                          <AppRoutes />
                        </Box>
                      </Box>
                    </Box>
                    <Box id="modal" />
                  </Box>
                </TokenExpirationModalProvider>
              </ThemeProvider>
            </CompaniesProvider>
          </TranslationsProvider>
        </PermissionsProvider>
      </>
    )
  );
};
