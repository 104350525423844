import { TypographyVariant } from '@mui/material';

import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH
} from 'styles/components/Common';

export const sidebarWrapper = {
  top: '0',
  left: '0',
  overflowX: 'hidden',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  fontWeight: 600,
  fontFamily: 'Public Sans sans-serif',
  color: 'rgb(38, 38, 38)',
  overflowY: 'auto',
  borderRight: '1px dashed #0000001f',
  transition: 'width 0.5s',
  display: 'flex',
  flexDirection: 'column',

  '&::-webkit-scrollbar': {
    width: '0.4em'
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey'
  },

  hr: {
    borderStyle: 'dashed',
    margin: '0 1rem'
  }
};

export const sidebarOpen = {
  top: '0',
  left: '0',
  width: SIDEBAR_EXPANDED_WIDTH
};

export const sidebarClosed = {
  width: SIDEBAR_CLOSED_WIDTH
};

export const sidebarButtonExpanded = {
  left: SIDEBAR_EXPANDED_WIDTH,

  transform: 'translateX(-50%)'
};

export const sidebarButtonCollapsed = {
  left: SIDEBAR_CLOSED_WIDTH,
  transform: 'translateX(-50%)'
};

export const tabText = {
  visibility: 'visible',
  opacity: '1',
  transition: 'opacity 0.4s linear, visibility 0.4s linear',
  whiteSpace: 'nowrap'
};

export const tabTextRemoved = {
  visibility: 'hidden',
  opacity: '0',
  transition: 'opacity 0.2s linear, visibility 0.2s linear',
  whiteSpace: 'nowrap'
};

export const sidebarExpandButton = {
  zIndex: 2,
  position: 'fixed',
  bottom: '1rem',
  border: '1px dashed #0000001f',
  width: '2rem',
  height: '2rem',
  minWidth: '2rem',
  minHeight: '2rem',
  borderRadius: '50%',
  backgroundColor: '#ffffff !important',
  transition: 'left 0.5s'
};

export const sidebarMainList = {
  padding: '0'
};

export const selectedTabStyles = {
  margin: '0.7rem',
  color: 'rgb(7, 141, 238)',
  backgroundColor: 'rgba(7, 141, 238, 0.08)',
  borderRadius: '0.7rem',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  '&:hover': {
    backgroundColor: 'rgba(7, 141, 238, 0.16)'
  }
};

export const tabStyle = {
  margin: '0.7rem',
  borderRadius: '0.7rem',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  }
};

export const icon = {
  minWidth: '40px'
};

export const selectedIcon = {
  minWidth: '40px',
  color: 'rgb(7, 141, 238)'
};

export const logoStyle = {
  maxWidth: '196px',
  height: '32px',
  margin: '0',
  padding: '0',
  color: 'rgb(7, 141, 238)'
};

export const sidebarIconStyle = {
  width: '30px',
  height: '30px'
};

export const sidebarIconStyleSmall = {
  width: '25px',
  height: '25px'
};

export const sidebarIconStyleSelected = {
  color: 'rgb(7, 141, 238)'
};

export const sidebarLightTheme = {
  typography: {
    fontFamily: 'Regular',
    fontSize: 14
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body1' as TypographyVariant },
          style: {
            fontFamily: 'Regular'
          }
        },
        {
          props: { variant: 'body2' as TypographyVariant },
          style: {
            fontFamily: 'Medium',
            fontSize: 16
          }
        }
      ]
    }
  }
};
