import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import { useAtom } from 'jotai';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { ExpenseType, ExpenseTypeCreate, User } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import {
  getApproverIdsDefaultValue,
  getMappedInitialValues
} from 'utils/helpers/expenseTypeHelpers';
import { processMoneyInput } from 'utils/helpers/moneyHelper';
import { mapExpenseTypeValues } from 'utils/mappers/expenseType';

import { formSmallContainer, submitButton } from 'styles/components/Common';

import { userAtom } from 'state/state';

interface CreateUpdateExpenseTypeFormProps {
  handleSubmit: (values: ExpenseTypeCreate) => void;
  initialValues?: ExpenseType;
  approvers: User[];
  ignoreCreating?: boolean;
}

export const CreateUpdateExpenseTypeForm = ({
  initialValues,
  handleSubmit,
  approvers,
  ignoreCreating
}: CreateUpdateExpenseTypeFormProps) => {
  const { translate } = useTranslations();
  const [user] = useAtom(userAtom);

  const [formValues] = useState<ExpenseTypeCreate>(
    mapExpenseTypeValues(initialValues)
  );
  const [thresholdValue, setThresholdValue] = useState<string>('0');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().trim().required(translate('errors.expenseTypeName')),
        approvalThreshold: Yup.number()
          .min(0, translate('errors.expenseTypeThresholdMin'))
          .required(translate('errors.expenseTypeThreshold')),
        approverIds: Yup.array()
          .of(Yup.number())
          .min(1, translate('errors.expenseTypeApprover'))
      }),
    [translate]
  );

  const handleThresholdValueChange = (
    value: string,
    setFieldValue: (
      string: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<ExpenseType>>
  ) => {
    setThresholdValue(value);
    setFieldValue('approvalThreshold', value);
  };

  const approverIdsDefaultValue = getApproverIdsDefaultValue(
    initialValues,
    approvers,
    user?.id as number
  );

  const mappedInitialValues = getMappedInitialValues(
    initialValues,
    formValues,
    approvers,
    user?.id as number
  );

  useEffect(() => {
    setThresholdValue(initialValues?.approvalThreshold?.toString() || '0');
  }, [initialValues]);

  return (
    <Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={mappedInitialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ touched, errors, setFieldValue, values }) => (
          <Form noValidate>
            <Box style={{ ...formSmallContainer, flexDirection: 'column' }}>
              <Field
                sx={{ height: 65 }}
                as={TextField}
                label={translate('labels.expenseTypeName')}
                name="name"
                helperText={<ErrorMessage name="name" />}
                error={touched && !!errors.name}
                required
                disabled={ignoreCreating}
              />
              <Field
                sx={{ height: 65 }}
                as={TextField}
                label={translate('labels.expenseTypeThreshold')}
                name="approvalThreshold"
                value={thresholdValue}
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) =>
                  handleThresholdValueChange(
                    processMoneyInput(
                      e.target.value,
                      values.approvalThreshold.toString()
                    ),
                    setFieldValue
                  )
                }
                helperText={<ErrorMessage name="approvalThreshold" />}
                error={touched && !!errors.approvalThreshold}
                required
              />
              <FormControl
                error={!!(touched.approverIds && errors.approverIds)}
              >
                <Autocomplete
                  defaultValue={approverIdsDefaultValue}
                  multiple
                  options={approvers}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name!}
                  onChange={(e, value) => {
                    setFieldValue(
                      'approverIds',
                      value.map((v) => v.id)
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={!!(touched.approverIds && errors.approverIds)}
                      {...params}
                      label={translate('labels.approvers')}
                    />
                  )}
                />

                <FormHelperText sx={{ height: 5 }}>
                  {touched.approverIds &&
                    errors.approverIds &&
                    errors.approverIds}
                </FormHelperText>
              </FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant="contained" type="submit" sx={submitButton}>
                  {translate('buttons.save')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
