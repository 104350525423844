import { Box } from '@mui/material';

import { Navigation } from 'components/Navigation/Navigation';

import { appHeader, headerWrapper } from 'styles/components/HeaderStyle';

export const Header: React.FC = (): React.JSX.Element => {
  return (
    <Box component="header" sx={appHeader}>
      <Box sx={headerWrapper}>
        <Navigation />
      </Box>
    </Box>
  );
};
