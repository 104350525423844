/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CompanyListResponse } from '../models/CompanyListResponse';
import type { CompanyResponse } from '../models/CompanyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompanyService {

    /**
     * This method returns the list of companies
     * @returns CompanyListResponse OK
     * @throws ApiError
     */
    public static getCompanies(): CancelablePromise<CompanyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method creates a new company
     * @param requestBody Company to add
     * @returns CompanyResponse CREATED
     * @throws ApiError
     */
    public static createCompany(
        requestBody: Company,
    ): CancelablePromise<CompanyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the company by id
     * @param id The company id
     * @returns CompanyResponse OK
     * @throws ApiError
     */
    public static getCompanyById(
        id: number,
    ): CancelablePromise<CompanyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method updates the company
     * @param id The company id
     * @param requestBody Company to update
     * @returns CompanyResponse OK
     * @throws ApiError
     */
    public static updateCompany(
        id: number,
        requestBody: Company,
    ): CancelablePromise<CompanyResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method deletes the company by id
     * @param id The company id
     * @returns void
     * @throws ApiError
     */
    public static deleteCompanyById(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
