import { Permission, ScopeHolder } from 'openapi';

import { PermissionsObject } from 'utils/interfaces/Permissions';

export const mapPermissions = (
  permissions: Permission[]
): PermissionsObject => {
  const mappedPermissions: PermissionsObject = {} as PermissionsObject;

  permissions.forEach((permission) => {
    mappedPermissions[permission.resource as keyof PermissionsObject] =
      permission.scopeHolder as ScopeHolder;
  });

  return mappedPermissions;
};
