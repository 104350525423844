import { AmountCreate, Amount } from 'openapi';

export const mapInitialValues = (initialValues?: Amount): AmountCreate => {
  if (!initialValues) {
    return {
      value: '' as unknown as number,
      approverIds: []
    };
  }

  return {
    value: initialValues.value as number,
    approverIds: initialValues.approvers.map((approver) => approver.id)
  };
};
