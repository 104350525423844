import PublishIcon from '@mui/icons-material/Publish';
import { Box } from '@mui/material';

import { useTranslations } from 'context/TranslationContext';

interface OverlayDropFilesProps {
  isReject?: boolean;
}

export const OverlayDropFiles = ({ isReject }: OverlayDropFilesProps) => {
  const { translate } = useTranslations();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        color: '#fff',
        fontSize: '2rem',
        fontWeight: 'bold'
      }}
    >
      <PublishIcon sx={{ ml: 1, fontSize: '8rem' }} />
      {translate('labels.dropFiles')}
      {isReject && (
        <Box sx={{ fontSize: '1.3rem', color: '#f44336' }}>
          {translate('warnings.supportedFormats')}
        </Box>
      )}
    </Box>
  );
};
