import { UserManage, UserRoles } from 'openapi';

export const mapUserValues = (user?: UserManage) => {
  if (!user) {
    return {
      email: '',
      name: '',
      role: UserRoles.USER,
      companyIds: []
    };
  }
  return {
    email: user.email,
    name: user.name,
    role: user.role,
    companyIds: user.companyIds
  };
};
