import { ExpenseType, ExpenseTypeCreate } from 'openapi';

export const mapExpenseTypeValues = (
  expenseType?: ExpenseType
): ExpenseTypeCreate => {
  if (!expenseType) {
    return {
      name: '',
      approvalThreshold: 0,
      approverIds: []
    };
  }

  const values = {
    id: expenseType.id,
    name: expenseType.name,
    approvalThreshold: expenseType.approvalThreshold || 0,
    approverIds: expenseType.approvers?.map((app) => app.id) || []
  };

  return values;
};
