import { useEffect, useRef, useCallback } from 'react';

type CallbackFunction = (...args: any[]) => void;

export const useDebounce = (
  callback: CallbackFunction,
  delay: number
): CallbackFunction => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedCallback: CallbackFunction = useCallback(
    (...args: any[]) => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedCallback;
};
