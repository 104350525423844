import { Skeleton, SkeletonProps } from '@mui/material';

export const Loader: React.FC = (props: SkeletonProps): JSX.Element => {
  return (
    <Skeleton
      variant="rectangular"
      width={1920}
      height={634}
      animation="wave"
      sx={{ margin: 'auto' }}
      {...props}
    />
  );
};
