import { useCallback } from 'react';

import { DropDownHelperService, ExpenseTypeService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useAdditionalFieldsController = () => {
  const { interceptRequest } = useInterceptor();

  const { getAllDropDownExpenseTypes } = ExpenseTypeService;

  const {
    getAllCurrenciesForDropDown,
    getAllDropDownCounterparties,
    getAllTagsByCompanyIdForDropDown
  } = DropDownHelperService;

  const getExpenseTypes = useCallback(
    (id: number) => interceptRequest(getAllDropDownExpenseTypes, {}, id),
    [getAllDropDownExpenseTypes]
  );

  const getCurrencies = useCallback(
    () => interceptRequest(getAllCurrenciesForDropDown, {}),
    [getAllCurrenciesForDropDown]
  );

  const getAllDropDownCounterpartiesByCompany = useCallback(
    (searchParam?: string, companyId?: number) =>
      interceptRequest(
        getAllDropDownCounterparties,
        {},
        searchParam,
        companyId
      ),
    [getAllDropDownCounterparties]
  );

  const getAllTagsByCompanyId = useCallback(
    (companyId: number) =>
      interceptRequest(getAllTagsByCompanyIdForDropDown, {}, companyId),
    [getAllTagsByCompanyIdForDropDown]
  );

  return {
    getExpenseTypes,
    getCurrencies,
    getAllDropDownCounterpartiesByCompany,
    getAllTagsByCompanyId
  };
};
