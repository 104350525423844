import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  IconButton,
  Tooltip,
  Switch
} from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import {
  Counterparty,
  CounterpartyCreateUpdate,
  CounterpartyDropDown,
  ExpenseType,
  Iban
} from 'openapi';

import { VerifyCounterPartyIbanButton } from 'components/shared/buttons/VerifyCounterPartyIbanButton/VeryfyCounterPartyIbanButton';

import { useTranslations } from 'context/TranslationContext';

import { useDebounce } from 'hooks/useDebounce';

import {
  INITIAL_IBAN_ITEM,
  COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS
} from 'utils/constants/constants';
import { COUNTERPARTY_SEARCH_MIN_CHARACTERS } from 'utils/constants/invoices';
import { uniqueIbanValidation } from 'utils/helpers/ibanHelper';
import {
  getCounterPartySearchOptionLabel,
  validateIban,
  validateRegistrationNumber,
  validateVatNumber
} from 'utils/helpers/invoiceHelpers';
import { mapCounterPartyValues } from 'utils/mappers/counterParty';

import {
  submitButton,
  arrayFieldFormContainer
} from 'styles/components/Common';
import {
  verificationFormFieldsWrapper,
  verificationFormItem,
  smallVerificationFieldWidth,
  verificationFormFields
} from 'styles/pages/InvoiceVerificationStyle';

interface CreateUpdateCounterPartyProps {
  readonly handleSubmit: (values: CounterpartyCreateUpdate) => void;
  readonly initialValues?: Counterparty;
  readonly expenseTypeOptions: ExpenseType[];
}

export const CreateUpdateCounterParty = ({
  initialValues,
  handleSubmit: handleFormSubmit,
  expenseTypeOptions
}: CreateUpdateCounterPartyProps) => {
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const { getAllDropDownCounterpartiesByCompany } =
    useAdditionalFieldsController();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CounterpartyDropDown[]>([]);
  const [searchParams, setSearchParams] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const notEnoughCharactersToSearch = useMemo(() => {
    return (searchParams || '').length < COUNTERPARTY_SEARCH_MIN_CHARACTERS;
  }, [searchParams]);

  const getCounterParties = useCallback(async () => {
    if (notEnoughCharactersToSearch) {
      setIsLoading(false);
      setOptions([]);
      return;
    }
    const result = await getAllDropDownCounterpartiesByCompany(
      searchParams,
      Number(companyId)
    );
    const filteredCounterparties = result.filter(
      (counterparty: CounterpartyDropDown) =>
        !counterparty.isCompanyCounterparty
    );
    setOptions(filteredCounterparties);
    setIsLoading(false);
  }, [getAllDropDownCounterpartiesByCompany, searchParams, setOptions]);

  const debouncedSearch = useDebounce(getCounterParties, 500);

  useEffect(() => {
    setIsLoading(true);
    debouncedSearch();
  }, [searchParams, debouncedSearch]);

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    trigger
  } = useForm<CounterpartyCreateUpdate>({
    mode: 'onChange',
    defaultValues: mapCounterPartyValues(initialValues)
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'ibanList',
    keyName: 'fieldId'
  });

  const watchedCounterpartyRegistrationNumber = watch('registrationNumber');
  const watchedCounterpartyVatNumber = watch('vatNumber');
  const watchedWithoutRegistrationNumber = watch('isWithoutRegistrationNumber');
  const watchedIbanList = watch('ibanList');
  const watchedIsValidated = watch('isValidated');

  // Validation logic for the registration number and vat number
  const isCounterpartyRegistrationNumberValid = useMemo(
    () =>
      validateRegistrationNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [watchedCounterpartyRegistrationNumber, watchedCounterpartyVatNumber]
  );

  const isCounterpartyVatValid = useMemo(
    () =>
      validateVatNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [
      watchedCounterpartyVatNumber,
      watchedCounterpartyRegistrationNumber,
      isCounterpartyRegistrationNumberValid
    ]
  );

  const handleAutocompleteChange = useCallback(
    (value: string, event?: SyntheticEvent<Element, Event>) => {
      let counterpartyName;
      if (event) {
        const [inputValue] = (event.target as HTMLInputElement).value.split(
          ' ('
        );
        counterpartyName = inputValue;
      } else {
        const [inputValue] = value.split(' (');
        counterpartyName = inputValue;
      }

      const selectedOption = options.find(
        (option: CounterpartyDropDown) => option.name === counterpartyName
      );

      if (!selectedOption) {
        return;
      }

      setValue('name', selectedOption.name || '');
      setValue('registrationNumber', selectedOption.registrationNumber || '');
      setValue(
        'isWithoutRegistrationNumber',
        selectedOption.isWithoutRegistrationNumber || false
      );
      setValue('vatNumber', selectedOption.vatNumber || '');
      clearErrors();
    },
    [options, setValue, clearErrors]
  );

  const [selectedIbanId, setSelectedIbanId] = useState<string | undefined>();

  useEffect(() => {
    const validIban = watchedIbanList?.find(
      (item) => item.isSelectedForPayment
    );
    if (validIban) {
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      setSelectedIbanId(validIban.tmpId);
    }
  }, []);

  const appendIban = () => {
    append({
      ...INITIAL_IBAN_ITEM,
      ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      tmpId: uuidv4()
    });
  };

  const removeIban = (index: number) => {
    remove(index);
    trigger();
  };

  const updateCounterPartyIban = useCallback(
    (newIban: Iban) => {
      const value = !newIban.isSelectedForPayment;
      const updatedIbanList = watchedIbanList?.map((item) => {
        // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
        return item.tmpId === newIban.tmpId
          ? {
              ...newIban,
              isSelectedForPayment: value
            }
          : { ...item, isSelectedForPayment: false };
      });

      setValue('ibanList', updatedIbanList);
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      setSelectedIbanId(value ? newIban.tmpId : undefined);
    },
    [setSelectedIbanId, setValue, watchedIbanList]
  );

  useEffect(() => {
    if (watchedWithoutRegistrationNumber) {
      setValue('registrationNumber', '');
    }
  }, [watchedWithoutRegistrationNumber]);

  useEffect(() => {
    trigger();
  }, [trigger, formState.isValid]);

  return (
    <Box sx={{ pt: 2.5 }}>
      <form
        className="supplier-form"
        onSubmit={handleSubmit((values) =>
          handleFormSubmit({ ...values, ibanList: watchedIbanList })
        )}
      >
        <Box sx={verificationFormFields}>
          {!initialValues && (
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                height: '55px'
              }}
            >
              <Controller
                // @ts-ignore
                name="selectedCounterPartyId"
                control={control}
                render={({ field }) => (
                  <FormControl sx={verificationFormItem}>
                    <Autocomplete
                      open={open}
                      disableClearable
                      blurOnSelect
                      clearOnBlur={false}
                      forcePopupIcon={false}
                      onInputChange={(event, value) => {
                        handleAutocompleteChange(value);
                      }}
                      onSelect={(event: SyntheticEvent) => {
                        handleAutocompleteChange('', event);
                      }}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        getCounterPartySearchOptionLabel(option)
                      }
                      options={options}
                      loading={notEnoughCharactersToSearch ? false : isLoading}
                      noOptionsText={
                        notEnoughCharactersToSearch
                          ? translate('messages.searchRequiredCharacters', {
                              numberOfCharacters: String(
                                COUNTERPARTY_SEARCH_MIN_CHARACTERS
                              )
                            })
                          : translate('labels.noCounterpartiesFound')
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label={translate('labels.counterparty')}
                          onChange={(event) => {
                            setSearchParams(event.target.value);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment sx={{ ml: 1 }} position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => {
                        return (
                          <li {...props}>
                            {getCounterPartySearchOptionLabel(option)}
                          </li>
                        );
                      }}
                    />
                  </FormControl>
                )}
              />
            </Box>
          )}
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '70px'
            }}
          >
            <FormControl sx={{ flex: 0.8 }}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={`${translate('labels.name')}*`}
                    name="name"
                    placeholder={translate('labels.name')}
                    error={!!error}
                    helperText={error && translate('errors.counterpartyName')}
                  />
                )}
              />
            </FormControl>
            <Box sx={{ flex: 0.2, alignSelf: 'center', height: '55px' }}>
              <Controller
                name="isValidated"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    control={
                      <Switch
                        {...field}
                        onChange={(event) => {
                          field.onChange(event.target.value === 'false');
                          if (event.target.value === 'true') {
                            const newIbanList = watchedIbanList?.map(
                              (iban) => ({
                                ...iban,
                                isSelectedForPayment: false
                              })
                            );
                            setValue('ibanList', newIbanList);
                            setSelectedIbanId(undefined);
                          }
                        }}
                      />
                    }
                    label={translate('labels.isValidated')}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ ...verificationFormFieldsWrapper }}>
            <Box sx={{ display: 'flex', gap: 2, flex: 0.8, height: 80 }}>
              <Controller
                name="vatNumber"
                control={control}
                rules={{
                  validate: (value) => {
                    if (value?.startsWith('BG')) {
                      return (
                        isCounterpartyVatValid ||
                        translate('errors.counterpartyVatNumberEquality')
                      );
                    }
                    return true; // No validation error for non-BG VAT numbers
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={translate('labels.vatNumber')}
                    name="vatNumber"
                    placeholder={translate('labels.vatNumber')}
                    sx={verificationFormItem}
                    error={!!field.value && !isCounterpartyVatValid}
                    helperText={
                      field.value &&
                      !isCounterpartyVatValid &&
                      translate('errors.counterpartyVatNumberEquality')
                    }
                  />
                )}
              />
              <Controller
                name="registrationNumber"
                control={control}
                rules={{
                  validate: () => {
                    if (!watchedCounterpartyVatNumber) {
                      return (
                        isCounterpartyRegistrationNumberValid ||
                        translate('errors.counterpartyRegistrationNumberBG')
                      );
                    }
                    if (watchedCounterpartyVatNumber.startsWith('BG')) {
                      return (
                        isCounterpartyRegistrationNumberValid ||
                        translate('errors.counterpartyRegistrationNumberBG')
                      );
                    }

                    return (
                      watchedCounterpartyVatNumber === '' ||
                      watchedCounterpartyRegistrationNumber === ''
                    );
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!isCounterpartyRegistrationNumberValid}
                    label={translate('labels.companyRegistrationNumber')}
                    name="registrationNumber"
                    placeholder={translate(
                      'labels.counterpartyRegistrationNumber'
                    )}
                    helperText={
                      !isCounterpartyRegistrationNumberValid &&
                      // eslint-disable-next-line no-nested-ternary
                      (watchedCounterpartyVatNumber?.startsWith('BG')
                        ? translate('errors.counterpartyRegistrationNumberBG')
                        : watchedCounterpartyVatNumber === ''
                        ? translate('errors.counterpartyRegistrationNumberBG')
                        : translate(
                            'errors.counterpartyRegistrationNumberForeign'
                          ))
                    }
                    sx={{ ...verificationFormItem, width: '100%' }}
                    disabled={watchedWithoutRegistrationNumber}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 0.2, alignSelf: 'center', height: '55px' }}>
              <Controller
                name="isWithoutRegistrationNumber"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    control={<Switch {...field} />}
                    label={translate('labels.withoutCompanyRegistrationNumber')}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={verificationFormFieldsWrapper}>
            <Controller
              name="expenseTypeId"
              control={control}
              rules={{
                required: translate('errors.expenseType')
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.expenseType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    label={`${translate('labels.expenseType')}*`}
                    error={!!error}
                    value={field.value || ''} // Ensure a fallback value
                  >
                    {expenseTypeOptions &&
                      expenseTypeOptions.map(
                        (expenseType) =>
                          expenseType.isActive && (
                            <MenuItem
                              key={expenseType.id}
                              value={expenseType.id}
                              disabled={!expenseType.isActive}
                            >
                              {expenseType.name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                  <FormHelperText>
                    {error && translate('errors.expenseType')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Box>
          <Tooltip
            title={
              !watchedIsValidated &&
              translate('messages.disabledIbanSectionInfo')
            }
            placement="top"
          >
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                minHeight: '50px',
                flexDirection: 'column',
                marginTop: 1
              }}
            >
              {watchedIbanList?.map((item, index) => (
                <Box
                  sx={{ ...arrayFieldFormContainer, alignItems: 'center' }}
                  // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
                  key={item.tmpId}
                >
                  <Box sx={{ mb: 2 }}>
                    <VerifyCounterPartyIbanButton
                      disabled={!watchedIsValidated}
                      iban={item}
                      handleToggle={() => updateCounterPartyIban(item)}
                      isSelected={
                        // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
                        !!(selectedIbanId && selectedIbanId === item.tmpId)
                      }
                    />
                  </Box>
                  <Box sx={{ ...verificationFormFields, width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 70
                      }}
                    >
                      <Controller
                        name={`ibanList.${index}.iban`}
                        control={control}
                        rules={{
                          validate: (value) =>
                            value
                              ? validateIban(value) &&
                                uniqueIbanValidation(
                                  translate,
                                  getValues(`ibanList.${index}.iban`),
                                  getValues('ibanList')
                                )
                              : true
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={!!item.id}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              trigger();
                            }}
                            label={translate('labels.iban')}
                            error={
                              !!errors.ibanList && !!errors.ibanList[index]
                            }
                            placeholder={translate('labels.iban')}
                            sx={
                              !watchedIsValidated
                                ? {
                                    ...smallVerificationFieldWidth,
                                    '& .MuiInputBase-input': {
                                      backgroundColor: '#f5f5f5', // Light grey background
                                      color: '#9e9e9e', // Grey text color
                                      cursor: 'not-allowed' // Change cursor to indicate non-editable
                                    },
                                    '& .MuiInputBase-input:focus': {
                                      backgroundColor: '#f5f5f5' // Keep background color on focus
                                    }
                                  }
                                : smallVerificationFieldWidth
                            }
                            inputProps={{ readOnly: !watchedIsValidated }}
                          />
                        )}
                      />
                      {errors.ibanList && errors.ibanList[index] && (
                        <FormHelperText error>
                          {!validateIban(
                            getValues(`ibanList.${index}.iban`) || ''
                          )
                            ? translate('errors.invalidIban')
                            : translate('errors.ibanUnique')}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 55
                    }}
                  >
                    <IconButton
                      sx={{ mb: 2 }}
                      disabled={!watchedIsValidated}
                      color="primary"
                      type="button"
                      onClick={() => removeIban(index)}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Box display="flex" justifyContent="center">
                <Button
                  disabled={!watchedIsValidated}
                  type="button"
                  onClick={appendIban}
                >
                  {`+ ${translate('buttons.add')}`}
                </Button>
              </Box>
            </Box>
          </Tooltip>
        </Box>
        <Tooltip
          title={!formState.isValid ? translate('errors.invalidData') : ''}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
            <Button
              variant="contained"
              type="submit"
              sx={submitButton}
              disabled={!formState.isValid}
            >
              {translate('buttons.save')}
            </Button>
          </Box>
        </Tooltip>
      </form>
    </Box>
  );
};
