import { GridPaginationModel } from '@mui/x-data-grid';
import { useState, useCallback } from 'react';

export interface PaginationProps {
  page: number;
  pageSize: number;
  rowCount: number;
}

export const usePagination = ({
  page,
  pageSize,
  rowCount
}: PaginationProps) => {
  const validedPage = !Number.isNaN(Number(page)) ? page : 0;
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: validedPage,
    pageSize
  });
  const [rowsCount, setRowsCount] = useState<number>(rowCount);

  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setPaginationModel(newPaginationModel);
    },
    []
  );

  const updateRowCount = useCallback((newRowCount: number) => {
    setRowsCount(newRowCount);
  }, []);

  return {
    paginationModel,
    handlePaginationModelChange,
    rowsCount,
    updateRowCount
  };
};
