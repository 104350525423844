import { Box, IconButton } from '@mui/material';

interface SignOutButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx: { [key: string]: string };
  src: string;
  alt: string;
}
export const SignOutButton: React.FC<SignOutButtonProps> = ({
  onClick,
  sx,
  src,
  alt
}): JSX.Element => {
  return (
    <IconButton
      component="button"
      size="small"
      sx={{ ml: 2 }}
      onClick={onClick}
    >
      <Box component="img" sx={sx} alt={alt} src={src} />
    </IconButton>
  );
};
