import { useCallback } from 'react';

import { ExportService, InvoiceQueryFilters } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

import { ExportForPayment } from '../../openapi/models/ExportForPayment';

export const useExportController = () => {
  const { interceptRequest } = useInterceptor();

  const {
    exportInvoicesForPayment,
    exportInvoicesZip,
    exportInvoicesToExcel,
    exportInvoicesByCompanyIdToExcel
  } = ExportService;

  const exportInvoicesPayment = useCallback(
    (id: number, body: ExportForPayment) =>
      interceptRequest(exportInvoicesForPayment, {}, id, body),
    [exportInvoicesForPayment]
  );

  const exportInvoicesZipByIds = useCallback(
    (ids: number[]) => interceptRequest(exportInvoicesZip, {}, { ids }),
    [exportInvoicesZip]
  );

  const exportInvoicesExcelByFilters = useCallback(
    (filters: InvoiceQueryFilters) =>
      interceptRequest(exportInvoicesToExcel, {}, filters),
    [exportInvoicesToExcel]
  );

  const exportInvoicesExcelByCompanyIdAndFilters = useCallback(
    (companyId: number, filters: InvoiceQueryFilters) =>
      interceptRequest(
        exportInvoicesByCompanyIdToExcel,
        {},
        companyId,
        filters
      ),
    [exportInvoicesByCompanyIdToExcel]
  );

  return {
    exportInvoicesPayment,
    exportInvoicesZipByIds,
    exportInvoicesExcelByFilters,
    exportInvoicesExcelByCompanyIdAndFilters
  };
};
