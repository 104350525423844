/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportForPayment } from '../models/ExportForPayment';
import type { FileResponse } from '../models/FileResponse';
import type { IdList } from '../models/IdList';
import type { InvoiceQueryFilters } from '../models/InvoiceQueryFilters';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportService {

    /**
     * Export .TXT file with generated bank transfer details for the selected invoices
     * @param id The company id
     * @param requestBody Files for extraction
     * @returns FileResponse OK
     * @throws ApiError
     */
    public static exportInvoicesForPayment(
        id: number,
        requestBody: ExportForPayment,
    ): CancelablePromise<FileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/invoices/export-for-payment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Export all invoices to Excel file
     * @param requestBody Invoice query filters
     * @returns FileResponse OK
     * @throws ApiError
     */
    public static exportInvoicesToExcel(
        requestBody: InvoiceQueryFilters,
    ): CancelablePromise<FileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/export-to-excel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Export all invoices by company id to Excel file
     * @param companyId The company id
     * @param requestBody Invoice query filters
     * @returns FileResponse OK
     * @throws ApiError
     */
    public static exportInvoicesByCompanyIdToExcel(
        companyId: number,
        requestBody: InvoiceQueryFilters,
    ): CancelablePromise<FileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/export-to-excel',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to download the invoices in zip
     * @param requestBody The invoice id (not to be confused with invoice number)
     * @returns FileResponse OK
     * @throws ApiError
     */
    public static exportInvoicesZip(
        requestBody: IdList,
    ): CancelablePromise<FileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/download',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
