export const navWrapper = {
  display: 'flex',
  width: '100%',
  fontSize: 14,
  fontFamily: 'regular'
};

export const rightNavSide = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto'
};

export const navLink = {
  display: 'inline',
  fontSize: '20px',
  fontWeight: 500,
  color: 'var(--nav-link-basic-color)',
  margin: 'auto 12px',

  '&:hover': {
    textDecoration: 'underline',
    color: '#7BEF6A'
  },

  '&.active': {
    color: '#7BEF6A'
  }
};

export const navIconStyle = {
  verticalAlign: 'middle',
  cursor: 'pointer'
};

export const menuItemStyle = {
  fontFamily: 'Regular'
};
