import { useCallback, useMemo } from 'react';

import { ResourceTypes } from 'openapi';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import {
  ROUTE_PERMISSIONS,
  RoutePermissionsType
} from 'utils/constants/routePermissions';
import { Scopes } from 'utils/enums/Scopes';
import { AppRoutesEnum } from 'utils/routes';

export const useRouteSecurity = () => {
  const { companiesList, isCompaniesListLoading } = useCompanies();
  const { permissions } = usePermissions();

  const hasMultipleCompanies = useMemo(
    () => companiesList.length > 1,
    [companiesList]
  );

  const routeValidations = useMemo(
    () => ({
      [AppRoutesEnum.INVOICES]: hasMultipleCompanies,
      [AppRoutesEnum.DASHBOARD]: hasMultipleCompanies
    }),
    [hasMultipleCompanies]
  );

  const getHomePageRoute = useCallback(() => {
    if (companiesList.length === 1) {
      if (permissions.DASHBOARD.read) {
        return AppRoutesEnum.COMPANY_DASHBOARD.replace(
          COMPANY_PARAMETER,
          companiesList[0].id!.toString()
        );
      }
      return AppRoutesEnum.COMPANY_INVOICES.replace(
        COMPANY_PARAMETER,
        companiesList[0].id!.toString()
      );
    }
    if (hasMultipleCompanies) {
      if (permissions.DASHBOARD.read) {
        return AppRoutesEnum.DASHBOARD;
      }
      return AppRoutesEnum.INVOICES;
    }

    if (!isCompaniesListLoading) {
      return AppRoutesEnum.ADD_COMPANY;
    }

    return AppRoutesEnum.HOME;
  }, [
    companiesList,
    isCompaniesListLoading,
    permissions,
    hasMultipleCompanies
  ]);

  const checkRoutePermission = useCallback(
    (routePath: string) => {
      const requiredPermissions =
        ROUTE_PERMISSIONS[routePath as keyof RoutePermissionsType];
      const additionalValidation =
        routeValidations[routePath as keyof typeof routeValidations];

      // If nothing is defined in ROUTE_PERMISSIONS, deny access
      if (!requiredPermissions) {
        return false;
      }

      // Example: [['DASHBOARD', { read: true }], ['INVOICES', { read: true }]]
      const permissionEntries = Object.entries(requiredPermissions!) as [
        keyof typeof ResourceTypes,
        (typeof requiredPermissions)[keyof typeof requiredPermissions]
      ][];

      // If permission is empty object and there is no additional validation, grant access
      if (
        permissionEntries.length === 0 &&
        additionalValidation === undefined
      ) {
        return true;
      }

      // Example: { 'DASHBOARD': { read: true }, 'INVOICES': { read: true } }
      const hasNecessaryPermissions = permissionEntries.every(
        ([resourceKey, resourcePermissions]) => {
          if (!resourcePermissions) {
            return false;
          }

          // Example: [['read', true], ['write', false]]
          const scopeEntries = Object.entries(resourcePermissions) as [
            Scopes,
            boolean
          ][];

          // Check if the user has the required permissions for each scope
          // The value must match exactly (true or false)
          return scopeEntries.every(
            ([scope, isAllowed]) =>
              permissions[resourceKey][scope] === isAllowed
          );
        }
      );

      // Check if the route passes additional custom validations
      const passesAdditionalValidation =
        routePath in routeValidations
          ? routeValidations[routePath as keyof typeof routeValidations]
          : true;

      return hasNecessaryPermissions && passesAdditionalValidation;
    },
    [permissions, routeValidations]
  );

  return {
    getHomePageRoute,
    checkRoutePermission
  };
};
