import { Box, Tooltip } from '@mui/material';

interface ListHoverGridCellProps {
  list: string[];
}

export const ListHoverGridCell = ({ list }: ListHoverGridCellProps) => {
  if (list?.length > 1) {
    return (
      <Box sx={{ width: '100%' }}>
        <Tooltip
          placement="top"
          title={
            <Box>
              {list.map((item) => (
                <p key={item}>{item}</p>
              ))}
            </Box>
          }
        >
          <Box
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {[...list].join(', ')}
          </Box>
        </Tooltip>
      </Box>
    );
  }
  return <Box>{list?.[0]}</Box>;
};
