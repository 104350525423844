import { decodeTxtBase64 } from './decodeTxtBase64';

export const exportTxtFile = async (data: string, fileName: string) => {
  const fileBlob = new Blob([decodeTxtBase64(data)], { type: 'text/plain' });

  const url = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
