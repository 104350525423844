/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyIbanListResponse } from '../models/CompanyIbanListResponse';
import type { CompanyIbanResponse } from '../models/CompanyIbanResponse';
import type { IbanCreate } from '../models/IbanCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IbanService {

    /**
     * Create iban for company
     * @param companyId The company id
     * @param requestBody The iban object
     * @returns CompanyIbanResponse CREATED
     * @throws ApiError
     */
    public static createCompanyIban(
        companyId: number,
        requestBody: IbanCreate,
    ): CancelablePromise<CompanyIbanResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/ibans',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the ibans for given company id
     * @param companyId The company id
     * @returns CompanyIbanListResponse OK
     * @throws ApiError
     */
    public static getIbansByCompanyId(
        companyId: number,
    ): CancelablePromise<CompanyIbanListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/ibans',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get iban for company by id
     * @param companyId The company id
     * @param ibanId The iban id
     * @returns CompanyIbanResponse OK
     * @throws ApiError
     */
    public static getCompanyIban(
        companyId: number,
        ibanId: number,
    ): CancelablePromise<CompanyIbanResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/ibans/{ibanId}',
            path: {
                'companyId': companyId,
                'ibanId': ibanId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete iban for company
     * @param companyId The company id
     * @param ibanId The iban id
     * @returns void
     * @throws ApiError
     */
    public static deleteCompanyIban(
        companyId: number,
        ibanId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{companyId}/ibans/{ibanId}',
            path: {
                'companyId': companyId,
                'ibanId': ibanId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
