export const invoicesDataGrid = {
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    cursor: 'pointer'
  },
  '& .MuiDataGrid-cellContent': {
    textOverflow: 'ellipsis !important',
    textAlign: 'left !important'
  }
};

export const exportableContainerStyles = {
  width: '24px',
  height: '24px',
  display: 'inline-block'
};

export const exportableStyles = {
  borderRadius: '22px',
  width: '10px',
  height: '10px',
  display: 'inline-block'
};

export const exportableSignStyles = {
  backgroundColor: 'rgb(87, 202, 34)'
};

export const notExportableSignStyles = {
  backgroundColor: 'rgb(255, 25, 67)'
};

export const exportableIconContainerStyles = {
  display: 'flex',
  borderRadius: '24px',
  background: '#cae2f9'
};

export const exportableIconStyles = {
  color: '#1976d2'
};

export const severityPillRootStyles = {
  borderRadius: 3,
  fontSize: '0.75rem',
  lineHeight: 2,
  fontWeight: 600,
  letterSpacing: '0.5px',
  paddingLeft: 1,
  paddingRight: 1
};
