import { useCallback } from 'react';

import { IbanCreate, IbanService } from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import { useInterceptor } from 'hooks/useInterceptor';

export const useIbanController = () => {
  const { interceptRequest } = useInterceptor();
  const { translate } = useTranslations();

  const {
    getIbansByCompanyId,
    createCompanyIban,
    deleteCompanyIban,
    getCompanyIban
  } = IbanService;

  const getIbansByCompany = useCallback(
    (companyId: number) => interceptRequest(getIbansByCompanyId, {}, companyId),
    [getIbansByCompanyId]
  );

  const createCompanyIbanByCompanyId = useCallback(
    (companyId: number, requestBody: IbanCreate) =>
      interceptRequest(
        createCompanyIban,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyCreated'))
        },
        companyId,
        requestBody
      ),
    [createCompanyIban]
  );

  const deleteCompanyIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number) =>
      interceptRequest(
        deleteCompanyIban,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyDeleted'))
        },
        companyId,
        ibanId
      ),
    [deleteCompanyIban]
  );

  const getCompanyIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number) =>
      interceptRequest(getCompanyIban, {}, companyId, ibanId),
    [getCompanyIban]
  );

  return {
    getIbansByCompany,
    createCompanyIbanByCompanyId,
    deleteCompanyIbanByCompanyId,
    getCompanyIbanByCompanyId
  };
};
