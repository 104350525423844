import {
  CounterpartyQueryFilters,
  CounterpartySortColumns,
  SortOrder
} from 'openapi';

import { INITIAL_PAGINATION_PROPS } from './paginations';

type FieldNames =
  | 'names'
  | 'registrationNumbers'
  | 'vatNumbers'
  | 'expenseTypes';

export const INITIAL_COUNTERPARTY_FILTERS: CounterpartyQueryFilters = {
  pageSize: INITIAL_PAGINATION_PROPS.pageSize,
  pageNumber: INITIAL_PAGINATION_PROPS.page,
  sortBy: CounterpartySortColumns.NAME,
  sortOrder: SortOrder.ASC
};

export const FILTER_FIELDS = [
  { name: 'names' as FieldNames, translationKey: 'labels.name' },
  {
    name: 'registrationNumbers' as FieldNames,
    translationKey: 'labels.companyRegistrationNumber'
  },
  { name: 'vatNumbers' as FieldNames, translationKey: 'labels.vatNumber' },
  { name: 'expenseTypes' as FieldNames, translationKey: 'labels.expenseType' }
];
