import { Box } from '@mui/material';
import { useContext } from 'react';

import { LanguageMenu } from 'components/LanguageMenu/LanguageMenu';
import { SignOutButton } from 'components/shared/buttons/SignOutButton/SignOutButton';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';

import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import { END, EN_LANGUAGE, SPACE_BETWEEN } from 'utils/constants/constants';
import { isAuthenticated } from 'utils/helpers/authHelper';

import {
  navWrapper,
  rightNavSide,
  navIconStyle
} from 'styles/components/NavigationStyle';

import signOutIcon from 'assets/images/sign-out-icon.svg';
import bgFlag from 'assets/languages/flag-bg.png';
import enFlag from 'assets/languages/flag-en.png';

export const Navigation: React.FC = (): JSX.Element => {
  const { translate, currentLanguage } = useTranslations();
  const { handleLogout } = useContext(TokenExpirationModalContext);
  const { openModal, closeModal, isOpen } = useModal();

  return (
    <Box
      sx={{
        ...navWrapper,
        justifyContent: isAuthenticated() ? SPACE_BETWEEN : END
      }}
    >
      <Box sx={rightNavSide}>
        <LanguageMenu
          sx={navIconStyle}
          alt={translate(
            `${currentLanguage === EN_LANGUAGE ? 'alt.enFlag' : 'alt.bgFlag'}`
          )}
          src={currentLanguage === EN_LANGUAGE ? enFlag : bgFlag}
        />

        {isAuthenticated() && (
          <SignOutButton
            sx={navIconStyle}
            alt={translate('alt.signOutIcon')}
            onClick={openModal}
            src={signOutIcon}
          />
        )}

        <ConfirmationDialog
          isOpen={isOpen}
          onClose={closeModal}
          onConfirm={handleLogout}
          title={translate('buttons.logout')}
          size="sm"
        >
          {translate('messages.logoutConfirmation')}
        </ConfirmationDialog>
      </Box>
    </Box>
  );
};
