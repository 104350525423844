import { Box, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

import {
  overviewTypographyContainerStyle,
  overviewTypographyLabelStyle,
  overviewTypographyStyle
} from 'styles/pages/InvoiceVerificationStyle';

interface LabelValueFielddProps {
  readonly label: string;
  readonly value: string | number;
  readonly paddingTop?: number;
  readonly children?: ReactNode;
}

export const LabelValueField = ({
  label,
  value,
  paddingTop,
  children
}: LabelValueFielddProps) => (
  <Box
    pt={paddingTop || 0}
    sx={{ ...overviewTypographyContainerStyle, alignItems: 'center' }}
  >
    <Typography sx={overviewTypographyLabelStyle}>{`${label}:`}</Typography>
    <span>&nbsp;</span>
    <Tooltip title={value}>
      <Typography pr={!children ? 5 : 1} sx={overviewTypographyStyle}>
        {value}
      </Typography>
    </Tooltip>
    {children}
  </Box>
);
