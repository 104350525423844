import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import { ExpenseTypeBudget } from 'openapi';

import {
  COLUMNS_DEFAULT_OPTIONS,
  DATE_FORMATS,
  EXPENSE_TYPE_NAME
} from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

import { formatNumberWithSpaces, processMoneyInput } from './moneyHelper';

export const updateBudgetCell = ({
  row,
  value,
  index
}: {
  row: ExpenseTypeBudget;
  value: string;
  index: number;
}) => {
  const validatedAndParsedValue = processMoneyInput(
    value,
    row.budgets[index].amount!.toString()
  );

  return {
    ...row,
    budgets: row.budgets.map((budget, budgetIndex) =>
      budgetIndex === index
        ? { ...budget, amount: Number(validatedAndParsedValue) }
        : budget
    )
  };
};

export const getBudgetingColumns = (
  translate: TranslateFunction
): GridColDef[] => {
  const months = new Array(12).fill(null).map((_, index) => {
    const currentMonth = index + 1;
    return {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: currentMonth.toString(),
      editable: true,
      filterable: false,
      type: 'string',
      align: 'right',
      headerAlign: 'right',
      headerName: moment().month(index).format(DATE_FORMATS.shortMonthFormat),
      valueGetter: ({ row }: { row: ExpenseTypeBudget }) =>
        row.budgets[index].amount!,
      renderCell: ({ value }: { value: number }) => (
        <>{formatNumberWithSpaces(value)}</>
      ),
      valueSetter: ({
        row,
        value
      }: {
        row: ExpenseTypeBudget;
        value: string;
      }) => updateBudgetCell({ row, value, index })
    };
  });

  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: EXPENSE_TYPE_NAME,
      filterable: false,
      minWidth: 200,
      maxWidth: 400,
      renderHeader: () => <p className="bold">{translate('labels.name')}</p>
    },
    ...months,
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'total',
      filterable: false,
      minWidth: 100,
      maxWidth: 150,
      align: 'right',
      headerAlign: 'right',
      weight: 5,
      renderHeader: () => <p className="bold">{translate('labels.total')}</p>,
      valueGetter: ({ row }: { row: ExpenseTypeBudget }) =>
        row.budgets.reduce((acc, curr) => acc + (curr.amount || 0), 0),
      renderCell: ({ value }) => (
        <p className="bold">{formatNumberWithSpaces(value)}</p>
      )
    }
  ] as GridColDef[];
};
