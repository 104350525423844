import { Box, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { layoutWrapper } from 'styles/components/LayoutStyle';

interface LayoutProps {
  children: ReactNode;
  pageTitle?: string;
  styles?: {
    [key: string]: {};
  };
}

export const Layout = (props: LayoutProps): JSX.Element => {
  const { children, styles, pageTitle } = props;

  return (
    <Box sx={{ ...layoutWrapper, ...styles }}>
      {pageTitle && (
        <Typography variant="h2" align="left">
          {pageTitle}
        </Typography>
      )}
      {pageTitle !== undefined && <Divider light />}
      {children}
    </Box>
  );
};
