import moment from 'moment';
import { useState, useMemo, useEffect } from 'react';

export const useYearSelector = (alwaysHasCurrentYear: boolean = true) => {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [existingYears, setExistingYears] = useState<number[]>([]);

  const availableYears = useMemo(() => {
    return alwaysHasCurrentYear
      ? [...new Set([...existingYears, moment().year()])]
      : existingYears;
  }, [existingYears]);

  useEffect(() => {
    if (alwaysHasCurrentYear) {
      return;
    }

    if (!existingYears.includes(selectedYear) && existingYears.length) {
      setSelectedYear(existingYears[0]);
    }
  }, [existingYears]);

  return {
    selectedYear,
    setSelectedYear,
    existingYears,
    setExistingYears,
    availableYears
  };
};
