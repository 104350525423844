import { SeriesValueFormatter } from '@mui/x-charts/internals';

import { CHARTS_OTHERS_MAX_INDEX } from 'utils/constants/constants';
import { formatMoney } from 'utils/helpers/moneyHelper';

export const mapHighlightToItems = <T>(data: T[]): T[] => {
  return data.map((item: any) => {
    return {
      ...item,
      highlightScope: { highlight: 'item', fade: 'global' }
    };
  });
};

export const dataToValueKey = <T extends { data: number }>(
  items: T[]
): (Omit<T, 'data'> & { value: number })[] =>
  items.map(({ data, ...rest }) => ({ ...rest, value: data }));

export const condenseData = (
  data: {
    value: number;
    label: string;
  }[]
) => {
  if (data.length - 1 >= CHARTS_OTHERS_MAX_INDEX) {
    const firstPart = data.slice(0, CHARTS_OTHERS_MAX_INDEX - 1);
    const secondPart = data.slice(CHARTS_OTHERS_MAX_INDEX - 1);

    return {
      items: [
        ...firstPart,
        {
          label: '',
          value: secondPart.reduce((acc, curr) => acc + curr.value, 0)
        }
      ],
      labels: secondPart.map((item) => item.label),
      values: secondPart.map((item) => item.value)
    };
  }
  return {
    items: data,
    labels: null,
    values: null
  };
};

export const formatOthersMoney = (
  othersValues: number[] | null,
  expenses: object[]
): SeriesValueFormatter<any> => {
  return (item, { dataIndex }) => {
    if (othersValues && dataIndex === expenses!.length - 1) {
      const totalOtherValue = othersValues.reduce(
        (acc, value) => acc + value,
        0
      );
      return formatMoney(totalOtherValue);
    }
    return `${formatMoney(item.value)}`;
  };
};
