import { getIn, FormikErrors } from 'formik';
import { useCallback } from 'react';

import { Invoice, Company, Counterparty } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { conditions } from 'utils/helpers/invoiceHelpers';

export const useFormHelpers = () => {
  const { translate } = useTranslations();

  const getTitleHint = useCallback(
    (name: keyof Invoice, values: Invoice): string | undefined => {
      const condition = conditions[name];

      if (condition && condition(values[name] as string, values)) {
        return translate(`warnings.${name}`);
      }

      return undefined;
    },
    [translate]
  );

  const checkForError = (
    errors: FormikErrors<Invoice>,
    fieldNames: string[]
  ) => {
    return fieldNames.some((fieldName) => getIn(errors, fieldName));
  };

  const getOptionLabelReceiverName = useCallback((option: Company | string) => {
    if (option === null) {
      return '';
    }
    if (typeof option === 'string') {
      return option;
    }

    return `${option.name} (${option.registrationNumber})`;
  }, []);

  const getOptionLabelSupplier = useCallback(
    (option: Counterparty | string) => {
      if (typeof option === 'string') {
        return option;
      }

      if (option !== null) {
        if (option.registrationNumber === null) {
          return option.name;
        }
        return `${option.name} (${option.registrationNumber})`;
      }
      return '';
    },
    []
  );

  const getOptionLabelReceiverCRN = useCallback((option: Company | string) => {
    if (typeof option === 'string') {
      return option;
    }

    return `${option.registrationNumber} (${option.name})`;
  }, []);

  const getOptionLabelCRN = useCallback((option: Counterparty | string) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.registrationNumber === null) {
      return `- (${option.name})`;
    }

    if (option !== null) {
      if (option.name === null) {
        return `${option.registrationNumber}`;
      }
      return `${option.registrationNumber} (${option.name})`;
    }
    return '';
  }, []);

  return {
    getTitleHint,
    checkForError,
    getOptionLabelReceiverName,
    getOptionLabelSupplier,
    getOptionLabelReceiverCRN,
    getOptionLabelCRN
  };
};
