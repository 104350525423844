/* eslint-disable consistent-return */
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Tooltip, Button, CircularProgress } from '@mui/material';
import { Worker } from '@react-pdf-viewer/core';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import { useInvoiceController } from 'api/controllers/InvoiceController';

import { DocumentType, Invoice, InvoiceStages, OpenAPI } from 'openapi';

import { Layout } from 'components/Layout/Layout';
import { PdfViewerWithWatermark } from 'components/PdfViewerWithWatermark/PdfViewerWithWatermark';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { TokenExpirationModal } from 'components/shared/Modal/TokenExpirationModal';

import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  ACCESS_TOKEN,
  HEARTBEAT_TIMEOUT,
  PDF_JS_WORKER_URL,
  TOKEN_EXPIRY_WARNING_TIME_SECONDS
} from 'utils/constants/constants';
import { InvoiceEventType } from 'utils/enums/Invoice';
import { getRouteForBackNavigation } from 'utils/helpers/filtersHelpers';
import {
  getEventData,
  getSSERequestOptions,
  getUrl
} from 'utils/helpers/serverSentEvents';

import {
  zoomButtonsWrapper,
  mediumVerificationFieldWidth,
  verificationContentWrapper,
  fullWidth
} from 'styles/pages/InvoiceVerificationStyle';

import { filterTypeAtom, userAtom } from 'state/state';

import { InvoiceVerificationStepper } from './InvoiceVerificationStepper';

export const InvoiceVerification = React.memo((): JSX.Element => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();

  const [filterType] = useAtom(filterTypeAtom);
  const [user] = useAtom(userAtom);

  const {
    getInvoiceFile,
    getSingleInvoiceById,
    rempromptInvoice,
    setIsAccounted
  } = useInvoiceController();
  const {
    isOpen: isAccountModalOpen,
    openModal: openAccountModal,
    closeModal: closeAccountModal
  } = useModal();
  const { translate } = useTranslations();

  const [currentInvoice, setCurrentInvoice] = useState<Invoice | null>(null);
  const { isRefreshedToken, handleLogout, handleRefresh } = useContext(
    TokenExpirationModalContext
  );
  const [triggeredReprompt, setTriggeredReprompt] = useState<boolean>(false);
  const [isSessionExpiredModalVisible, setIsSessionExpiredModalVisible] =
    useState<boolean>(false);

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const rotatePluginInstance = rotatePlugin();

  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const state = useMemo(() => localStorage.getItem('state'), []);

  const [pdfFileUrl, setPdfFileUrl] = useState<string>('');
  const [token, setToken] = useState<string>();

  const isCreatedDocument = !currentInvoice?.filePath;

  const getInvoiceUrlInfo = useCallback(
    async (invoiceId: number) => {
      const invoiceFileData = await getInvoiceFile(invoiceId);

      setPdfFileUrl(invoiceFileData.url);
    },
    [getInvoiceFile]
  );

  const getInvoice = useCallback(
    async (calledFromSSEs?: boolean) => {
      const invoice = await getSingleInvoiceById(Number(companyId), Number(id));

      if (
        invoice.stage === InvoiceStages.UPLOADED &&
        !triggeredReprompt &&
        !calledFromSSEs
      ) {
        navigate(`/companies/${companyId}/invoices`);
      }

      setCurrentInvoice(invoice);
      setTriggeredReprompt(false);
    },
    [companyId, id, getSingleInvoiceById, triggeredReprompt]
  );

  const handleInvoiceEvent = useCallback(
    async (data: string) => {
      const { eventType, eventId } = getEventData(data);
      if (eventType === InvoiceEventType.INVOICE_UPDATE && eventId === id) {
        getInvoice(true);
      }
    },
    [getInvoice]
  );

  // TODO: Change eventually to a better way to check if the file is a pdf
  const isPdf = useMemo(
    () => currentInvoice?.filePath?.includes('.pdf'),
    [currentInvoice?.filePath]
  );

  const reprompt = useCallback(async () => {
    setTriggeredReprompt(true);
    await rempromptInvoice(currentInvoice?.id as number);
  }, [rempromptInvoice, currentInvoice, setTriggeredReprompt]);

  const refreshInvoice = useCallback(
    async (newCompanyId: number) => {
      const invoice = await getSingleInvoiceById(
        newCompanyId,
        Number(currentInvoice?.id)
      );
      setCurrentInvoice(invoice);
    },
    [currentInvoice]
  );

  const isRepromptDisabled = useMemo(
    () => currentInvoice?.stage !== InvoiceStages.DRAFT,
    [currentInvoice]
  );

  const handleNavigateBack = useCallback(() => {
    const route = getRouteForBackNavigation(
      state as string,
      filterType,
      companyId
    );

    navigate(route);
  }, [navigate, companyId]);

  const confirmAccount = async () => {
    closeAccountModal();
    await setIsAccounted([Number(currentInvoice?.id)]);
    refreshInvoice(Number(companyId));
  };

  // Effects

  useEffect(() => {
    if (!token) {
      return;
    }
    let retryTimeout: NodeJS.Timeout | undefined;
    let heartbeatTimeout: NodeJS.Timeout | undefined;
    let retryCount = 0;

    const createEventSource = (): EventSourcePolyfill => {
      const result = new EventSourcePolyfill(
        getUrl(OpenAPI, getSSERequestOptions(user?.id)),
        {
          headers: {
            Authorization: `Bearer ${token}` || ''
          }
        }
      );

      result.onmessage = (e) => {
        const { eventType } = getEventData(e.data);

        if (eventType === InvoiceEventType.HEARTBEAT) {
          if (heartbeatTimeout) clearTimeout(heartbeatTimeout);

          heartbeatTimeout = setTimeout(() => {
            result.close();
            createEventSource();
          }, HEARTBEAT_TIMEOUT);
        } else {
          handleInvoiceEvent(e.data);
        }
      };

      result.onerror = (error) => {
        if ((error as any).status === 401) {
          setIsSessionExpiredModalVisible(true);
          result.close();
          return;
        }
        setToken(localStorage.getItem(ACCESS_TOKEN) || '');
        result.close();
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        if (retryTimeout) clearTimeout(retryTimeout);

        const retryDelay = Math.min(10000, 2 ** retryCount * 1000);
        retryCount += 1;

        retryTimeout = setTimeout(() => {
          createEventSource();
        }, retryDelay);
      };

      return result;
    };

    const eventSourceInstance = createEventSource();

    return () => {
      eventSourceInstance.close();
      if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [token]);

  useEffect(() => {
    getInvoice();
  }, [id]);

  useEffect(() => {
    if (!isCreatedDocument) {
      getInvoiceUrlInfo(Number(id));
    }
  }, [isCreatedDocument]);

  useEffect(() => {
    setToken(localStorage.getItem(ACCESS_TOKEN) || '');
  }, [localStorage.getItem(ACCESS_TOKEN), isRefreshedToken]);

  const handleRefreshSession = useCallback(() => {
    setIsSessionExpiredModalVisible(false);
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Layout
      styles={{
        paddingTop: '1rem',
        display: currentInvoice ? 'block' : 'none'
      }}
    >
      {currentInvoice && (
        <>
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button
              sx={{ mb: isCreatedDocument ? 2 : 0 }}
              startIcon={<ArrowBackIcon />}
              onClick={handleNavigateBack}
            >
              {translate('buttons.back')}
            </Button>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {currentInvoice.documentType !== DocumentType.NO_DOCUMENT &&
                (currentInvoice.stage === InvoiceStages.APPROVED ||
                  currentInvoice.stage === InvoiceStages.FINALIZED) && (
                  <span>
                    <Button
                      onClick={openAccountModal}
                      color="primary"
                      disabled={currentInvoice.isBooked}
                      startIcon={
                        currentInvoice.isBooked ? (
                          <TaskAltIcon
                            sx={{
                              width: '1.5rem',
                              height: '1.5rem',
                              mr: '0.2rem',
                              color: '#43a047'
                            }}
                          />
                        ) : (
                          <AccountBalanceWallet
                            sx={{
                              width: '1.5rem',
                              height: '1.5rem',
                              mr: '0.2rem'
                            }}
                          />
                        )
                      }
                      sx={{
                        '&.Mui-disabled': {
                          color: 'rgb(0,0, 0, 0.87)'
                        }
                      }}
                    >
                      {currentInvoice.isBooked
                        ? translate('labels.bookedStatus')
                        : translate('buttons.book')}
                    </Button>
                  </span>
                )}
              {!isCreatedDocument && (
                <Tooltip
                  title={
                    isRepromptDisabled
                      ? translate('messages.repromptDisabled')
                      : ''
                  }
                >
                  <span>
                    <Button
                      startIcon={<ReplayIcon />}
                      disabled={isRepromptDisabled}
                      onClick={reprompt}
                    >
                      {translate('buttons.reprompt')}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box sx={verificationContentWrapper}>
            {!isCreatedDocument && (
              <Worker workerUrl={PDF_JS_WORKER_URL || ''}>
                {pdfFileUrl ? (
                  <Box sx={mediumVerificationFieldWidth}>
                    {isPdf ? (
                      <>
                        <PdfViewerWithWatermark
                          fileUrl={pdfFileUrl}
                          plugins={[zoomPluginInstance, rotatePluginInstance]}
                          watermarkText={currentInvoice.stage as string}
                        />
                        <Box sx={zoomButtonsWrapper}>
                          <RotateBackwardButton />
                          <ZoomOutButton />
                          <ZoomPopover />
                          <ZoomInButton />
                          <RotateForwardButton />
                          <a
                            href={pdfFileUrl}
                            download={`Invoice-${currentInvoice.shortNameCompany}.pdf`}
                          >
                            <Tooltip title={translate('buttons.exportPdf')}>
                              <Button variant="text">
                                <FileDownloadIcon />
                              </Button>
                            </Tooltip>
                          </a>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: '100%',
                          maxHeight: 'calc(100vh - 200px)'
                        }}
                      >
                        <img
                          src={pdfFileUrl}
                          alt="Preview invoice"
                          style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                            maxHeight: 'calc(100vh - 200px)'
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ width: '50%' }}>
                    <CircularProgress
                      sx={{ position: 'absolute', top: '45%' }}
                    />
                  </Box>
                )}
              </Worker>
            )}
            {currentInvoice.stage === InvoiceStages.UPLOADED ? (
              <Box sx={{ width: '50%' }}>
                <CircularProgress
                  sx={{ position: 'absolute', top: '40%', left: '77%' }}
                />
              </Box>
            ) : (
              <Box
                sx={
                  isCreatedDocument ? fullWidth : mediumVerificationFieldWidth
                }
              >
                <InvoiceVerificationStepper
                  currentInvoice={currentInvoice}
                  triggeredReprompt={triggeredReprompt}
                  setCurrentInvoice={setCurrentInvoice}
                  refreshInvoice={refreshInvoice}
                />
              </Box>
            )}
          </Box>
          <ConfirmationDialog
            title={translate('titles.book')}
            isOpen={isAccountModalOpen}
            onClose={closeAccountModal}
            size="sm"
            onConfirm={confirmAccount}
          >
            {translate('messages.accountInvoiceConfirmation')}
          </ConfirmationDialog>
          <TokenExpirationModal
            onLogout={handleLogout}
            onRefresh={handleRefreshSession}
            isOpen={isSessionExpiredModalVisible}
            refreshExpTime={
              new Date().getTime() + TOKEN_EXPIRY_WARNING_TIME_SECONDS * 1000
            }
          />
        </>
      )}
    </Layout>
  );
});
