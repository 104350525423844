export const commonDataGridContainerStyle = {
  '& .MuiDataGrid-columnHeaders': {
    background: '#F9F9F9'
  },
  width: '100%',
  height: '100%'
};

export const gridToolbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const defaultGridToolbarStyle = {
  gap: 2
};

export const gridToolbarStyle = {
  display: 'flex',
  p: 1,
  minHeight: '52px'
};

export const gridToolbarButtonsStyle = {
  display: 'flex',
  gap: 2
};

export const gridToolbarOutsideContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem',
  gap: '1rem'
};
