/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PayrollListResponse } from '../models/PayrollListResponse';
import type { PayrollSummaryListResponse } from '../models/PayrollSummaryListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PayrollService {

    /**
     * This method returns the Payroll list for given companyId
     * @param id The company id
     * @param year The payroll year
     * @param month The payroll month
     * @returns PayrollListResponse OK
     * @throws ApiError
     */
    public static getPayrollByCompanyId(
        id: number,
        year: number,
        month: number,
    ): CancelablePromise<PayrollListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/payrolls',
            path: {
                'id': id,
            },
            query: {
                'year': year,
                'month': month,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to upload payroll file for extraction
     * @param id The company id
     * @param formData File for payroll extraction
     * @returns PayrollListResponse OK
     * @throws ApiError
     */
    public static uploadPayrollFile(
        id: number,
        formData: {
            year: number;
            month: number;
            file: Blob;
        },
    ): CancelablePromise<PayrollListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/payrolls',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to delete payroll data for given month and year
     * @param id The company id
     * @param year The payroll year
     * @param month The payroll month
     * @returns void
     * @throws ApiError
     */
    public static deletePayrollData(
        id: number,
        year: number,
        month: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}/payrolls',
            path: {
                'id': id,
            },
            query: {
                'year': year,
                'month': month,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the Payroll summary for given company
     * @param id The company id
     * @returns PayrollSummaryListResponse OK
     * @throws ApiError
     */
    public static getPayrollSummaryList(
        id: number,
    ): CancelablePromise<PayrollSummaryListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/payrolls/summaries',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
