import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { showErrorToastMessage } from 'components/shared/Toast/Toast';

import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';
import { useTranslations } from 'context/TranslationContext';

import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { AppRoutesEnum } from 'utils/routes';

import { viravaDefault } from 'config/authConfig';

export const useInterceptor = () => {
  const { translate } = useTranslations();
  const { handleLogout, handleRefresh } = useContext(
    TokenExpirationModalContext
  );
  const navigate = useNavigate();

  const interceptRequest = async (
    requestFunc: Function,
    options: { onSuccess?: () => void },
    ...params: any[]
  ) => {
    try {
      const response = await requestFunc(...params);

      if (options.onSuccess) {
        options.onSuccess();
      }

      if (response && response.data) {
        return response.data;
      }

      return response;
    } catch (error: any) {
      if (error.status === HttpStatusCodes.Unauthorized) {
        if (!viravaDefault.isRefreshTokenExpired()) {
          await handleRefresh();
          const response = await requestFunc(...params);
          if (response.data) {
            return response.data;
          }
          return response;
        }

        return handleLogout();
      }
      if (error.status === HttpStatusCodes.Forbidden) {
        navigate(AppRoutesEnum.HOME, {
          state: { reason: HttpStatusCodes.Forbidden }
        });
      }
      if (error.status !== HttpStatusCodes.NotAcceptable) {
        showErrorToastMessage(error, translate);
      }

      throw new Error(error);
    }
  };

  return {
    interceptRequest
  };
};
