import { ChevronRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Company } from 'openapi';

import { SidebarIcon } from 'components/Sidebar/SidebarIcon/SidebarIcon';

import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import { AppRoutesEnum } from 'utils/routes';

import {
  selectedTabStyles,
  tabStyle,
  icon,
  tabText,
  tabTextRemoved
} from 'styles/components/SidebarStyle';

import companyIcon from 'assets/images/ic_job.svg';

import { companyAtom } from 'state/state';

interface CompanySelectProps {
  companiesList: Company[];
  isSidebarExpanded: boolean;
  currentCompanyId?: string;
}

export const CompanySelect = ({
  companiesList,
  isSidebarExpanded,
  currentCompanyId
}: CompanySelectProps) => {
  const { translate } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { checkRoutePermission } = useRouteSecurity();

  const [currCompany, setCurrCompany] = useAtom(companyAtom);

  const handleCloseCompanyMenu = () => {
    setAnchorEl(null);
  };

  const handleCompanySelect = useCallback(
    (_: React.MouseEvent<HTMLLIElement, MouseEvent>, newCompanyId?: number) => {
      handleCloseCompanyMenu();

      if (!newCompanyId) {
        navigate(AppRoutesEnum.DASHBOARD);
        return;
      }

      const companyLandingPage = checkRoutePermission(
        AppRoutesEnum.COMPANY_DASHBOARD
      )
        ? AppRoutesEnum.COMPANY_DASHBOARD
        : AppRoutesEnum.COMPANY_INVOICES;

      navigate(
        companyLandingPage.replace(COMPANY_PARAMETER, newCompanyId.toString())
      );
    },
    [navigate, handleCloseCompanyMenu]
  );

  const handleClickCompanyMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddCompanySelect = () => {
    handleCloseCompanyMenu();
    navigate(AppRoutesEnum.ADD_COMPANY);
  };

  const selectedCompany = useMemo(() => {
    return companiesList.find(
      (company) => company.id === Number(currentCompanyId)
    );
  }, [currentCompanyId, companiesList]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.id !== currCompany?.id) {
      setCurrCompany(selectedCompany);
    }
  }, [selectedCompany]);

  const displayedTabText = useMemo(() => {
    if (location.pathname === AppRoutesEnum.ADD_COMPANY) {
      return `${translate('buttons.add')}`;
    }
    return selectedCompany?.name || translate('buttons.all');
  }, [selectedCompany, location.pathname, translate]);

  const isAddCompanySelected = useMemo(() => {
    return location.pathname === AppRoutesEnum.ADD_COMPANY;
  }, [location.pathname]);

  const isAddCompanyVisible = useMemo(
    () => checkRoutePermission(AppRoutesEnum.ADD_COMPANY),
    [checkRoutePermission]
  );

  const hasMultipleCompanies = useMemo(
    () => companiesList.length > 1,
    [companiesList]
  );

  return (
    <Tooltip
      title={!isSidebarExpanded ? translate('labels.company') : ''}
      placement="right"
    >
      <Box>
        <ListItemButton
          onClick={handleClickCompanyMenu}
          sx={{
            ...(anchorEl ? selectedTabStyles : tabStyle),
            opacity: '1 !important'
          }}
          disabled={!hasMultipleCompanies && !isAddCompanyVisible}
        >
          <ListItemIcon sx={icon}>
            <SidebarIcon
              iconSource={companyIcon}
              shouldBeSelected={!!anchorEl}
            />
          </ListItemIcon>

          <ListItemText>
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                ...(!isSidebarExpanded ? tabTextRemoved : tabText)
              }}
            >
              {displayedTabText}
            </Typography>
          </ListItemText>

          {hasMultipleCompanies && <ChevronRight sx={icon} />}
        </ListItemButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseCompanyMenu}
          sx={{
            marginLeft: 1,
            '& .MuiPaper-root': {
              borderRadius: '1rem'
            }
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {hasMultipleCompanies && (
            <MenuItem
              onClick={handleCompanySelect}
              sx={{
                ...(selectedCompany || isAddCompanySelected
                  ? tabStyle
                  : selectedTabStyles),
                marginY: '0.2rem'
              }}
            >
              {translate('buttons.all')}
            </MenuItem>
          )}
          {companiesList.map((company) => (
            <MenuItem
              key={company.id}
              onClick={(_) => handleCompanySelect(_, company.id!)}
              sx={{
                ...(selectedCompany?.id === company.id
                  ? selectedTabStyles
                  : tabStyle),
                marginY: '0.2rem'
              }}
            >
              {company.name}
            </MenuItem>
          ))}
          {isAddCompanyVisible && (
            <MenuItem
              onClick={() => handleAddCompanySelect()}
              sx={{
                ...(isAddCompanySelected ? selectedTabStyles : tabStyle),
                marginY: '0.2rem'
              }}
            >
              <AddIcon sx={{ marginRight: 0.5 }} />
              {translate('buttons.add')}
            </MenuItem>
          )}
        </Menu>
        <Divider sx={{ width: '100%' }} />
      </Box>
    </Tooltip>
  );
};
