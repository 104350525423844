import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import {
  CurrencyDropDown,
  Invoice,
  InvoiceExpenseType,
  PaymentChannel
} from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DATE_FORMATS } from 'utils/constants/constants';
import { PAYMENT_CHANNELS_TRANSLATION_KEYS } from 'utils/constants/invoices';
import { processMoneyInput } from 'utils/helpers/moneyHelper';

import {
  verificationFormFieldsWrapper,
  verificationFormItem,
  verificationFormItemNoHeight
} from 'styles/pages/InvoiceVerificationStyle';

interface InvoiceFormSectionProps {
  expenseTypeOptions: InvoiceExpenseType[];
  currencies: CurrencyDropDown[];
  currentInvoice: Invoice;
}

export const InvoiceFormSection = ({
  expenseTypeOptions,
  currencies,
  currentInvoice
}: InvoiceFormSectionProps) => {
  const { control, watch } = useFormContext();
  const { translate } = useTranslations();

  const { getAllTagsByCompanyId } = useAdditionalFieldsController();

  const [isInvoiceDateOpen, setInvoiceDateOpen] = useState(false);
  const [tags, setTags] = useState<string[]>(currentInvoice.tags || []);
  const [availableTags, setAvailableTags] = useState<string[]>([]);

  const filter = createFilterOptions<string>();

  const fetchAvailableTags = useCallback(async () => {
    const tagsResponse = await getAllTagsByCompanyId(
      Number(currentInvoice.companyId)
    );
    setAvailableTags(Array.from(new Set([...tags, ...tagsResponse])));
  }, [getAllTagsByCompanyId, currentInvoice.companyId, tags]);

  useEffect(() => {
    fetchAvailableTags();
  }, [fetchAvailableTags]);

  useEffect(() => {
    setAvailableTags((previousTags) =>
      Array.from(new Set([...tags, ...previousTags]))
    );
  }, [tags]);

  const watchedIsPrePaid = watch('isPrepaid');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          ...verificationFormFieldsWrapper,
          height: '55px'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name="invoiceDate"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    format={DATE_FORMATS.displayedDateFormat}
                    open={isInvoiceDateOpen}
                    disableFuture
                    label={`${translate('labels.invoiceDate')}*`}
                    value={
                      value
                        ? moment(value, DATE_FORMATS.displayedDateFormat)
                        : null
                    }
                    onClose={() => setInvoiceDateOpen(false)}
                    onChange={(event) => {
                      onChange(
                        (event as Moment).format(
                          DATE_FORMATS.displayedDateFormat
                        )
                      );
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: !!error && translate('errors.invoiceDate'),
                        name: 'invoiceDate',
                        onClick: () => setInvoiceDateOpen(true),
                        inputProps: { readOnly: true }
                      },
                      openPickerButton: {
                        onClick: () => setInvoiceDateOpen(true)
                      }
                    }}
                  />
                </FormControl>
              );
            }}
          />
        </LocalizationProvider>
        <Controller
          name="invoiceAmount"
          control={control}
          rules={{
            required: true
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              onChange={(event) => {
                field.onChange(
                  processMoneyInput(event.target.value, String(field.value))
                );
              }}
              label={`${translate('labels.amount')}*`}
              name="invoiceAmount"
              error={!!error}
              placeholder={translate('labels.amount')}
              sx={verificationFormItemNoHeight}
            />
          )}
        />
        <Controller
          name="currencyId"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} sx={verificationFormItemNoHeight}>
              <InputLabel>{`${translate('labels.currency')}*`}</InputLabel>
              <Select
                {...field}
                value={field.value || ''}
                sx={{ textAlign: 'left' }}
                label={`${translate('labels.currency')}*`}
                error={!!error}
              >
                {currencies &&
                  currencies.map((currency: CurrencyDropDown) => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.isoCode}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {error && translate('errors.currency')}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Controller
          name="isPrepaid"
          control={control}
          render={({ field }) => (
            <Box height={58} display="flex" alignItems="center">
              <Typography>{translate('labels.alreadyPaid')}</Typography>
              <Switch
                size="medium"
                defaultChecked={currentInvoice.isPrepaid}
                {...field}
              />
            </Box>
          )}
        />
        {watchedIsPrePaid && (
          <Box sx={{ minHeight: 87, display: 'flex' }}>
            <Controller
              name="paymentChannel"
              control={control}
              render={({ field }) => (
                <FormControl {...field}>
                  <Box display="flex">
                    <Typography>
                      {`${translate('labels.paymentChannel')}:`}
                    </Typography>
                  </Box>
                  <RadioGroup row {...field}>
                    {Object.values(PaymentChannel).map((channel) => (
                      <FormControlLabel
                        key={channel}
                        value={channel}
                        control={<Radio />}
                        label={translate(
                          PAYMENT_CHANNELS_TRANSLATION_KEYS[channel]
                        )}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...verificationFormFieldsWrapper,
          minHeight: '50px',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px'
          }}
        >
          <Controller
            name="expenseType"
            control={control}
            rules={{
              required: translate('errors.expenseType')
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} sx={verificationFormItem}>
                <InputLabel>{`${translate('labels.expenseType')}*`}</InputLabel>
                <Select
                  {...field}
                  value={field.value || ''}
                  sx={{ textAlign: 'left' }}
                  label={`${translate('labels.expenseType')}*`}
                  error={!!error}
                  renderValue={(selected) => {
                    const selectedOption = expenseTypeOptions.find(
                      (expenseType) => expenseType.id === Number(selected)
                    ) as InvoiceExpenseType;

                    return (
                      <span
                        style={{
                          color:
                            selectedOption && !selectedOption.isActive
                              ? 'gray'
                              : 'inherit'
                        }}
                      >
                        {!selectedOption.isActive
                          ? `${selectedOption.name} ${translate(
                              'labels.deactivatedExpenseTypeValue'
                            )}`
                          : selectedOption.name}
                      </span>
                    );
                  }}
                >
                  {expenseTypeOptions &&
                    expenseTypeOptions.map(
                      (expenseType: InvoiceExpenseType) =>
                        expenseType.isActive && (
                          <MenuItem
                            key={expenseType.id}
                            value={expenseType.id}
                            disabled={!expenseType.isActive}
                          >
                            {expenseType.name}
                          </MenuItem>
                        )
                    )}
                </Select>
                <FormHelperText>
                  {error && translate('errors.expenseType')}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px'
          }}
        >
          <Controller
            name="documentDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                minRows={2}
                maxRows={2}
                label={translate('labels.comment')}
                name="documentDescription"
                placeholder={translate('labels.comment')}
                sx={verificationFormItemNoHeight}
              />
            )}
          />
        </Box>
        <Box sx={{ ...verificationFormFieldsWrapper }}>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                fullWidth
                freeSolo
                multiple
                disableCloseOnSelect
                options={availableTags}
                value={tags}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    const isExisting = options.some(
                      (option) =>
                        params.inputValue.toLowerCase() === option.toLowerCase()
                    );
                    if (!isExisting) {
                      filtered.push(`Add "${params.inputValue}"`);
                    }
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  if (option.startsWith('Add "')) {
                    return option;
                  }
                  return option;
                }}
                onChange={(event, newValue) => {
                  const lastValue = newValue[newValue.length - 1];
                  if (lastValue && lastValue.startsWith('Add "')) {
                    const newTag = lastValue.slice(5, -1);
                    const updatedTags = [...tags, newTag];
                    setTags(updatedTags);
                    field.onChange(updatedTags);
                  } else {
                    const uniqueTags = Array.from(new Set(newValue));
                    setTags(uniqueTags);
                    field.onChange(uniqueTags);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.startsWith('Add "') ? <em>{option}</em> : option}
                  </li>
                )}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      sx={{ cursor: 'initial' }}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate('labels.tags')}
                    placeholder={translate('labels.addATag')}
                  />
                )}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
