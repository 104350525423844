/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyIbanResponse } from '../models/CompanyIbanResponse';
import type { DocumentUrlResponse } from '../models/DocumentUrlResponse';
import type { IbanConsent } from '../models/IbanConsent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenBankingService {

    /**
     * Sign up for open banking
     * @param companyId The company id
     * @returns void
     * @throws ApiError
     */
    public static signUp(
        companyId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/open-banking/sign-up',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Sign out from open banking
     * @param companyId The company id
     * @returns void
     * @throws ApiError
     */
    public static signOut(
        companyId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/open-banking/sign-out',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Consent to open banking use of the given iban
     * @param companyId The company id
     * @param ibanId The iban id
     * @param requestBody The iban object
     * @returns DocumentUrlResponse OK
     * @throws ApiError
     */
    public static consentIban(
        companyId: number,
        ibanId: number,
        requestBody: IbanConsent,
    ): CancelablePromise<DocumentUrlResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/ibans/{ibanId}/open-banking/consent',
            path: {
                'companyId': companyId,
                'ibanId': ibanId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete iban from open banking
     * @param companyId The company id
     * @param ibanId The iban id
     * @returns CompanyIbanResponse OK
     * @throws ApiError
     */
    public static deleteIban(
        companyId: number,
        ibanId: number,
    ): CancelablePromise<CompanyIbanResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/ibans/{ibanId}/open-banking/delete',
            path: {
                'companyId': companyId,
                'ibanId': ibanId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
