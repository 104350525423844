/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRoles {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    SUPER_USER = 'SUPER_USER',
    USER = 'USER',
}
