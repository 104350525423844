import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import { DataGrid, GridExpandMoreIcon } from '@mui/x-data-grid';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PayrollController } from 'api/controllers/PayrollController';

import { Payroll, PayrollList } from 'openapi';

import { Modal } from 'components/shared/Modal/Modal';

import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  DATE_FORMATS,
  DEFAULT_GRID_ROW_HEIGHT
} from 'utils/constants/constants';
import { formatMoney } from 'utils/helpers/moneyHelper';
import { getPayrollColumns } from 'utils/helpers/payrollHelper';

import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import {
  accordionBackgound,
  accordionHeader,
  modalButtonsWrapper
} from 'styles/pages/InvoiceVerificationStyle';

interface PayrollAccordionProps {
  month: number;
  year: number;
  totalFromSummary: number;
  fetchPayrollYears: () => void;
}

export const PayrollAccordion = ({
  month,
  year,
  totalFromSummary,
  fetchPayrollYears
}: PayrollAccordionProps) => {
  const { translate } = useTranslations();
  const { getPayroll, uploadPayroll, deletePayroll } = PayrollController();
  const { companyId } = useParams();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();

  const {
    isOpen: isOverrideModalOpen,
    openModal: openOverrideModal,
    closeModal: closeOverrideModal
  } = useModal();

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [payrollData, setPayrollData] = useState<Payroll[] | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleUpload = useCallback(
    async (inputFile?: File | null) => {
      const fileToSave = file || inputFile;

      if (fileToSave) {
        setPayrollData(null);
        setIsUploading(true);

        const response = (await uploadPayroll(Number(companyId), {
          year,
          month,
          file: fileToSave
        })) as PayrollList;
        setPayrollData(response.payrolls);
        setIsUploading(false);
        fetchPayrollYears();
      }
    },
    [file, year]
  );

  const handleUploadClick = useCallback(
    async (inputFile: File | null) => {
      if (totalFromSummary) {
        setFile(inputFile);
        openOverrideModal();
      } else {
        await handleUpload(inputFile);
      }
    },
    [handleUpload, totalFromSummary]
  );

  const handleCloseOverride = () => {
    setFile(null);
    closeOverrideModal();
  };

  const overridePayrollData = async () => {
    await handleUpload();
    handleCloseOverride();
  };

  const fetchPayrollData = async () => {
    if (isAccordionExpanded && !payrollData) {
      const response = (await getPayroll(
        Number(companyId),
        year,
        month
      )) as PayrollList;
      setPayrollData(response.payrolls);
    }
  };

  const deletePayrollData = async () => {
    await deletePayroll(Number(companyId), year, month);
    setPayrollData([]);
    fetchPayrollYears();
    closeDeleteModal();
  };

  useEffect(() => {
    fetchPayrollData();
  }, [isAccordionExpanded, year]);

  useEffect(() => {
    setIsAccordionExpanded(false);
    setPayrollData(null);
  }, [year]);

  const columns = getPayrollColumns(translate);

  return (
    <Box sx={{ marginBottom: 2 }} key={month}>
      <Accordion
        sx={accordionBackgound}
        expanded={isAccordionExpanded}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          sx={accordionHeader}
          onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}
        >
          <Grid container columns={12} wrap="nowrap" marginRight={2}>
            <Grid item xs={2} alignContent="center">
              <Box textAlign="start">
                {moment()
                  .month(month - 1)
                  .format(DATE_FORMATS.monthTextFormat)}
              </Box>
            </Grid>

            <Grid
              item
              container
              xs={8}
              gap={1}
              alignContent="center"
              justifyContent="start"
            >
              <Box sx={{ fontWeight: 'normal' }}>
                {translate('labels.totalExpensesForEmployer')}:
              </Box>
              <Box>{formatMoney(totalFromSummary)}</Box>
            </Grid>

            <Grid
              item
              container
              xs={2}
              sx={{
                opacity: isHovered ? 1 : 0,
                transition: 'opacity 0.5s'
              }}
            >
              <Grid container wrap="nowrap" gap={2} justifyContent="end">
                <Button
                  disabled={isUploading}
                  variant="contained"
                  component="label"
                  sx={{
                    paddingY: 0.5,
                    paddingX: 1
                  }}
                >
                  {translate(
                    totalFromSummary ? 'buttons.override' : 'buttons.upload'
                  )}
                  {isUploading && (
                    <CircularProgress sx={{ position: 'absolute' }} size={24} />
                  )}
                  <input
                    hidden
                    accept=".csv, .xls, .xlsx"
                    type="file"
                    name="payroll"
                    onChange={(e) => {
                      handleUploadClick(e.target.files && e.target.files[0]);
                      e.target.value = '';
                    }}
                  />
                </Button>
                {!!totalFromSummary && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      paddingY: 0.5,
                      paddingX: 1
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteModal();
                    }}
                  >
                    {translate('buttons.delete')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Modal
          headerTitle={translate('titles.override')}
          isOpen={isOverrideModalOpen}
          hide={handleCloseOverride}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {translate('messages.overridePayrollConfirmation', {
              monthYear: moment()
                .year(year)
                .month(month - 1)
                .format(DATE_FORMATS.monthTextAndYearFormat)
            })}
          </Typography>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={handleCloseOverride}>
              {translate('buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={overridePayrollData}>
              {translate('buttons.override')}
            </Button>
          </Box>
        </Modal>
        <Modal
          headerTitle={translate('titles.delete')}
          isOpen={isDeleteModalOpen}
          hide={closeDeleteModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {translate('messages.deletePayrollConfirmation')}
          </Typography>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={closeDeleteModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={deletePayrollData}>
              {translate('buttons.delete')}
            </Button>
          </Box>
        </Modal>
        <AccordionDetails sx={{ height: '50dvh' }}>
          <DataGrid
            rows={payrollData || []}
            columns={columns}
            rowHeight={DEFAULT_GRID_ROW_HEIGHT}
            autoPageSize
            loading={!payrollData}
            sx={invoicesDataGrid}
            localeText={{
              noRowsLabel: translate('labels.noData')
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
