import { Box, Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BudgetVsActual } from 'components/Charts/BudgetVsActual';
import { BudgetVsActualMonthly } from 'components/Charts/BudgetVsActualMonthly';
import { ExpenseForCounterparty } from 'components/Charts/ExpenseForCounterparty';
import { ExpensesForCompany } from 'components/Charts/ExpensesForCompany';
import { QuarterlyCompaniesExpenses } from 'components/Charts/QuarterlyCompaniesExpenses';
import { ReceivedInvoices } from 'components/Charts/ReceivedInvoices';

export const Dashboard = () => {
  const { companyId } = useParams();

  const isAllCompanies = useMemo(() => !companyId, [companyId]);

  return (
    <Box height="100%">
      {isAllCompanies ? (
        <Stack height="100%" justifyContent="space-between">
          <QuarterlyCompaniesExpenses sx={{ height: '49%', pt: 2 }} />
          <ReceivedInvoices sx={{ height: '49%', pt: 2 }} />
        </Stack>
      ) : (
        <Stack height="100%" justifyContent="space-between">
          <Grid container height="49.5%" justifyContent="space-between">
            <ExpensesForCompany
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
            <ExpenseForCounterparty
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
          </Grid>
          <Grid container height="49.5%" justifyContent="space-between">
            <BudgetVsActual sx={{ height: '100%', width: '49.5%', pt: 2 }} />
            <BudgetVsActualMonthly
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
          </Grid>
        </Stack>
      )}
    </Box>
  );
};
