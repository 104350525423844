import { Box, Grid, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ExpenseTypesDataGrid } from 'pages/ExpenseTypes/ExpenseTypesDataGrid';

import { AmountsApproval } from 'components/approvals/AmountsApproval';
import { CounterpartiesApproval } from 'components/approvals/CounterpartiesApproval';

import { useTranslations } from 'context/TranslationContext';

import { ApprovalTabs } from 'utils/enums/Approvals';

export const Approvals = () => {
  const { translate } = useTranslations();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabFromUrl = searchParams.get('tab');
  const initialTab = Object.values(ApprovalTabs).includes(
    tabFromUrl as unknown as ApprovalTabs
  )
    ? (tabFromUrl as ApprovalTabs)
    : ApprovalTabs.ExpenseTypes;

  const [value, setValue] = useState<ApprovalTabs>(initialTab);

  const handleChange = (_: SyntheticEvent, newValue: ApprovalTabs) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const tabContent = useMemo(
    () => ({
      [ApprovalTabs.ExpenseTypes]: <ExpenseTypesDataGrid ignoreCreating />,
      [ApprovalTabs.CounterParties]: <CounterpartiesApproval />,
      [ApprovalTabs.Amounts]: <AmountsApproval />
    }),
    []
  );

  return (
    <Grid container flexDirection="column" height="100%">
      <Grid item mb={2}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            value={ApprovalTabs.ExpenseTypes}
            label={translate('buttons.expenseTypes')}
          />
          <Tab
            value={ApprovalTabs.CounterParties}
            label={translate('buttons.counterParties')}
          />
          <Tab
            value={ApprovalTabs.Amounts}
            label={translate('buttons.amounts')}
          />
        </Tabs>
      </Grid>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 64px)'
        }}
      >
        {tabContent[value]}
      </Box>
    </Grid>
  );
};
