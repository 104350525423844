import { NavigateFunction } from 'react-router-dom';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import { CounterpartyFilterType } from 'utils/enums/CounterpartyFilterType';
import { FilterType } from 'utils/enums/FilterType';
import { TranslateFunction } from 'utils/interfaces/Translations';
import { AppRoutesEnum } from 'utils/routes';

import {
  sidebarWrapper,
  sidebarExpandButton,
  sidebarOpen,
  sidebarClosed,
  sidebarButtonCollapsed,
  sidebarButtonExpanded
} from 'styles/components/SidebarStyle';

export const createSidebarConditionalsManager = (isSidebarOpen: boolean) => {
  return {
    sidebarStyle: isSidebarOpen
      ? { ...sidebarWrapper, ...sidebarOpen }
      : { ...sidebarWrapper, ...sidebarClosed },
    expandButtonStyle: isSidebarOpen
      ? { ...sidebarExpandButton, ...sidebarButtonExpanded }
      : { ...sidebarExpandButton, ...sidebarButtonCollapsed }
  };
};

export const navigateToInvoices = (
  currentCompanyId: string,
  navigate: NavigateFunction,
  routeCompany: string,
  routeAll: string
) => {
  if (currentCompanyId) {
    navigate(routeCompany.replace(COMPANY_PARAMETER, currentCompanyId));
  } else {
    navigate(routeAll);
  }
};

export const navigateToDashboard = (
  currentCompanyId: string,
  navigate: NavigateFunction
) => {
  if (currentCompanyId) {
    navigate(
      AppRoutesEnum.COMPANY_DASHBOARD.replace(
        COMPANY_PARAMETER,
        currentCompanyId
      )
    );
  } else {
    navigate(AppRoutesEnum.DASHBOARD);
  }
};

export const isPathSelected = (
  routeConstant: AppRoutesEnum,
  currentCompanyId: string,
  currentLocation: string,
  routeConstantSingle?: AppRoutesEnum
) => {
  if (currentCompanyId) {
    if (routeConstantSingle) {
      return (
        currentLocation ===
        routeConstantSingle.replace(COMPANY_PARAMETER, currentCompanyId)
      );
    }

    return currentLocation.split('/')[3] === routeConstant.split('/')[3];
  }

  return currentLocation === routeConstant;
};

interface GetSidebarTabsProps {
  currentCompanyId: string;
  translate: TranslateFunction;
  navigate: NavigateFunction;
  currentLocation: string;
  checkRoutePermission: (routePath: string) => boolean;
  filterType?: FilterType;
  isNested?: boolean;
  tabLongText?: string;
}

export const getSidebarTabs = ({
  currentCompanyId,
  translate,
  navigate,
  currentLocation,
  checkRoutePermission
}: GetSidebarTabsProps) => {
  const isDashboardSelected =
    isPathSelected(
      AppRoutesEnum.COMPANY_DASHBOARD,
      currentCompanyId,
      currentLocation
    ) ||
    isPathSelected(AppRoutesEnum.DASHBOARD, currentCompanyId, currentLocation);
  const isInvoiceSelected =
    isPathSelected(
      AppRoutesEnum.COMPANY_INVOICES,
      currentCompanyId,
      currentLocation
    ) ||
    isPathSelected(AppRoutesEnum.INVOICES, currentCompanyId, currentLocation);

  return [
    {
      route: AppRoutesEnum.DASHBOARD,
      tabText: translate('buttons.dashboard'),
      isSelected: isDashboardSelected,
      onClick: () => {
        navigateToDashboard(currentCompanyId, navigate);
      },
      isVisible:
        checkRoutePermission(AppRoutesEnum.DASHBOARD) ||
        checkRoutePermission(AppRoutesEnum.COMPANY_DASHBOARD)
    },
    {
      route: AppRoutesEnum.INVOICES,
      tabText: translate('buttons.invoices'),
      isSelected: isInvoiceSelected,
      onClick: () => {
        navigateToInvoices(
          currentCompanyId,
          navigate,
          AppRoutesEnum.COMPANY_INVOICES,
          AppRoutesEnum.INVOICES
        );
      },
      filterType: FilterType.Initial,
      isVisible:
        checkRoutePermission(AppRoutesEnum.INVOICES) ||
        checkRoutePermission(AppRoutesEnum.COMPANY_INVOICES)
    },
    {
      route: AppRoutesEnum.INVOICES_APPROVAL,
      tabText: translate('buttons.forApproval'),
      tabLongText: translate('buttons.forApprovalLong'),
      isSelected: isPathSelected(
        AppRoutesEnum.INVOICES_APPROVAL,
        currentCompanyId,
        currentLocation,
        AppRoutesEnum.COMPANY_INVOICES_APPROVAL
      ),
      onClick: () => {
        navigateToInvoices(
          currentCompanyId,
          navigate,
          AppRoutesEnum.COMPANY_INVOICES_APPROVAL,
          AppRoutesEnum.INVOICES_APPROVAL
        );
      },
      filterType: FilterType.Approval,
      isVisible: checkRoutePermission(AppRoutesEnum.INVOICES_APPROVAL),
      isNested: true
    },
    {
      route: AppRoutesEnum.INVOICES_ACCOUNTING,
      tabText: translate('buttons.forAccounting'),
      tabLongText: translate('buttons.forAccountingLong'),
      isSelected: isPathSelected(
        AppRoutesEnum.INVOICES_ACCOUNTING,
        currentCompanyId,
        currentLocation,
        AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING
      ),
      onClick: () => {
        navigateToInvoices(
          currentCompanyId,
          navigate,
          AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING,
          AppRoutesEnum.INVOICES_ACCOUNTING
        );
      },
      filterType: FilterType.Accounting,
      isVisible: checkRoutePermission(AppRoutesEnum.INVOICES_ACCOUNTING),
      isNested: true
    },
    {
      route: AppRoutesEnum.INVOICES_PAYMENT,
      tabText: translate('buttons.forPayment'),
      tabLongText: translate('buttons.forPaymentLong'),
      isSelected: isPathSelected(
        AppRoutesEnum.INVOICES_PAYMENT,
        currentCompanyId,
        currentLocation,
        AppRoutesEnum.COMPANY_INVOICES_PAYMENT
      ),
      onClick: () => {
        navigateToInvoices(
          currentCompanyId,
          navigate,
          AppRoutesEnum.COMPANY_INVOICES_PAYMENT,
          AppRoutesEnum.INVOICES_PAYMENT
        );
      },
      filterType: FilterType.Payment,
      isVisible: checkRoutePermission(AppRoutesEnum.INVOICES_PAYMENT),
      isNested: true
    },
    {
      route: AppRoutesEnum.COMPANY_COUNTERPARTIES,
      tabText: translate('buttons.counterParties'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_COUNTERPARTIES,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_COUNTERPARTIES.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      filterType: CounterpartyFilterType.Default,
      isVisible:
        currentCompanyId &&
        checkRoutePermission(AppRoutesEnum.COMPANY_COUNTERPARTIES)
    },
    {
      route: AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_VALIDATED,
      tabText: translate('buttons.notValidated'),
      tabLongText: translate('buttons.notValidated'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_VALIDATED,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_VALIDATED.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      filterType: CounterpartyFilterType.NotValidated,
      isVisible:
        currentCompanyId &&
        checkRoutePermission(
          AppRoutesEnum.COMPANY_COUNTERPARTIES_NOT_VALIDATED
        ),
      isNested: true
    },
    {
      route: AppRoutesEnum.COMPANY_PAYROLL,
      tabText: translate('buttons.payroll'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_PAYROLL,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_PAYROLL.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      isVisible:
        currentCompanyId && checkRoutePermission(AppRoutesEnum.COMPANY_PAYROLL)
    },
    {
      route: AppRoutesEnum.COMPANY_EXPENSE_TYPES,
      tabText: translate('buttons.expenseTypes'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_EXPENSE_TYPES,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_EXPENSE_TYPES.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      isVisible:
        currentCompanyId &&
        checkRoutePermission(AppRoutesEnum.COMPANY_EXPENSE_TYPES)
    },
    {
      route: AppRoutesEnum.COMPANY_APPROVALS,
      tabText: translate('buttons.approvals'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_APPROVALS,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_APPROVALS.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      isVisible:
        !!currentCompanyId &&
        checkRoutePermission(AppRoutesEnum.COMPANY_APPROVALS)
    },
    {
      route: AppRoutesEnum.COMPANY_BUDGETING,
      tabText: translate('buttons.budgeting'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_BUDGETING,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_BUDGETING.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      isVisible:
        currentCompanyId &&
        checkRoutePermission(AppRoutesEnum.COMPANY_BUDGETING)
    },
    {
      route: AppRoutesEnum.COMPANY_DETAILS,
      tabText: translate('buttons.companyDetails'),
      isSelected: isPathSelected(
        AppRoutesEnum.COMPANY_DETAILS,
        currentCompanyId,
        currentLocation
      ),
      onClick: () => {
        navigate(
          AppRoutesEnum.COMPANY_DETAILS.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        );
      },
      isVisible:
        currentCompanyId && checkRoutePermission(AppRoutesEnum.COMPANY_DETAILS)
    }
  ].filter((tab) => {
    return tab.isVisible;
  });
};
