export const MOMENT_LOCALE_CONFIG = {
  months:
    'Януари_Февруари_Март_Април_Май_Юни_Юли_Август_Септември_Октомври_Ноември_Декември'.split(
      '_'
    ),
  monthsShort: 'Яну_Фев_Мар_Апр_Май_Юни_Юли_Авг_Сеп_Окт_Ное_Дек'.split('_'),
  weekdays: 'неделя_понеделник_вторник_сряда_четвъртък_петък_събота'.split('_'),
  weekdaysShort: 'нед_пон_вто_сря_чет_пет_съб'.split('_'),
  weekdaysMin: 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'D.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY H:mm',
    LLLL: 'dddd, D MMMM YYYY H:mm'
  },
  calendar: {
    sameDay: '[Днес в] LT',
    nextDay: '[Утре в] LT',
    nextWeek: 'dddd [в] LT',
    lastDay: '[Вчера в] LT',
    lastWeek(this: { day: () => number }) {
      switch (this.day()) {
        case 0:
        case 3:
        case 6:
          return '[Миналата] dddd [в] LT';
        case 1:
        case 2:
        case 4:
        case 5:
          return '[Миналия] dddd [в] LT';
        default:
          return '';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    s: 'няколко секунди',
    ss: '%d секунди',
    m: 'минута',
    mm: '%d минути',
    h: 'час',
    hh: '%d часа',
    d: 'ден',
    dd: '%d дни',
    w: 'седмица',
    ww: '%d седмици',
    M: 'месец',
    MM: '%d месеца',
    y: 'година',
    yy: '%d години'
  },
  dayOfMonthOrdinalParse: /\d{1,2}-(ев|ен|ти|ви|ри|ми)/,
  ordinal(number: number) {
    const lastDigit = number % 10;
    const last2Digits = number % 100;
    if (number === 0) {
      return `${number}-ев`;
    }
    if (last2Digits === 0) {
      return `${number}-ен`;
    }
    if (last2Digits > 10 && last2Digits < 20) {
      return `${number}-ти`;
    }
    if (lastDigit === 1) {
      return `${number}-ви`;
    }
    if (lastDigit === 2) {
      return `${number}-ри`;
    }
    if (lastDigit === 7 || lastDigit === 8) {
      return `${number}-ми`;
    }
    return `${number}-ти`;
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7 // The week that contains Jan 7th is the first week of the year.
  }
};
