import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Paper,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CounterpartyFilters } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { FILTER_FIELDS } from 'utils/constants/counterparty';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CounterPartiesFilterPanelProps {
  filtersData?: CounterpartyFilters;
  areFiltersVisible?: boolean;
  handleApplyFilters: (data: any) => void;
}

export const CounterPartiesFilterPanel = ({
  filtersData,
  areFiltersVisible,
  handleApplyFilters
}: CounterPartiesFilterPanelProps) => {
  const { control, handleSubmit, reset } = useForm<CounterpartyFilters>({
    defaultValues: {
      names: [],
      registrationNumbers: [],
      vatNumbers: [],
      expenseTypes: []
    }
  });

  const { translate } = useTranslations();

  const [counterPartyOptions, setCounterPartyOptions] = useState(
    filtersData || {}
  );

  useEffect(() => {
    setCounterPartyOptions(filtersData || {});
    reset();
  }, [filtersData]);

  return (
    <Paper
      elevation={4}
      className={`filters-container ${!areFiltersVisible && 'hidden'}`}
    >
      <Grid container>
        <form
          className={`filters ${!areFiltersVisible && 'hidden'}`}
          onSubmit={handleSubmit(handleApplyFilters)}
        >
          <Grid container spacing={1}>
            {FILTER_FIELDS.map((filter) => (
              <Grid item xs={3} key={filter.name}>
                <Controller
                  name={filter.name}
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: '100%' }} size="small" fullWidth>
                      <Autocomplete
                        {...field}
                        multiple
                        size="small"
                        options={counterPartyOptions[filter.name] || []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option || ''}
                        onChange={(e, value) => {
                          field.onChange(value);
                          setCounterPartyOptions((previousValue) => {
                            const filteredValues = previousValue[
                              filter.name
                            ]?.filter((item) => !value.some((v) => v === item));
                            return {
                              ...previousValue,
                              [filter.name]: [
                                ...value,
                                ...(filteredValues || [])
                              ]
                            };
                          });
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value
                            .slice(0, 1)
                            .map((option, index) => (
                              <Chip
                                sx={{ width: '65%' }}
                                label={option}
                                {...getTagProps({ index })}
                                disabled={index > 1}
                                size="small"
                              />
                            ))
                            .concat(
                              value.length > 1
                                ? [
                                    <Chip
                                      size="small"
                                      key="more"
                                      label={`+${value.length - 1} ${translate(
                                        'labels.more'
                                      )}`}
                                    />
                                  ]
                                : []
                            )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate(filter.translationKey)}
                            placeholder={translate(filter.translationKey)}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            ))}

            <Grid container paddingTop={2} gap={2} paddingLeft={1}>
              <Grid item>
                <Button type="submit" variant="contained" size="small">
                  {translate('buttons.apply')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    reset();
                    handleApplyFilters({});
                  }}
                >
                  {translate('buttons.reset')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
};
