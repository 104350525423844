import { GridColDef } from '@mui/x-data-grid';

import {
  ExpenseType,
  ExpenseTypeCreate,
  ExpenseTypeResponse,
  User
} from 'openapi';

import {
  ACTIONS,
  ACTIVE,
  APPROVERS,
  COLUMNS_DEFAULT_OPTIONS
} from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

import { formatMoney } from './moneyHelper';

export const getExpenseTypeColumns = (
  translate: TranslateFunction
): GridColDef[] => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'name',
      headerName: translate('labels.name'),
      minWidth: 200,
      maxWidth: 400
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: APPROVERS,
      headerName: translate('labels.approvers'),
      flex: 0.8,
      sortable: false
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvalThreshold',
      headerName: translate('labels.autoApprovalThreshold'),
      flex: 0.5,
      valueFormatter: (params) =>
        params.value
          ? formatMoney(params.value as number)
          : translate('labels.disabled'),
      minWidth: 120,
      maxWidth: 150
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIVE,
      headerName: translate('labels.active'),
      flex: 0.1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      minWidth: 90
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: '',
      flex: 0.1,
      align: 'center',
      headerAlign: 'center',
      sortable: false
    }
  ];
};

export const getApproverIdsDefaultValue = (
  initialValues: ExpenseType | undefined,
  approvers: User[],
  id: number
): User[] =>
  initialValues
    ? approvers.filter((approver) =>
        initialValues.approvers?.find((app) => app.id === approver.id)
      )
    : approvers.filter((approver) => {
        return approver.id === id;
      });

export const getMappedInitialValues = (
  initialValues: ExpenseType | undefined,
  formValues: ExpenseTypeCreate,
  approvers: User[],
  id: number
): ExpenseTypeCreate => ({
  name: formValues.name || '',
  approvalThreshold: formValues.approvalThreshold || 0,
  approverIds: initialValues
    ? formValues.approverIds
    : approvers
        .filter((approver) => approver.id === id)
        .map((approver) => approver.id)
});

export const updateExpenseTypes = (
  expenseTypes: ExpenseType[],
  setExpenseTypes: (
    value: React.SetStateAction<ExpenseType[] | undefined>
  ) => void,
  result: ExpenseTypeResponse | ExpenseType
) => {
  const index = expenseTypes.findIndex(
    (expense) => expense.id === (result as ExpenseType).id
  );

  if (index !== -1) {
    const updatedExpenseTypes = [...expenseTypes];
    updatedExpenseTypes[index] = result as ExpenseType;
    setExpenseTypes(updatedExpenseTypes);
    return;
  }

  setExpenseTypes([...expenseTypes, result as ExpenseType]);
};
