import { Buffer } from 'buffer';

export const decodeTxtBase64 = (base64: string) => {
  // Decode base64
  const binary = atob(base64);

  const buffer = Buffer.from(binary, 'binary');

  return buffer;
};
