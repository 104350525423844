import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { BarChart, BarSeriesType, cheerfulFiestaPalette } from '@mui/x-charts';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboardController } from 'api/controllers/DashboardController';

import { useTranslations } from 'context/TranslationContext';

import { useYearSelector } from 'hooks/useYearSelector';

import {
  CHARTS_LABEL_MAX_CHARS,
  CHARTS_LABEL_MAX_CHARS_WITHOUT_ELIPSIS
} from 'utils/constants/constants';
import { formatMoney } from 'utils/helpers/moneyHelper';
import { mapHighlightToItems } from 'utils/mappers/charts';

interface BudgetVsActualProps {
  sx?: SxProps;
}

export const BudgetVsActual = ({ sx }: BudgetVsActualProps) => {
  const { getBudgetByCompany } = useDashboardController();
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const { selectedYear, setExistingYears, availableYears, setSelectedYear } =
    useYearSelector(false);

  const [budgetsData, setBudgetsData] = useState(null);

  const fetchBudgets = useCallback(async () => {
    const response = await getBudgetByCompany(Number(companyId), selectedYear);

    setBudgetsData(response.budgets || []);
    setExistingYears(response.years || []);
  }, [companyId, selectedYear, getBudgetByCompany]);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  useEffect(() => {
    fetchBudgets();
  }, [fetchBudgets]);

  return (
    <Paper elevation={4} sx={sx}>
      <Stack height="100%">
        <Grid container justifyContent="space-between">
          <Stack justifyContent="center">
            <Typography
              sx={{ ml: 4, mb: 0, fontSize: '1.1vw', fontWeight: 'bold' }}
              align="left"
            >
              {translate('labels.budgetVsActualAsOfToday')}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ mr: 2 }}>
            <Select
              value={selectedYear}
              size="small"
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Box height="90%">
          <BarChart
            colors={cheerfulFiestaPalette}
            loading={!budgetsData}
            dataset={budgetsData || []}
            slotProps={{
              legend: {
                itemGap: 25,
                markGap: 3,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                labelStyle: {
                  fontSize: 12
                }
              },
              loadingOverlay: {
                message: translate('labels.loading')
              },
              noDataOverlay: {
                message: translate('labels.noData')
              }
            }}
            margin={{ left: 80, bottom: 90 }}
            series={
              mapHighlightToItems([
                {
                  dataKey: 'budget',
                  label: translate('labels.budget'),
                  valueFormatter: (value: number) => formatMoney(value)
                },
                {
                  dataKey: 'actual',
                  label: translate('labels.actual'),
                  valueFormatter: (value: number) => formatMoney(value)
                }
              ]) as unknown as BarSeriesType[]
            }
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'label',
                valueFormatter: (value: string, context: any) =>
                  context.location === 'tick' &&
                  value.length > CHARTS_LABEL_MAX_CHARS_WITHOUT_ELIPSIS
                    ? `${value.slice(0, CHARTS_LABEL_MAX_CHARS)}...`
                    : value,
                tickLabelStyle: {
                  angle: -45,
                  dominantBaseline: 'auto',
                  textAnchor: 'end'
                }
              }
            ]}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
