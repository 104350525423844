import React from 'react';
import { ReactSVG } from 'react-svg';

interface SidebarIconProps {
  iconSource: string;
  shouldBeSelected: boolean;
}

export const SidebarIcon: React.FC<SidebarIconProps> = ({
  iconSource,
  shouldBeSelected
}) => {
  return (
    <ReactSVG
      src={iconSource}
      wrapper="span"
      beforeInjection={(svg) => {
        const paths = svg.querySelectorAll('path');
        for (const path of paths) {
          if (shouldBeSelected) {
            path.style.fill = 'rgb(7, 141, 238)';
          } else {
            path.style.fill = 'rgba(0, 0, 0, 0.54)';
          }
        }
        svg.setAttribute('width', '30px');
        svg.setAttribute('height', '30px');
        svg.setAttribute('minWidth', '40px');
      }}
    />
  );
};

export default SidebarIcon;
