import { Box, Grid, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState, SyntheticEvent } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useUserController } from 'api/controllers/UserController';

import { Company, User } from 'openapi';

import { CreateUpdateCompanyForm } from 'components/forms/CreateUpdateCompanyForm';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { CompanyTabs } from 'utils/enums/Company';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

import { CompanyIbans } from './CompanyIbans';

export const CompanyDetails = () => {
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { removeCompany } = useCompanies();
  const { companyId } = useParams();
  const { getHomePageRoute } = useRouteSecurity();
  const [searchParams, setSearchParams] = useSearchParams();
  const { deleteCompany, getCompanyDetailsById } = useCompanyController();
  const { getUsersByCompanyId } = useUserController();
  const [companyDetails, setCompanyDetails] = useState<Company>();
  const [users, setUsers] = useState<User[]>([]);

  const getUsers = async () => {
    const response = await getUsersByCompanyId(Number(companyId));
    setUsers(response);
  };

  const tabFromUrl = searchParams.get('tab');
  const initialTab = Object.values(CompanyTabs).includes(
    tabFromUrl as unknown as CompanyTabs
  )
    ? (tabFromUrl as CompanyTabs)
    : CompanyTabs.CompanyDetails;

  const [value, setValue] = useState<CompanyTabs>(initialTab);

  const handleChange = (_: SyntheticEvent, newValue: CompanyTabs) => {
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const onDelete = async () => {
    await deleteCompany(Number(companyId));
    navigate(getHomePageRoute());
    removeCompany(Number(companyId));
  };

  const getCompanyDetails = async () => {
    const result = await getCompanyDetailsById(Number(companyId));
    setCompanyDetails(result);
  };

  useEffect(() => {
    getUsers();
    getCompanyDetails();
  }, [companyId]);

  const tabContent = useMemo(
    () => ({
      [CompanyTabs.CompanyDetails]: (
        <Box mt={2} sx={mediumVerificationFieldWidth}>
          <CreateUpdateCompanyForm
            companyDetails={companyDetails}
            onDelete={onDelete}
            users={users}
          />
        </Box>
      ),
      [CompanyTabs.CompanyIbans]: (
        <CompanyIbans
          companyDetails={companyDetails}
          refreshCompanyDetails={getCompanyDetails}
        />
      )
    }),
    [users, companyDetails]
  );

  useEffect(() => {
    getUsers();
  }, [companyId]);

  return (
    <Box>
      <Grid container flexDirection="column" height="100%">
        <Grid item mb={2}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              value={CompanyTabs.CompanyDetails}
              label={translate('buttons.companyDetails')}
            />
            <Tab
              value={CompanyTabs.CompanyIbans}
              label={translate('buttons.banking')}
            />
          </Tabs>
        </Grid>
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 64px)'
          }}
        >
          {tabContent[value]}
        </Box>
      </Grid>
    </Box>
  );
};
