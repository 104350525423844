import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, TextField, Button, Grid, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { Controller, useForm } from 'react-hook-form';

import { AmountCreate, User, Amount } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { AMOUNT_MAX, AMOUNT_MIN } from 'utils/constants/constants';
import { mapInitialValues } from 'utils/mappers/amounts';

import { submitButton } from 'styles/components/Common';
import { verificationFormFieldsWrapper } from 'styles/pages/InvoiceVerificationStyle';

interface CreateUpdateAmountProps {
  onSubmit: (values: AmountCreate) => void;
  approvers: User[];
  initialValues?: Amount;
  allAmounts: number[];
}

export const CreateUpdateAmount = ({
  onSubmit,
  initialValues,
  approvers,
  allAmounts
}: CreateUpdateAmountProps) => {
  const { translate } = useTranslations();

  const { handleSubmit, control, formState, watch } = useForm<AmountCreate>({
    defaultValues: mapInitialValues(initialValues),
    mode: 'onChange'
  });

  const watchedValue = Number(watch('value'));
  const isAmountAlreadyCreated = initialValues
    ? false
    : allAmounts.includes(watchedValue);

  let tooltipTitle = '';
  if (!formState.isValid) {
    tooltipTitle = translate('errors.invalidData');
  } else if (isAmountAlreadyCreated) {
    tooltipTitle = translate('errors.amountExisting');
  }

  return (
    <Grid container sx={{ ...verificationFormFieldsWrapper, width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid item mb={2} height={60}>
          <Controller
            name="value"
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                (value >= AMOUNT_MIN && value <= AMOUNT_MAX) || 'error'
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={`${translate('labels.amount')}*`}
                name="value"
                placeholder={translate('labels.amount')}
                error={!!error}
                helperText={
                  error &&
                  translate('errors.amountOutOfRange', {
                    min: AMOUNT_MIN.toString(),
                    max: AMOUNT_MAX.toString()
                  })
                }
                fullWidth
                inputProps={{
                  pattern: '[0-9]*' // HTML5: allows only digits
                }}
              />
            )}
          />
        </Grid>

        <Grid item mb={3}>
          <Controller
            name="approverIds"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Autocomplete
                defaultValue={approvers.filter((approver) =>
                  field.value.includes(approver.id)
                )}
                multiple
                options={approvers}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name!}
                onChange={(e, value) => {
                  field.onChange(value.map((v) => v.id));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${translate('labels.approvers')}*`}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error && translate('errors.approver')
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        <Tooltip title={tooltipTitle}>
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={submitButton}
              disabled={!formState.isValid || isAmountAlreadyCreated}
            >
              {translate('buttons.save')}
            </Button>
          </Box>
        </Tooltip>
      </form>
    </Grid>
  );
};
