/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExpenseTypeCreate } from '../models/ExpenseTypeCreate';
import type { ExpenseTypeDropDownListResponse } from '../models/ExpenseTypeDropDownListResponse';
import type { ExpenseTypeListResponse } from '../models/ExpenseTypeListResponse';
import type { ExpenseTypePatch } from '../models/ExpenseTypePatch';
import type { ExpenseTypeResponse } from '../models/ExpenseTypeResponse';
import type { IdList } from '../models/IdList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExpenseTypeService {

    /**
     * This method returns all the expense types for the company
     * @param id The company id
     * @returns ExpenseTypeListResponse OK
     * @throws ApiError
     */
    public static getExpenseTypesByCompanyId(
        id: number,
    ): CancelablePromise<ExpenseTypeListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/expense-types',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method creates a new expense type
     * @param id The company id
     * @param requestBody Expense type to add
     * @returns ExpenseTypeResponse CREATED
     * @throws ApiError
     */
    public static createExpenseType(
        id: number,
        requestBody: ExpenseTypeCreate,
    ): CancelablePromise<ExpenseTypeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/expense-types',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple expense types by id
     * @param id The company id
     * @param expenseTypeIds List of expense type ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteExpenseTypesByIds(
        id: number,
        expenseTypeIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}/expense-types',
            path: {
                'id': id,
            },
            query: {
                'expenseTypeIds': expenseTypeIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of expense types for the dropdown
     * @param id The company id
     * @returns ExpenseTypeDropDownListResponse OK
     * @throws ApiError
     */
    public static getAllDropDownExpenseTypes(
        id: number,
    ): CancelablePromise<ExpenseTypeDropDownListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/expense-types/drop-down',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the expense type by id
     * @param companyId The company id
     * @param expenseTypeId The expense type id
     * @returns ExpenseTypeResponse OK
     * @throws ApiError
     */
    public static getExpenseTypeById(
        companyId: number,
        expenseTypeId: number,
    ): CancelablePromise<ExpenseTypeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/expense-types/{expenseTypeId}',
            path: {
                'companyId': companyId,
                'expenseTypeId': expenseTypeId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method updates an expense type
     * @param companyId The company id
     * @param expenseTypeId The expense type id
     * @param requestBody Expense types to update
     * @returns ExpenseTypeResponse OK
     * @throws ApiError
     */
    public static updateExpenseTypeById(
        companyId: number,
        expenseTypeId: number,
        requestBody: ExpenseTypePatch,
    ): CancelablePromise<ExpenseTypeResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/companies/{companyId}/expense-types/{expenseTypeId}',
            path: {
                'companyId': companyId,
                'expenseTypeId': expenseTypeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method toggle the expense type activation
     * @param companyId The company id
     * @param requestBody The expense type ids
     * @returns void
     * @throws ApiError
     */
    public static toggleExpenseTypeActivation(
        companyId: number,
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/expense-types/activate',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
