import { JSONObject } from 'utils/interfaces/Translations';

export const replaceParams = (
  label: string,
  params: { [key: string]: string }
): string => {
  let templateLabel = '';
  let currentLabel = label;

  Object.keys(params).map((key) => {
    const replaceKey = `{{${key}}}`;
    const regularExpression = new RegExp(replaceKey, 'g');

    templateLabel = currentLabel.replace(regularExpression, params[key]);
    currentLabel = templateLabel;

    return templateLabel;
  });

  return templateLabel;
};

export const getNestedValues = (object: JSONObject, property: string): string =>
  property
    .replace(/\[([^\\[\]]*)\]/g, '.$1.')
    .split('.')
    .filter((element: string) => element !== '')
    .reduce(
      (previousValue: any, argument: string) =>
        previousValue && previousValue[argument],
      object
    );
