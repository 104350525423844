import { PIE_CHART_LABELS_MAX_CHARS } from 'utils/constants/constants';

export const getFormattedChartLabel = (
  label: string // Removes unnessesary characters and trims the label to a max length
) =>
  label.length > PIE_CHART_LABELS_MAX_CHARS
    ? `${label
        .replaceAll('[\\",\\\',\\`]+', '')
        .replaceAll('\\s+', ' ')
        .slice(0, PIE_CHART_LABELS_MAX_CHARS)}...`
    : label.replaceAll('[\\",\\\',\\`]+', '').replaceAll('\\s+', ' ');
