import { Box, Button, Tooltip } from '@mui/material';

import { useTranslations } from 'context/TranslationContext';

interface ActionButtonsProps {
  isFormValid?: boolean;
  shouldVerify?: boolean;
  handleBack?: () => void;
  handleCreate?: () => void;
}

export const ActionButtons = ({
  isFormValid,
  shouldVerify,
  handleBack,
  handleCreate
}: ActionButtonsProps) => {
  const { translate } = useTranslations();
  return (
    <Box
      sx={{
        pb: 3,
        display: 'flex',
        justifyContent: handleBack ? 'space-between' : 'flex-end',
        alignItems: 'center'
      }}
    >
      {handleBack && (
        <Button onClick={handleBack} variant="outlined" color="primary">
          {translate('buttons.back')}
        </Button>
      )}
      {handleCreate && (
        <Button onClick={handleCreate} variant="contained" color="primary">
          {translate('buttons.create')}
        </Button>
      )}
      {!handleCreate && (
        <Tooltip
          placement="left"
          title={!isFormValid ? translate('messages.formIsInvalid') : ''}
        >
          <span>
            <Button
              disabled={!isFormValid}
              type="submit"
              variant="contained"
              color="primary"
            >
              {translate(shouldVerify ? 'buttons.verify' : 'buttons.next')}
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
