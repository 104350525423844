/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyBudgetListResponse } from '../models/CompanyBudgetListResponse';
import type { CompanyExpenseListResponse } from '../models/CompanyExpenseListResponse';
import type { CounterpartyExpenseListResponse } from '../models/CounterpartyExpenseListResponse';
import type { InvoicesReceivedListResponse } from '../models/InvoicesReceivedListResponse';
import type { QuarterlyExpenseListResponse } from '../models/QuarterlyExpenseListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * This method returns the quarterly expenses for given year if there are no expenses for a year it returns only the list of years
     * @param year The company id
     * @returns QuarterlyExpenseListResponse OK
     * @throws ApiError
     */
    public static getQuarterlyExpensesByYear(
        year: number,
    ): CancelablePromise<QuarterlyExpenseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboards/quarterly-expenses',
            query: {
                'year': year,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the invoices received for given year if there are no invoices for a year it returns values as 0
     * @param year The company id
     * @returns InvoicesReceivedListResponse OK
     * @throws ApiError
     */
    public static getInvoicesReceivedByYear(
        year: number,
    ): CancelablePromise<InvoicesReceivedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboards/invoices-received',
            query: {
                'year': year,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the expenses for given company id
     * @param id The company id
     * @param year The year
     * @returns CompanyExpenseListResponse OK
     * @throws ApiError
     */
    public static getExpensesByCompanyId(
        id: number,
        year: number,
    ): CancelablePromise<CompanyExpenseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/dashboards/expenses',
            path: {
                'id': id,
            },
            query: {
                'year': year,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the expenses for given company id
     * @param id The company id
     * @param year The year
     * @returns CounterpartyExpenseListResponse OK
     * @throws ApiError
     */
    public static getCounterpartyExpenseByCompanyId(
        id: number,
        year: number,
    ): CancelablePromise<CounterpartyExpenseListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/dashboards/counterparties',
            path: {
                'id': id,
            },
            query: {
                'year': year,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the budgets and the actual spendings for a given company by id
     * @param id The company id
     * @param year The year
     * @param month The month
     * @returns CompanyBudgetListResponse OK
     * @throws ApiError
     */
    public static getBudgetByCompanyId(
        id: number,
        year: number,
        month?: number,
    ): CancelablePromise<CompanyBudgetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/dashboards/budgets',
            path: {
                'id': id,
            },
            query: {
                'year': year,
                'month': month,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
