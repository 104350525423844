/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BudgetPut } from '../models/BudgetPut';
import type { BudgetResponse } from '../models/BudgetResponse';
import type { ExpenseTypeBudgetListResponse } from '../models/ExpenseTypeBudgetListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BudgetService {

    /**
     * This method returns the list of budgets by year
     * @param companyId The company id
     * @param year The budget year
     * @returns ExpenseTypeBudgetListResponse OK
     * @throws ApiError
     */
    public static getAllBudgetsByYear(
        companyId: number,
        year: number,
    ): CancelablePromise<ExpenseTypeBudgetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/budgets',
            path: {
                'companyId': companyId,
            },
            query: {
                'year': year,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method updates the budget by id
     * @param companyId The company id
     * @param budgetId The budget id
     * @param requestBody The Budget to update
     * @returns BudgetResponse OK
     * @throws ApiError
     */
    public static updateBudgetById(
        companyId: number,
        budgetId: number,
        requestBody: BudgetPut,
    ): CancelablePromise<BudgetResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{companyId}/budgets/{budgetId}',
            path: {
                'companyId': companyId,
                'budgetId': budgetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
