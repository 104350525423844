import { useCallback } from 'react';

import { AmountCreate, AmountService } from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import { useInterceptor } from 'hooks/useInterceptor';

import { AmountPatch } from '../../openapi/models/AmountPatch';

export const useAmountsController = () => {
  const { interceptRequest } = useInterceptor();
  const {
    getAmountsByCompanyId,
    createAmount,
    deleteAmountsByIds,
    updateAmountById
  } = AmountService;

  const { translate } = useTranslations();

  const getAmounts = useCallback(
    (companyId: number) =>
      interceptRequest(getAmountsByCompanyId, {}, companyId),
    [getAmountsByCompanyId]
  );

  const createAmountForCompany = useCallback(
    (companyId: number, body: AmountCreate) =>
      interceptRequest(
        createAmount,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyCreated'))
        },
        companyId,
        body
      ),
    [createAmount]
  );

  const updateAmount = useCallback(
    (companyId: number, amountId: number, body: AmountPatch) =>
      interceptRequest(
        updateAmountById,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyUpdated'))
        },
        companyId,
        amountId,
        body
      ),
    [updateAmountById]
  );

  const deleteAmounts = useCallback(
    (companyId: number, amountIds: Array<number>) =>
      interceptRequest(deleteAmountsByIds, {}, companyId, amountIds),
    [deleteAmountsByIds]
  );

  return {
    getAmounts,
    createAmountForCompany,
    updateAmount,
    deleteAmounts
  };
};
