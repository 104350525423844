import moment from 'moment';
import { useState } from 'react';

export const useMonthSelector = () => {
  const [selectedMonth, setSelectedMonth] = useState(
    moment.months().indexOf(moment().format('MMMM')) + 1
  );

  const currentYear = moment().year();

  return {
    selectedMonth,
    setSelectedMonth,
    currentYear
  };
};
