import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

import { ResourceTypes } from 'openapi';

import { Scopes } from 'utils/enums/Scopes';
import { PermissionsObject } from 'utils/interfaces/Permissions';

interface PermissionsContextType {
  permissions: PermissionsObject;
  updatePermissions: (permissions: PermissionsObject) => void;
  checkPermission: (permission: ResourceTypes, scope: Scopes[]) => boolean;
}

const PermissionsContext = createContext<PermissionsContextType>(
  {} as PermissionsContextType
);

interface PermissionsProviderProps {
  readonly children: ReactNode;
  readonly initialPermissions: PermissionsObject;
}

const PermissionsProvider = ({
  children,
  initialPermissions
}: PermissionsProviderProps) => {
  const [permissions, setPermissions] =
    useState<PermissionsObject>(initialPermissions);

  const checkPermission = useCallback(
    (permission: ResourceTypes, scopes: Scopes[]) => {
      return scopes.every((s) => permissions[permission]?.[s]);
    },
    [permissions]
  );

  const context = useMemo(
    () => ({
      permissions,
      updatePermissions: setPermissions,

      checkPermission
    }),
    [permissions]
  );

  return (
    <PermissionsContext.Provider value={context}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = (): PermissionsContextType => {
  const context = useContext(PermissionsContext);

  if (context === undefined) {
    throw new Error(
      'usePermissions must be used with-in a PermissionsProvider'
    );
  }

  return context;
};

export default PermissionsProvider;
