import { Box, Grid, Switch, Tooltip } from '@mui/material';
import { useMemo } from 'react';

import { Iban } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

interface VerifyCounterPartyIbanButtonProps {
  iban: Iban & { tempId?: number };
  isSelected: boolean;
  handleToggle: () => void;
  disabled?: boolean;
}
export const VerifyCounterPartyIbanButton = ({
  iban,
  isSelected,
  handleToggle,
  disabled
}: VerifyCounterPartyIbanButtonProps) => {
  const { translate } = useTranslations();

  const verifyTooltipLabel = useMemo(() => {
    return iban.isSelectedForPayment
      ? translate('buttons.unverifyIban')
      : translate('buttons.verifyIban');
  }, [iban]);

  return iban.isUsed ? (
    <Grid container justifyContent="center" sx={{ width: '15%', ml: 4 }}>
      <Tooltip
        title={translate('messages.ibanIsUsed')}
        arrow
        placement="left"
        slotProps={{ tooltip: { sx: { fontSize: '1rem' } } }}
      >
        <Box>
          <Switch checked disabled />
        </Box>
      </Tooltip>
    </Grid>
  ) : (
    <Grid container justifyContent="center" sx={{ width: '15%', ml: 4 }}>
      <Tooltip
        title={verifyTooltipLabel}
        arrow
        placement="left"
        slotProps={{ tooltip: { sx: { fontSize: '1rem' } } }}
      >
        <Switch
          disabled={disabled}
          checked={isSelected}
          onChange={handleToggle}
          color="primary"
        />
      </Tooltip>
    </Grid>
  );
};
