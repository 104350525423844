import { green, grey, red } from '@mui/material/colors';

export const approvedIconStyle = {
  fontSize: 40,
  ml: 0.8,
  mr: 1,
  color: green[600]
};

export const rejectedIconStyle = {
  fontSize: 40,
  ml: 0.8,
  mr: 1,
  color: red[600]
};

export const awaitingApprovalIconStyle = {
  fontSize: 40,
  ml: 0.8,
  mr: 1,
  color: grey[500]
};
