/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AmountCreate } from '../models/AmountCreate';
import type { AmountListResponse } from '../models/AmountListResponse';
import type { AmountPatch } from '../models/AmountPatch';
import type { AmountResponse } from '../models/AmountResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AmountService {

    /**
     * This method returns the amounts for given company id
     * @param companyId The company id
     * @returns AmountListResponse OK
     * @throws ApiError
     */
    public static getAmountsByCompanyId(
        companyId: number,
    ): CancelablePromise<AmountListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/amounts',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create amount for company
     * @param companyId The company id
     * @param requestBody The amount object
     * @returns AmountResponse CREATED
     * @throws ApiError
     */
    public static createAmount(
        companyId: number,
        requestBody: AmountCreate,
    ): CancelablePromise<AmountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/amounts',
            path: {
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple amounts for company
     * @param companyId The company id
     * @param amountIds List of amount ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteAmountsByIds(
        companyId: number,
        amountIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{companyId}/amounts',
            path: {
                'companyId': companyId,
            },
            query: {
                'amountIds': amountIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get single amount for company
     * @param companyId The company id
     * @param amountId The amount id
     * @returns AmountResponse OK
     * @throws ApiError
     */
    public static getAmountById(
        companyId: number,
        amountId: number,
    ): CancelablePromise<AmountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/amounts/{amountId}',
            path: {
                'companyId': companyId,
                'amountId': amountId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update amount for company
     * @param companyId The company id
     * @param amountId The amount id
     * @param requestBody The amount object
     * @returns AmountResponse OK
     * @throws ApiError
     */
    public static updateAmountById(
        companyId: number,
        amountId: number,
        requestBody: AmountPatch,
    ): CancelablePromise<AmountResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/companies/{companyId}/amounts/{amountId}',
            path: {
                'companyId': companyId,
                'amountId': amountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
