import { Box, CircularProgress, Stack } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PayrollController } from 'api/controllers/PayrollController';

import { PayrollSummary } from 'openapi';

import { PayrollAccordion } from 'components/Payroll/PayrollAccordion';
import { Stepper } from 'components/shared/Stepper/Stepper';

import { MONTHS } from 'utils/constants/constants';
import { getPayrollSummaryTotal } from 'utils/helpers/payrollHelper';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';

export const Payroll = () => {
  const { getPayrollSummary } = PayrollController();
  const { companyId } = useParams();
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [availableYears, setAvailableYears] = useState<number[]>([
    moment().year()
  ]);

  const [summaries, setSummaries] = useState<PayrollSummary[]>();

  const selectedYearIndex = useMemo(
    () => availableYears.indexOf(selectedYear),
    [selectedYear, availableYears]
  );

  const handleYearChange = (step: number) => {
    setSelectedYear(availableYears[step]);
  };

  const fetchPayrollYears = async () => {
    const response = await getPayrollSummary(Number(companyId));
    setSummaries(response);
    const years: number[] = [];
    response.map((summary: PayrollSummary) => years.push(summary.year));
    setAvailableYears([...new Set([...years, moment().year()])]);
  };

  useEffect(() => {
    fetchPayrollYears();
  }, []);

  return summaries ? (
    <Stack
      overflow="hidden"
      sx={{
        ...commonDataGridContainerStyle
      }}
    >
      <Stepper
        steps={availableYears}
        activeStepIndex={selectedYearIndex}
        setActiveStep={handleYearChange}
      />
      <Stack flexGrow={1} overflow="hidden">
        <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: 1 }}>
          {MONTHS.map((month) => (
            <PayrollAccordion
              key={month}
              month={month}
              totalFromSummary={getPayrollSummaryTotal(
                summaries,
                selectedYear,
                month
              )}
              year={selectedYear}
              fetchPayrollYears={fetchPayrollYears}
            />
          ))}
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
