import { Box, CircularProgress } from '@mui/material';

interface OverlayLoadingProps {
  message?: string;
}

export const OverlayLoading = ({ message }: OverlayLoadingProps) => {
  return (
    <Box
      className="overlay-loading"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '2rem',
        fontWeight: 'bold'
      }}
    >
      {message && <Box>{message}</Box>}
      <CircularProgress color="secondary" size="5rem" />
    </Box>
  );
};
