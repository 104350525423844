export const LANGUAGE_CONFIG = {
  id: 0,
  name: 'Translation Service',
  defaultLanguage: {
    id: 0,
    language: 'English',
    code: 'en'
  },
  defaultPlatformId: 0,
  filePath: 'string',
  languages: [
    {
      id: 0,
      language: 'English',
      code: 'en'
    },
    {
      id: 1,
      language: 'Български',
      code: 'bg'
    }
  ],
  description: 'string'
};

export const APP_LANGUAGE = 'app_language';
