import { useCallback } from 'react';

import {
  ExpenseType,
  ExpenseTypeCreate,
  ExpenseTypeService,
  IdList
} from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useExpenseTypeController = () => {
  const { interceptRequest } = useInterceptor();

  const {
    getExpenseTypesByCompanyId,
    createExpenseType,
    updateExpenseTypeById,
    getExpenseTypeById,
    deleteExpenseTypesByIds,
    toggleExpenseTypeActivation
  } = ExpenseTypeService;

  const getExpenseTypes = useCallback(
    (id: number): Promise<ExpenseType[]> =>
      interceptRequest(getExpenseTypesByCompanyId, {}, id),
    [getExpenseTypesByCompanyId]
  );

  const createNewExpenseType = useCallback(
    (id: number, body: ExpenseTypeCreate) =>
      interceptRequest(createExpenseType, {}, id, body),
    [createExpenseType]
  );

  const editExpenseType = useCallback(
    (id: number, expenseTypeId: number, body: ExpenseTypeCreate) =>
      interceptRequest(updateExpenseTypeById, {}, id, expenseTypeId, body),
    [updateExpenseTypeById]
  );

  const getSingleExpenseType = useCallback(
    (companyId: number, expenseTypeId: number) =>
      interceptRequest(getExpenseTypeById, {}, companyId, expenseTypeId),
    [getExpenseTypeById]
  );

  const deleteExpenseTypes = useCallback(
    (companyId: number, expenseTypeIds: number[]) =>
      interceptRequest(deleteExpenseTypesByIds, {}, companyId, expenseTypeIds),
    [deleteExpenseTypesByIds]
  );

  const toggleActiveStatusForExpenseTypes = useCallback(
    (companyId: number, body: IdList) =>
      interceptRequest(toggleExpenseTypeActivation, {}, companyId, body),
    [createExpenseType]
  );

  return {
    getExpenseTypes,
    createNewExpenseType,
    editExpenseType,
    getSingleExpenseType,
    deleteExpenseTypes,
    toggleActiveStatusForExpenseTypes
  };
};
