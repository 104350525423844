import {
  AUTO_APPROVAL_THRESHOLD_MAX_LENGTH,
  REGEX_DECIMAL_NUMBERS,
  REGEX_MONEY_FORMAT_SPACING,
  REGEX_SUM_OF_MONEY
} from 'utils/constants/constants';

export const formatMoney = (amount?: number): string => {
  if (!amount) {
    return '0.00';
  }
  const [integerPart, decimalPart] = amount.toFixed(2).split('.');
  const formattedIntegerPart = integerPart.replace(
    REGEX_MONEY_FORMAT_SPACING,
    '\xa0'
  );
  return `${formattedIntegerPart}.${decimalPart}`;
};

export const processMoneyInput = (
  value: string,
  prevValue: string,
  maxCharacters?: number
) => {
  if (value === '') {
    return '0';
  }

  if (maxCharacters && value.length > maxCharacters) {
    return prevValue;
  }

  const valueWithLeadingZero = value[0] === '.' ? `0${value}` : value;

  const cleanedValue =
    valueWithLeadingZero.length >= 2 &&
    valueWithLeadingZero[0] === '0' &&
    valueWithLeadingZero[1] !== '.'
      ? valueWithLeadingZero.slice(1)
      : valueWithLeadingZero;

  const isValidValue =
    cleanedValue === '' || REGEX_DECIMAL_NUMBERS.test(cleanedValue);

  if (
    !isValidValue ||
    cleanedValue.length > AUTO_APPROVAL_THRESHOLD_MAX_LENGTH
  ) {
    return prevValue;
  }

  const isValidMoneyValue =
    !Number.isNaN(Number(cleanedValue)) &&
    REGEX_SUM_OF_MONEY.test(cleanedValue);

  return isValidMoneyValue ? cleanedValue : prevValue;
};

export const formatNumberWithSpaces = (value: number): string => {
  return new Intl.NumberFormat('fr-FR').format(value);
};
