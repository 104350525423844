export const INVOICES_FILTERS_EXPANDED_HEIGHT = 162;
export const COUNTERPARTIES_FILTERS_EXPANDED_HEIGHT = 66;
export const FILTER_BAR_HEIGHT = 32;
export const REIMBURSEMENT_FILTER_OPTIONS = [
  {
    labelKey: 'buttons.counterParties',
    value: 'false'
  },
  { labelKey: 'labels.reimbursements', value: 'true' }
];
