/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CounterpartySortColumns {
    NAME = 'NAME',
    REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
    VAT_NUMBER = 'VAT_NUMBER',
    EXPENSE_TYPE = 'EXPENSE_TYPE',
}
