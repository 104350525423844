import moment from 'moment';

export const parseDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.').map(Number);
  return new Date(year, month - 1, day);
};

export const sortDates = (v1: string, v2: string) => {
  const date1 = parseDate(v1);
  const date2 = parseDate(v2);
  // @ts-ignore
  return date1 - date2;
};

export const shouldDisableEndDate = (
  date: moment.MomentInput,
  startDate: moment.MomentInput | null
): boolean => {
  return moment(date).isBefore(moment(startDate));
};

export const shouldDisableStartDate = (
  date: moment.MomentInput,
  endDate: moment.MomentInput | null
): boolean => {
  return moment(date).isAfter(moment(endDate));
};
