/* eslint-disable consistent-return */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { GridSortModel, GridPaginationModel } from '@mui/x-data-grid';
import imageCompression from 'browser-image-compression';
import { EventSourcePolyfill } from 'event-source-polyfill';
import heic2any from 'heic2any';
import { useAtom } from 'jotai';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useIbanController } from 'api/controllers/IbanController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  Company,
  CompanyIban,
  Invoice,
  InvoiceFilters,
  OpenAPI,
  SortOrder
} from 'openapi';

import { InvoicesDataGrid } from 'components/InvoicesDataGrid/InvoicesDataGrid';
import { Layout } from 'components/Layout/Layout';
import { OverlayLoading } from 'components/overlays/OverlayLoading';
import { Modal } from 'components/shared/Modal/Modal';
import { TokenExpirationModal } from 'components/shared/Modal/TokenExpirationModal';

import { usePermissions } from 'context/PermissionsContext';
import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';
import { usePagination } from 'hooks/usePagination';

import {
  ACCESS_TOKEN,
  BUTTON,
  HEARTBEAT_TIMEOUT,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
  TOKEN_EXPIRY_WARNING_TIME_SECONDS
} from 'utils/constants/constants';
import { INITIAL_INVOICES_FILTERS } from 'utils/constants/invoices';
import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { FilterType } from 'utils/enums/FilterType';
import { InvoiceEventType } from 'utils/enums/Invoice';
import { getInvoicesFiltersAtomByType } from 'utils/helpers/filtersHelpers';
import { handleSortModelChange } from 'utils/helpers/invoiceHelpers';
import {
  getEventData,
  getSSERequestOptions,
  getUrl
} from 'utils/helpers/serverSentEvents';
import {
  getFileSizeWarningMessage,
  getInvoiceWarningMessage,
  showWarnMessage
} from 'utils/helpers/toastHelpers';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import {
  getMappedInvoiceFilters,
  getMappedInvoices
} from 'utils/mappers/invoice';
import { AppRoutesEnum } from 'utils/routes';

import {
  invoicesPaginationAtom,
  filterTypeAtom,
  isSavingAtom,
  userAtom,
  selectedCompanyIdAtom
} from 'state/state';

interface DocumentsUploadProps {
  isOnAllCompanies?: boolean;
}

export const DocumentsUpload = ({
  isOnAllCompanies
}: DocumentsUploadProps): JSX.Element => {
  const { companyId } = useParams();
  const {
    addInvoice,
    getInvoiceFiltersByCompany,
    getInvoicesByCompanyAndFilters,
    getInvoiceRow,
    getFilteredInvoices,
    getInvoiceFiltersForAllCompanies
  } = useInvoiceController();
  const { getAllCompanies } = useCompanyController();
  const { getIbansByCompany } = useIbanController();
  const { translate } = useTranslations();
  const permissions = usePermissions();
  const { isRefreshedToken, handleLogout, handleRefresh } = useContext(
    TokenExpirationModalContext
  );

  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [blobArray, setBlobArray] = useState<FileList | File[] | Blob[]>();
  const [token, setToken] = useState<string>();
  const [isSessionExpiredModalVisible, setIsSessionExpiredModalVisible] =
    useState<boolean>(false);

  const [filterType] = useAtom(filterTypeAtom);
  const [pagination, setPagination] = useAtom(invoicesPaginationAtom);
  const [isSaving] = useAtom(isSavingAtom);
  const [filters, setFilters] = useAtom(
    getInvoicesFiltersAtomByType(filterType)
  );
  const [user] = useAtom(userAtom);
  const [selectedCompanyId, setSelectedCompanyId] = useAtom(
    selectedCompanyIdAtom
  );

  const { paginationModel, handlePaginationModelChange } = usePagination({
    page: pagination.page,
    pageSize: pagination.pageSize,
    rowCount: totalInvoices
  });

  const {
    isOpen: isSelectCompanyIdModalOpen,
    openModal: openSelectCompanyIdModal,
    closeModal: closeSelectCompanyIdModal
  } = useModal();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesFiltersData, setInvoicesFiltersData] =
    useState<InvoiceFilters>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [companyIbans, setCompanyIbans] = useState<CompanyIban[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const filtersRef = useRef(filters);
  const isFilteredRef = useRef(isFiltered);
  const filterTypeRef = useRef(filterType);

  const fetchAllCompaniesInvoices = useCallback(async () => {
    const currentFilters = filtersRef.current;
    setIsLoading(true);
    const result = await getFilteredInvoices({
      ...currentFilters,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      sortBy: currentFilters.sortBy,
      sortOrder: currentFilters.sortOrder?.toUpperCase() as SortOrder
    });

    const mappedInvoices = getMappedInvoices(result.invoices);

    setInvoices(mappedInvoices);
    setTotalInvoices(result.totalElements);
    setIsLoading(false);
  }, [paginationModel, getFilteredInvoices, filtersRef]);

  const fetchAllCompaniesInvoiceFilters = useCallback(async () => {
    const filtersData = await getInvoiceFiltersForAllCompanies();
    setInvoicesFiltersData(filtersData);
  }, [getInvoiceFiltersForAllCompanies]);

  const fetchCompanies = useCallback(async () => {
    const result = await getAllCompanies();
    setCompanies(result);
  }, [getAllCompanies]);

  const fetchCompanyInvoices = useCallback(async () => {
    setIsLoading(true);
    const currentFilters = filtersRef.current;
    const result = await getInvoicesByCompanyAndFilters(Number(companyId), {
      ...currentFilters,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      sortBy: currentFilters.sortBy,
      sortOrder: currentFilters.sortOrder?.toUpperCase() as SortOrder
    });

    const mappedInvoices = getMappedInvoices(result.invoices);
    setInvoices(mappedInvoices);
    setTotalInvoices(result.totalElements || 0);
    setIsLoading(false);
  }, [companyId, paginationModel, getInvoicesByCompanyAndFilters, filtersRef]);

  const fetchInvoiceFiltersByCompany = useCallback(async () => {
    const result = await getInvoiceFiltersByCompany(Number(companyId));
    setInvoicesFiltersData(result);
  }, [companyId]);

  const handleApplyFilters = useCallback(
    (data: InvoicesFiltersForm | null) => {
      const mappedFilters = getMappedInvoiceFilters(
        data || ({} as InvoicesFiltersForm)
      );
      setIsFiltered(!!data);
      // @ts-ignore
      setFilters({
        ...INITIAL_INVOICES_FILTERS,
        ...mappedFilters
      });
      handlePaginationModelChange({
        ...paginationModel,
        page: INITIAL_PAGINATION_PROPS.page
      });
    },
    [setFilters, paginationModel]
  );

  const handleSortChange = useCallback(
    (sortModel: GridSortModel) => {
      handleSortModelChange(sortModel, setFilters);
    },
    [setFilters]
  );

  const handleUploadInvoice = async (
    fileData: FileList | File[],
    field: string
  ) => {
    let files: File[];
    setIsUploading(true);

    if (field === BUTTON) {
      files = Array.from(fileData as File[]);
      setBlobArray(files);
    } else {
      files = Array.from(fileData as File[]);
      setBlobArray(files);
    }

    const compressionOptions = {
      maxSizeMB: MAX_FILE_SIZE_MB - 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/jpeg',
      initialQuality: 1
    };

    const convertedFilesPromises = files.map(async (file) => {
      if (
        file.type === 'image/heic' ||
        file.name.toLowerCase().endsWith('.heic')
      ) {
        try {
          const blob = await heic2any({ blob: file, toType: 'image/jpeg' });
          const newFileName = `${file.name.replace(/\.[^/.]+$/, '')}.jpg`;
          const newFile = new File([blob as Blob], newFileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });

          const compressionOptionsWithFileName = {
            ...compressionOptions,
            fileName: newFileName
          };

          const compressedResult = await imageCompression(
            newFile,
            compressionOptionsWithFileName
          );

          let compressedFile;
          if (compressedResult instanceof File) {
            compressedFile = compressedResult;
          } else {
            compressedFile = new File([compressedResult], newFileName, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
          }

          return compressedFile;
        } catch (error) {
          return file;
        }
      } else if (file.type.startsWith('image/') && file.type !== 'image/jpeg') {
        try {
          const newFileName = `${file.name.replace(/\.[^/.]+$/, '')}.jpg`;
          const compressionOptionsWithFileName = {
            ...compressionOptions,
            fileName: newFileName
          };

          const compressedResult = await imageCompression(
            file,
            compressionOptionsWithFileName
          );

          let compressedFile;
          if (compressedResult instanceof File) {
            compressedFile = compressedResult;
          } else {
            compressedFile = new File([compressedResult], newFileName, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
          }

          return compressedFile;
        } catch (error) {
          return file;
        }
      } else {
        return file;
      }
    });

    const convertedFiles = await Promise.all(convertedFilesPromises);
    const validFiles: File[] = [];
    const invalidFileNames: string[] = [];

    convertedFiles.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        invalidFileNames.push(file.name);
      }
    });

    if (isOnAllCompanies) {
      openSelectCompanyIdModal();
      return;
    }

    try {
      if (validFiles.length > 0) {
        await addInvoice(Number(companyId), {
          files: validFiles as Array<File>
        });
      }
    } finally {
      setIsUploading(false);
      if (invalidFileNames.length > 0) {
        const { label, message } = getFileSizeWarningMessage(invalidFileNames);
        showWarnMessage(
          translate(label, {
            maxFileSize: String(MAX_FILE_SIZE_MB),
            documents: message
          })
        );
      }
    }
  };

  const handlePagination = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setInvoices([]);
      handlePaginationModelChange(newPaginationModel);
    },
    [setInvoices, handlePaginationModelChange]
  );

  useEffect(() => {
    setToken(localStorage.getItem(ACCESS_TOKEN) || '');
  }, [localStorage.getItem(ACCESS_TOKEN), isRefreshedToken]);

  const handleInvoiceEvent = useCallback(
    async (data: string) => {
      const { eventId, eventType } = getEventData(data);
      const currentFilters = filtersRef.current;

      const shouldNotUpdate =
        eventType === InvoiceEventType.HEARTBEAT ||
        eventType === InvoiceEventType.IBAN_CONSENT ||
        isFilteredRef.current ||
        filterTypeRef.current !== FilterType.Initial ||
        paginationModel.page !== INITIAL_PAGINATION_PROPS.page ||
        currentFilters.sortBy !== INITIAL_INVOICES_FILTERS.sortBy ||
        currentFilters.sortOrder !== INITIAL_INVOICES_FILTERS.sortOrder;

      if (
        shouldNotUpdate &&
        eventType !== InvoiceEventType.INVOICE_DELETE &&
        eventType !== InvoiceEventType.INVOICE_DUPLICATE
      ) {
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_DUPLICATE) {
        const { label, message } = getInvoiceWarningMessage(eventId);
        const duplicateMessage = translate(label, {
          invoices: message
        });

        showWarnMessage(duplicateMessage);
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_CREATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        if (
          !isOnAllCompanies &&
          mappedInvoice[0].companyId !== Number(companyId)
        ) {
          return;
        }
        setInvoices((prevInvoices) => [...mappedInvoice, ...prevInvoices]);
        setTotalInvoices((prevTotalInvoices) => prevTotalInvoices + 1);
        return;
      }

      if (eventType === InvoiceEventType.INVOICE_UPDATE) {
        const invoice = await getInvoiceRow(Number(eventId));
        const mappedInvoice = getMappedInvoices([invoice]);
        if (
          !isOnAllCompanies &&
          mappedInvoice[0].companyId !== Number(companyId)
        ) {
          return;
        }
        setInvoices((prevInvoices) => {
          if (prevInvoices.some((inv) => inv.id === mappedInvoice[0].id)) {
            return prevInvoices.map((inv) =>
              inv.id === mappedInvoice[0].id ? mappedInvoice[0] : inv
            );
          }
          return [...mappedInvoice, ...prevInvoices];
        });
        return;
      }

      if (isOnAllCompanies) {
        fetchAllCompaniesInvoices();
        return;
      }

      fetchCompanyInvoices();
    },
    [
      paginationModel,
      fetchCompanyInvoices,
      fetchAllCompaniesInvoices,
      invoices,
      isFiltered,
      filterTypeRef
    ]
  );

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    isFilteredRef.current = isFiltered;
  }, [isFiltered]);

  useEffect(() => {
    filterTypeRef.current = filterType;
  }, [filterType]);

  useEffect(() => {
    if (isOnAllCompanies) {
      fetchAllCompaniesInvoiceFilters();
      return;
    }
    fetchInvoiceFiltersByCompany();
  }, [fetchAllCompaniesInvoiceFilters, fetchInvoiceFiltersByCompany]);

  useEffect(() => {
    setPagination({
      page: paginationModel.page,
      pageSize: paginationModel.pageSize
    });
  }, [paginationModel]);

  useEffect(() => {
    if (isOnAllCompanies) {
      fetchAllCompaniesInvoices();
      return;
    }
    fetchCompanyInvoices();
  }, [companyId, filters, fetchCompanyInvoices, fetchAllCompaniesInvoices]);

  useEffect(() => {
    if (!Object.keys(invoicesFiltersData).length || isSaving) {
      setIsLoading(true);
    }
  }, [invoicesFiltersData, isSaving]);

  useEffect(() => {
    if (!token) {
      return;
    }
    let retryTimeout: NodeJS.Timeout | undefined;
    let heartbeatTimeout: NodeJS.Timeout | undefined;
    let retryCount = 0;

    const createEventSource = (): EventSourcePolyfill => {
      const result = new EventSourcePolyfill(
        getUrl(OpenAPI, getSSERequestOptions(user?.id)),
        {
          headers: {
            Authorization: `Bearer ${token}` || ''
          }
        }
      );

      result.onmessage = (e) => {
        const { eventType } = getEventData(e.data);

        if (eventType === InvoiceEventType.HEARTBEAT) {
          if (heartbeatTimeout) clearTimeout(heartbeatTimeout);

          heartbeatTimeout = setTimeout(() => {
            result.close();
            createEventSource();
          }, HEARTBEAT_TIMEOUT);
        } else {
          handleInvoiceEvent(e.data);
        }
      };

      result.onerror = (error) => {
        if ((error as any).status === 401) {
          setIsSessionExpiredModalVisible(true);
          result.close();
          return;
        }
        setToken(localStorage.getItem(ACCESS_TOKEN) || '');
        result.close();
        if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
        if (retryTimeout) clearTimeout(retryTimeout);

        const retryDelay = Math.min(10000, 2 ** retryCount * 1000);
        retryCount += 1;

        retryTimeout = setTimeout(() => {
          createEventSource();
        }, retryDelay);
      };

      return result;
    };

    const eventSourceInstance = createEventSource();

    return () => {
      eventSourceInstance.close();
      if (heartbeatTimeout) clearTimeout(heartbeatTimeout);
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, [token]);

  const getIbansByCompanyId = useCallback(async () => {
    const ibans = await getIbansByCompany(Number(companyId));
    setCompanyIbans(ibans);
  }, [getIbansByCompany, companyId, setCompanyIbans]);

  const handleCloseSelectCompanyIdModal = useCallback(() => {
    closeSelectCompanyIdModal();
    setIsUploading(false);
  }, [closeSelectCompanyIdModal]);

  const handleRefreshSession = useCallback(() => {
    setIsSessionExpiredModalVisible(false);
    handleRefresh();
  }, [handleRefresh]);

  useEffect(() => {
    if (isOnAllCompanies || !permissions.permissions.IBANS.read) {
      return;
    }
    getIbansByCompanyId();
  }, [getIbansByCompanyId]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return (
    <Layout>
      {isUploading && <OverlayLoading />}
      <InvoicesDataGrid
        invoicesList={invoices}
        filters={filters}
        setInvoicesList={setInvoices}
        stateRoute={
          isOnAllCompanies
            ? AppRoutesEnum.INVOICES
            : AppRoutesEnum.COMPANY_INVOICES
        }
        handleUploadInvoice={handleUploadInvoice}
        invoicesFiltersData={invoicesFiltersData}
        handleApplyFilters={handleApplyFilters}
        handlePaginationModelChange={handlePagination}
        paginationModel={paginationModel}
        rowCount={totalInvoices}
        handleSortModelChange={handleSortChange}
        fetchInvoices={
          isOnAllCompanies ? fetchAllCompaniesInvoices : fetchCompanyInvoices
        }
        isLoading={isLoading}
        companyIbans={companyIbans}
        companies={companies}
      />
      {companies?.length && (
        <Modal
          headerTitle={translate('labels.uploadDocuments')}
          isOpen={isSelectCompanyIdModalOpen}
          hide={handleCloseSelectCompanyIdModal}
          maxWidth="sm"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <FormControl fullWidth>
              <InputLabel>{`${translate('labels.company')}*`}</InputLabel>
              <Select
                label={translate('labels.company')}
                value={selectedCompanyId || companies[0].id}
                onChange={(e) => setSelectedCompanyId(Number(e.target.value))}
              >
                {companies.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{ alignSelf: 'flex-end' }}
              onClick={async () => {
                closeSelectCompanyIdModal();
                try {
                  await addInvoice(Number(selectedCompanyId), {
                    files: blobArray as Array<Blob>
                  });
                } catch (error) {
                  setIsUploading(false);
                } finally {
                  setIsUploading(false);
                }
              }}
            >
              {translate('buttons.upload')}
            </Button>
          </Box>
        </Modal>
      )}
      <TokenExpirationModal
        onLogout={handleLogout}
        onRefresh={handleRefreshSession}
        isOpen={isSessionExpiredModalVisible}
        refreshExpTime={
          new Date().getTime() + TOKEN_EXPIRY_WARNING_TIME_SECONDS * 1000
        }
      />
    </Layout>
  );
};
