import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Tooltip
} from '@mui/material';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useCompanyController } from 'api/controllers/CompanyController';

import { Company, ResourceTypes, User } from 'openapi';

import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  COMPANY_PARAMETER,
  COUNTERPARTY_BG_REG_NUMBER_LENGTH_REGEX
} from 'utils/constants/constants';
import { initialFormValues } from 'utils/constants/initialValuesCreateCompany';
import { VAT_NUMBER_PREFIX_BULGARIA } from 'utils/constants/invoices';
import { Scopes } from 'utils/enums/Scopes';
import { AppRoutesEnum } from 'utils/routes';

import { fullFieldWidth, submitButton } from 'styles/components/Common';

import { companyAtom } from 'state/state';

interface CreateUpdateCompanyFormProps {
  onDelete: () => void;
  users?: User[];
  companyDetails?: Company;
}

export const CreateUpdateCompanyForm = ({
  onDelete,
  users,
  companyDetails
}: CreateUpdateCompanyFormProps) => {
  const { companyId } = useParams();

  const [company, setCompany] = useAtom(companyAtom);

  const { editCompany, addCompany } = useCompanyController();
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { updateCompaniesList } = useCompanies();
  const [isEdit, setIsEdit] = useState(!companyId);
  const { checkPermission } = usePermissions();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();

  const initialValues = useMemo(() => {
    if (companyId && companyDetails) {
      return {
        ...initialFormValues,
        name: companyDetails.name || '',
        alias: companyDetails.alias || '',
        registrationNumber: companyDetails.registrationNumber || '',
        vatNumber: companyDetails.vatNumber || '',
        emailPrefix: companyDetails.emailPrefix || '',
        userId: companyDetails.userId
      };
    }
    return initialFormValues;
  }, [companyId, companyDetails]);

  const onSubmit = async (values: Company, currentCompany: Company) => {
    const requestBody = {
      ...values,
      ...(companyId && { id: currentCompany.id }),
      name: values.name.trim(),
      alias: values.alias.trim(),
      registrationNumber: values.registrationNumber.trim(),
      vatNumber: values.vatNumber?.trim(),
      emailPrefix: values.emailPrefix ? values.emailPrefix?.trim() : undefined
    };

    const response = companyId
      ? await editCompany(currentCompany.id as number, requestBody)
      : await addCompany(requestBody);

    setCompany(response);

    updateCompaniesList(response);
    if (companyId) {
      setIsEdit(false);
    } else {
      navigate(
        AppRoutesEnum.COMPANY_DETAILS.replace(
          COMPANY_PARAMETER,
          response.id.toString()
        )
      );
    }
  };

  const canDeleteCompany = useMemo(
    () => checkPermission(ResourceTypes.COMPANIES, [Scopes.DELETE]),
    [checkPermission]
  );

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleCancelClick = (resetForm: () => void) => {
    setIsEdit(false);
    resetForm();
  };

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().trim().required(translate('errors.companyName')),
        registrationNumber: Yup.string()
          .trim()
          .when('vatNumber', (vatNumber, innerSchema) => {
            const vatNumberStr = String(vatNumber[0] || '');
            return vatNumberStr?.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
              ? innerSchema
                  .required(translate('errors.counterpartyRegistrationNumber'))
                  .matches(
                    COUNTERPARTY_BG_REG_NUMBER_LENGTH_REGEX,
                    translate('errors.counterpartyRegistrationNumberBG')
                  )
              : innerSchema.required(
                  translate('errors.counterpartyRegistrationNumber')
                );
          }),
        vatNumber: Yup.string().trim(),
        alias: Yup.string().trim().required(translate('errors.companyAlias')),
        emailPrefix: companyDetails?.emailSuffix
          ? Yup.string().matches(
              /^[a-zA-Z0-9._%+-]+$/,
              translate('errors.invalidEmail')
            )
          : Yup.string().trim()
      }),
    [translate]
  );

  return (
    <Box>
      <Formik
        onSubmit={(values) =>
          onSubmit(values as Company, companyDetails as Company)
        }
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, touched, errors, resetForm, setFieldValue }) => {
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              <Box sx={fullFieldWidth} height={70}>
                <Field
                  as={TextField}
                  label={`${translate('labels.name')}*`}
                  name="name"
                  fullWidth
                  placeholder={`${translate('labels.name')}*`}
                  disabled={!isEdit}
                  error={!!touched.name && !!errors.name}
                  helperText={<ErrorMessage name="name" />}
                />
              </Box>
              <Box sx={fullFieldWidth} height={70}>
                <Field
                  as={TextField}
                  label={`${translate('labels.companyRegistrationNumber')}*`}
                  name="registrationNumber"
                  placeholder={`${translate(
                    'labels.companyRegistrationNumber'
                  )}*`}
                  fullWidth
                  disabled={!isEdit}
                  error={
                    !!touched.registrationNumber && !!errors.registrationNumber
                  }
                  helperText={<ErrorMessage name="registrationNumber" />}
                />
              </Box>
              <Box sx={fullFieldWidth} height={70}>
                <Field
                  as={TextField}
                  label={translate('labels.vatNumber')}
                  name="vatNumber"
                  placeholder={translate('labels.vatNumber')}
                  fullWidth
                  disabled={!isEdit}
                  error={!!touched.vatNumber && !!errors.vatNumber}
                  helperText={<ErrorMessage name="vatNumber" />}
                />
              </Box>
              <Box
                sx={fullFieldWidth}
                height={companyDetails?.emailSuffix ? 55 : 70}
              >
                <Field
                  as={TextField}
                  label={`${translate('labels.shortName')}*`}
                  name="alias"
                  placeholder={`${translate('labels.shortName')}*`}
                  disabled={!isEdit}
                  fullWidth
                  error={!!touched.alias && !!errors.alias}
                  helperText={<ErrorMessage name="alias" />}
                />
              </Box>

              {companyDetails?.emailSuffix && (
                <>
                  <Divider />

                  <Box
                    sx={{
                      ...fullFieldWidth,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    height={60}
                  >
                    <Field
                      as={TextField}
                      label={`${translate('labels.email')}`}
                      name="emailPrefix"
                      placeholder={`${translate('labels.email')}`}
                      fullWidth
                      disabled={!isEdit}
                      error={!!touched.emailPrefix && !!errors.emailPrefix}
                      helperText={<ErrorMessage name="emailPrefix" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {companyDetails.emailSuffix}
                          </InputAdornment>
                        )
                      }}
                    />
                    <Tooltip
                      title={isEdit && translate('messages.companyEmailInfo')}
                    >
                      <InfoOutlinedIcon
                        color={!isEdit ? 'disabled' : 'primary'}
                        sx={{
                          marginLeft: 1
                        }}
                      />
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{
                      ...fullFieldWidth,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    height={70}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        disabled={
                          !isEdit ||
                          !touched.emailPrefix ||
                          !!errors.emailPrefix ||
                          !values.emailPrefix
                        }
                      >
                        {translate('labels.emailUser')}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={translate('labels.emailUser')}
                        name="userId"
                        fullWidth
                        disabled={
                          !isEdit || !!errors.emailPrefix || !values.emailPrefix
                        }
                        value={values.userId || ''}
                        sx={{
                          textAlign: 'left'
                        }}
                      >
                        {users && users.length > 0 ? (
                          users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>
                            {translate('messages.usersSelectNoOptions')}
                          </MenuItem>
                        )}
                      </Field>
                    </FormControl>
                    <Tooltip
                      title={
                        isEdit &&
                        translate(
                          !!errors.emailPrefix || !values.emailPrefix
                            ? 'messages.noValidEmail'
                            : 'messages.selectResponsibleUser'
                        )
                      }
                    >
                      <InfoOutlinedIcon
                        color={!isEdit ? 'disabled' : 'primary'}
                        sx={{
                          marginLeft: 1
                        }}
                      />
                    </Tooltip>
                  </Box>
                </>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {isEdit ? (
                  <Button
                    variant="contained"
                    type="submit"
                    key="submit"
                    sx={{ ...submitButton, width: '45%' }}
                    onClick={() => {
                      if (!values.emailPrefix) {
                        setFieldValue('userId', null);
                      }
                    }}
                  >
                    {companyId
                      ? translate('buttons.save')
                      : translate('buttons.add')}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    key="edit"
                    sx={{ ...submitButton, width: '45%' }}
                    onClick={handleEditClick}
                  >
                    {translate('buttons.edit')}
                  </Button>
                )}
                {companyId && canDeleteCompany && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ ...submitButton, width: '45%' }}
                    onClick={
                      isEdit
                        ? () => handleCancelClick(resetForm)
                        : openDeleteModal
                    }
                    type={isEdit ? 'reset' : 'button'}
                  >
                    {translate(isEdit ? 'buttons.cancel' : 'buttons.delete')}
                  </Button>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={onDelete}
        title={translate('labels.deleteCompany')}
        showInputField
        confirmationString={companyDetails?.name}
        size="sm"
      >
        {translate('messages.deleteCompanyConfirmation')}
      </ConfirmationDialog>
    </Box>
  );
};
