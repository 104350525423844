import { useCallback } from 'react';

import { BudgetPut, BudgetService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useBudgetingController = () => {
  const { interceptRequest } = useInterceptor();

  const { getAllBudgetsByYear, updateBudgetById } = BudgetService;

  const getBudgets = useCallback(
    (companyId: number, year: number) => {
      return interceptRequest(getAllBudgetsByYear, {}, companyId, year);
    },
    [getAllBudgetsByYear]
  );

  const updateBudget = useCallback(
    (companyId: number, budgetId: number, body: BudgetPut) =>
      interceptRequest(updateBudgetById, {}, companyId, budgetId, body),
    [updateBudgetById]
  );

  return {
    getBudgets,
    updateBudget
  };
};
