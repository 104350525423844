import {
  GridCellParams,
  GridTreeNode,
  GridValueGetterParams
} from '@mui/x-data-grid';

import { InvoiceStages } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import {
  DEFAULT_COMPANY_REGISTRATION_NUMBER,
  DRAFT_INVOICE_STYLE,
  CHECK
} from 'utils/constants/constants';
import { SEVERITY_COLORS } from 'utils/constants/invoices';

export const getColumnValue = (params: GridValueGetterParams) => {
  return params.value || '';
};

export const useDataGridHelpers = () => {
  const { translate } = useTranslations();

  const getCounterPartyTypeValue = (params: GridValueGetterParams) => {
    const type = params.value.toLowerCase();

    return translate(`labels.${type}`);
  };

  const getNameValue = (params: GridValueGetterParams) => {
    const { name } = params.value;

    return name || translate('labels.loading');
  };

  const getPercentageValue = (params: GridValueGetterParams) => {
    return `${params.value * 100}%`;
  };

  const getYesNoValue = (params: GridValueGetterParams) => {
    return params.value ? translate('labels.yes') : translate('labels.no');
  };

  const getExpenseTypeValue = (params: GridValueGetterParams) =>
    params.value?.expenseType;

  const getCompanyRegistrationNumberValue = (params: GridValueGetterParams) => {
    const { withoutCompanyRegistrationNumber } = params.row;

    return withoutCompanyRegistrationNumber
      ? DEFAULT_COMPANY_REGISTRATION_NUMBER
      : params.value;
  };

  const getCellValue = (params: GridValueGetterParams) => params?.value?.value;

  const getTransactionCellClassName = (
    params: GridCellParams<any, any, any, GridTreeNode>
  ) => {
    if (params.field !== CHECK && !params.row[params.field]?.isValid) {
      return DRAFT_INVOICE_STYLE;
    }
    return '';
  };

  const getSeverityColor = (value: InvoiceStages) => {
    return (
      SEVERITY_COLORS[value] || {
        color: 'rgb(153,153,153)',
        backgroundColor: 'rgba(153,153,153,0.1)'
      }
    );
  };

  return {
    getPercentageValue,
    getCounterPartyTypeValue,
    getYesNoValue,
    getExpenseTypeValue,
    getNameValue,
    getCompanyRegistrationNumberValue,
    getCellValue,
    getTransactionCellClassName,
    getSeverityColor
  };
};
