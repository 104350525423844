import { toast } from 'react-toastify';

import { ApiError } from 'openapi';

import { TOAST_ERROR_MESSAGE } from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

const ERROR_MAP = {
  COUNTERPARTY_HAS_NO_VALID_BULGARIAN_IBAN: 'errors.counterPartyNoValidBGiban',
  NOT_POSTBANK_IBAN: 'errors.notPostbankIban',
  BANK_WITH_IBAN_DOES_NOT_SUPPORT_EXPORT_FOR_PAYMENT:
    'errors.bankWithIbanDoesNotSupportExportForPayment'
};
export const showErrorToastMessage = (
  errorMessage?: ApiError | string,
  translate?: TranslateFunction
) => {
  let error;
  let translationKey;

  if (errorMessage instanceof ApiError) {
    error = errorMessage.body.error.detail as string;
    const errorKey = error.trim();
    translationKey = ERROR_MAP[errorKey as keyof typeof ERROR_MAP];
  } else if (errorMessage?.length) {
    error = errorMessage;
  } else {
    error = TOAST_ERROR_MESSAGE;
  }

  const message =
    translationKey && translate ? translate(translationKey) : error;

  toast.error(`${message}`, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export const showSuccessToastMessage = (successMessage?: string) => {
  toast.success(`${successMessage}`, {
    position: toast.POSITION.TOP_RIGHT
  });
};
