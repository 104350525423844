import React, { useContext, ReactNode, useState, useMemo } from 'react';

import { Company } from 'openapi';

type Companies = {
  companiesList: Company[];
  isCompaniesListLoading: boolean;
  updateCompaniesList: (company: Company) => void;
  setCompaniesList: (companies: Company[]) => void;
  removeCompany: (companyId: number) => void;
};

const CompaniesContext = React.createContext<Companies>({
  companiesList: [],
  isCompaniesListLoading: true,
  updateCompaniesList: () => [],
  setCompaniesList: () => [],
  removeCompany: () => []
});

interface CompaniesProviderProps {
  readonly children: ReactNode;
}

export const CompaniesProvider = ({ children }: CompaniesProviderProps) => {
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [isCompaniesListLoading, setIsCompaniesListLoading] =
    useState<boolean>(true);

  const updateCompaniesList = (newCompany: Company) => {
    const existingComapany = companiesList.find(
      (el) => el.id === newCompany.id
    );

    if (existingComapany) {
      setCompaniesList(
        companiesList.map((company: Company) =>
          company.id === newCompany.id ? { ...newCompany } : company
        )
      );
    } else {
      setCompaniesList([...companiesList, newCompany]);
    }
  };

  const removeCompany = (companyId: number) => {
    setCompaniesList(
      companiesList.filter((company: Company) => company.id !== companyId)
    );
  };

  const context = useMemo(
    () => ({
      companiesList,
      isCompaniesListLoading,
      updateCompaniesList,
      setCompaniesList: (companies: Company[]) => {
        setCompaniesList(companies);
        setIsCompaniesListLoading(false);
      },
      removeCompany
    }),
    [companiesList]
  );

  return (
    <CompaniesContext.Provider value={context}>
      {children}
    </CompaniesContext.Provider>
  );
};

export const useCompanies = (): Companies => {
  return useContext(CompaniesContext);
};
