import { useCallback } from 'react';

import { PayrollService } from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import { useInterceptor } from 'hooks/useInterceptor';

export const PayrollController = () => {
  const { interceptRequest } = useInterceptor();
  const {
    getPayrollByCompanyId,
    getPayrollSummaryList,
    uploadPayrollFile,
    deletePayrollData
  } = PayrollService;

  const { translate } = useTranslations();

  const getPayroll = useCallback(
    (companyId: number, year: number, month: number) =>
      interceptRequest(getPayrollByCompanyId, {}, companyId, year, month),
    [getPayrollByCompanyId]
  );

  const getPayrollSummary = useCallback(
    (companyId: number) =>
      interceptRequest(getPayrollSummaryList, {}, companyId),
    [getPayrollSummaryList]
  );

  const uploadPayroll = useCallback(
    (
      companyId: number,
      formData: {
        year: number;
        month: number;
        file: Blob;
      }
    ) =>
      interceptRequest(
        uploadPayrollFile,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyUploaded'))
        },
        companyId,
        formData
      ),
    [uploadPayrollFile]
  );

  const deletePayroll = useCallback(
    (companyId: number, year: number, month: number) =>
      interceptRequest(deletePayrollData, {}, companyId, year, month),
    [deletePayrollData]
  );

  return {
    getPayroll,
    getPayrollSummary,
    uploadPayroll,
    deletePayroll
  };
};
