import { useCallback } from 'react';

import {
  InvoiceService,
  ExportService,
  InvoicePatch,
  InvoiceDelete,
  InvoiceSection,
  PaymentChannel
} from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import { useInterceptor } from 'hooks/useInterceptor';

import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

export const useInvoiceController = () => {
  const { interceptRequest } = useInterceptor();

  const {
    uploadInvoice,
    getInvoicePdfById,
    deleteInvoicesByIds,
    verifyInvoiceSectionById,
    repromptInvoiceById,
    getInvoiceById,
    getInvoiceFiltersByCompanyId,
    getInvoiceFilters,
    getInvoicesByCompanyIdAndFilters,
    getInvoicesByFilters,
    getInvoiceRowById,
    unverifyInvoiceSectionById,
    setIsAccountedToTrue,
    payInvoices,
    approveInvoices,
    rejectInvoices,
    createWithoutFile,
    updateInvoiceById,
    verifyWithoutFileById,
    updateInvoiceSectionById,
    returnToValidated,
    updateExpenseTypeDuringApproval
  } = InvoiceService;

  const { exportInvoicesForPayment } = ExportService;

  const { translate } = useTranslations();

  const addInvoice = (id: number, formData: { files: Array<Blob> }) =>
    interceptRequest(
      uploadInvoice,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUploaded'))
      },
      id,
      formData
    );

  const getInvoiceFile = useCallback(
    (invoiceId: number) => interceptRequest(getInvoicePdfById, {}, invoiceId),
    [getInvoicePdfById]
  );

  const getSingleInvoiceById = useCallback(
    (companyId: number, id: number) =>
      interceptRequest(getInvoiceById, {}, companyId, id),
    [getInvoiceById]
  );

  const deleteInvoices = (requestBody: InvoiceDelete) =>
    interceptRequest(
      deleteInvoicesByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      requestBody
    );

  const exportSelectedInvoicesForPayment = (
    id: number,
    iban: string,
    invoiceIds: Array<number>
  ) => interceptRequest(exportInvoicesForPayment, {}, id, iban, invoiceIds);

  const updateInvoice = useCallback(
    (
      companyId: number,
      invoiceId: number,
      invoiceSection: InvoiceSectionKey,
      requestBody: InvoicePatch
    ) =>
      interceptRequest(
        verifyInvoiceSectionById,
        {},
        companyId,
        invoiceId,
        invoiceSection,
        requestBody
      ),
    [verifyInvoiceSectionById]
  );

  const verifyInvoiceSection = useCallback(
    (id: number, invoiceSection: string) =>
      interceptRequest(verifyInvoiceSectionById, {}, id, invoiceSection),
    [verifyInvoiceSectionById]
  );

  const unverifyInvoiceById = useCallback(
    (companyId: number, invoiceId: number, section: InvoiceSection) =>
      interceptRequest(
        unverifyInvoiceSectionById,
        {},
        companyId,
        invoiceId,
        section
      ),
    [unverifyInvoiceSectionById]
  );

  const rempromptInvoice = useCallback(
    (id: number) => interceptRequest(repromptInvoiceById, {}, id),
    [repromptInvoiceById]
  );

  const getInvoiceFiltersByCompany = useCallback(
    (companyId: number) =>
      interceptRequest(getInvoiceFiltersByCompanyId, {}, companyId),
    [getInvoiceFiltersByCompanyId]
  );

  const getInvoiceFiltersForAllCompanies = useCallback(
    () => interceptRequest(getInvoiceFilters, {}),
    [getInvoiceFilters]
  );

  const getInvoicesByCompanyAndFilters = useCallback(
    (companyId: number, filters: any) =>
      interceptRequest(
        getInvoicesByCompanyIdAndFilters,
        {},
        companyId,
        filters
      ),
    [getInvoicesByCompanyIdAndFilters]
  );

  const getFilteredInvoices = useCallback(
    (filters: any) => interceptRequest(getInvoicesByFilters, {}, filters),
    [getInvoicesByFilters]
  );

  const getInvoiceRow = useCallback(
    (id: number) => interceptRequest(getInvoiceRowById, {}, id),
    [getInvoiceRowById]
  );

  const setIsAccounted = useCallback(
    (ids: number[]) => interceptRequest(setIsAccountedToTrue, {}, { ids }),
    [setIsAccountedToTrue]
  );

  const payInvoicesByIds = useCallback(
    (paymentChannel: PaymentChannel, invoiceIds: number[]) =>
      interceptRequest(payInvoices, {}, { paymentChannel, invoiceIds }),
    [payInvoices]
  );

  const approveInvoicesByIds = useCallback(
    (ids: number[]) => interceptRequest(approveInvoices, {}, { ids }),
    [approveInvoices]
  );

  const rejectInvoicesByIds = useCallback(
    (ids: number[]) => interceptRequest(rejectInvoices, {}, { ids }),
    [rejectInvoices]
  );

  const createWithoutDocument = useCallback(
    (companyId: number) => interceptRequest(createWithoutFile, {}, companyId),
    [createWithoutFile]
  );

  const saveInvoiceData = useCallback(
    (companyId: number, invoiceId: number, requestBody: InvoicePatch) =>
      interceptRequest(
        updateInvoiceById,
        {},
        companyId,
        invoiceId,
        requestBody
      ),
    [updateInvoiceById]
  );

  const verifyCreatedDocument = useCallback(
    (companyId: number, invoiceId: number, requestBody: InvoicePatch) =>
      interceptRequest(
        verifyWithoutFileById,
        {},
        companyId,
        invoiceId,
        requestBody
      ),
    [verifyWithoutFileById]
  );

  const saveInvoiceSectionData = useCallback(
    (
      companyId: number,
      invoiceId: number,
      invoiceSection: InvoiceSectionKey,
      requestBody: InvoicePatch
    ) =>
      interceptRequest(
        updateInvoiceSectionById,
        {},
        companyId,
        invoiceId,
        invoiceSection,
        requestBody
      ),
    [updateInvoiceSectionById]
  );

  const returnToValidatedById = useCallback(
    (companyId: number, invoiceId: number) =>
      interceptRequest(returnToValidated, {}, companyId, invoiceId),
    [returnToValidated]
  );

  const updateExpenseTypeDuringApprovalById = useCallback(
    (companyId: number, invoiceId: number, id: number) =>
      interceptRequest(
        updateExpenseTypeDuringApproval,
        {},
        companyId,
        invoiceId,
        { id }
      ),
    [updateExpenseTypeDuringApproval]
  );

  return {
    addInvoice,
    getInvoiceFile,
    deleteInvoices,
    exportSelectedInvoicesForPayment,
    updateInvoice,
    verifyInvoiceSection,
    unverifyInvoiceById,
    rempromptInvoice,
    getSingleInvoiceById,
    getInvoiceFiltersByCompany,
    getInvoiceFiltersForAllCompanies,
    getInvoicesByCompanyAndFilters,
    getFilteredInvoices,
    getInvoiceRow,
    setIsAccounted,
    payInvoicesByIds,
    approveInvoicesByIds,
    rejectInvoicesByIds,
    createWithoutDocument,
    saveInvoiceData,
    verifyCreatedDocument,
    saveInvoiceSectionData,
    returnToValidatedById,
    updateExpenseTypeDuringApprovalById
  };
};
