import { RenderPage, RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import React from 'react';

export const PdfViewerWithWatermark = React.memo(
  ({
    fileUrl,
    plugins,
    watermarkText,
    defaultScale
  }: {
    fileUrl: any;
    plugins: any;
    watermarkText: string;
    defaultScale?: number;
  }) => {
    const renderPage: RenderPage = (props: RenderPageProps) => (
      <>
        {props.canvasLayer.children}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        >
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.1)',
              fontSize: `${8 * props.scale}rem`,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              transform: 'rotate(-45deg)',
              userSelect: 'none'
            }}
          >
            {watermarkText}
          </div>
        </div>
        {props.annotationLayer.children}
        {props.textLayer.children}
      </>
    );

    return (
      <Viewer
        fileUrl={fileUrl}
        renderPage={renderPage}
        plugins={plugins}
        defaultScale={defaultScale ?? 0}
      />
    );
  }
);
