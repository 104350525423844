import { TestContext, ValidationError } from 'yup';

import { Iban } from 'openapi';

import { TranslateFunction } from 'utils/interfaces/Translations';

export const uniqueIbanTest = (
  list: Iban[] | undefined,
  context: TestContext,
  translate: TranslateFunction
) => {
  if (!list) return true;

  const ibans = list.map((item) => item.iban).filter(Boolean);
  const duplicates = ibans.filter(
    (iban, index, self) => self.indexOf(iban) !== index
  );

  if (duplicates.length > 0) {
    const errors = list
      .map((item, index) => {
        if (item.iban && duplicates.includes(item.iban)) {
          return context.createError({
            path: `${context.path}[${index}]`,
            message: translate('errors.ibanUnique')
          });
        }
        return null;
      })
      .filter(Boolean);

    throw new ValidationError(errors as ValidationError[]);
  }

  return true;
};

export const uniqueIbanValidation = (
  translate: TranslateFunction,
  ibanForTest?: string,
  list?: Iban[]
) => {
  if (!list) {
    return true;
  }

  const ibans = list.map((item) => item.iban).filter(Boolean);
  const duplicates = ibans.filter((iban) => iban === ibanForTest);

  if (duplicates.length > 1) {
    return translate('errors.ibanUnique');
  }

  return true;
};
