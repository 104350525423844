import JSZip from 'jszip';

export const downloadZippedInvoices = async (
  data: string,
  fileName: string
): Promise<void> => {
  try {
    // Decode the base64 content to a binary string
    const binaryString = atob(data); // Decode base64 to binary string

    // Convert the binary string to a Uint8Array (which can be passed to JSZip)
    const arrayBuffer = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i += 1) {
      arrayBuffer[i] = binaryString.charCodeAt(i);
    }

    // Load the ArrayBuffer into JSZip
    const zip = await JSZip.loadAsync(arrayBuffer.buffer);

    // Generate the original zip file as a blob (no need to recreate the zip)
    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Create a download link for the zip file
    const url = URL.createObjectURL(zipBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Save file with the provided name
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error processing zip file:', error);
  }
};
