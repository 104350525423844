import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useUserController } from 'api/controllers/UserController';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { INITIAL_INVOICES_FILTERS } from 'utils/constants/invoices';
import { INITIAL_PAGINATION_PROPS } from 'utils/constants/paginations';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { mapPermissions } from 'utils/mappers/permissions';
import { AppRoutesEnum } from 'utils/routes';

import {
  invoicesFiltersAtom,
  invoicesPaginationAtom,
  areFiltrsVisibleAtom,
  userAtom
} from 'state/state';

interface ProtectedRouteProps {
  readonly children: JSX.Element;
  readonly path: AppRoutesEnum;
}

export const ProtectedRoute = ({
  children,
  path
}: ProtectedRouteProps): JSX.Element => {
  const { updatePermissions } = usePermissions();
  const { userLogin } = useUserController();
  const { getHomePageRoute, checkRoutePermission } = useRouteSecurity();
  const { companiesList } = useCompanies();
  const { companyId } = useParams();
  const location = useLocation();

  const prevCompanyId = useRef<string | undefined>();
  const prevPath = useRef<string>('');

  const [user, setUser] = useAtom(userAtom);
  const [filters, setFilters] = useAtom(invoicesFiltersAtom);

  const [pagination, setPagination] = useAtom(invoicesPaginationAtom);
  const [areFiltersVisible, setAreFiltersVisible] =
    useAtom(areFiltrsVisibleAtom);

  const updateLoginData = useCallback(async () => {
    const result = await userLogin();
    updatePermissions(mapPermissions(result.permissions));
    setUser(result);
  }, []);

  // TODO: Refactor this useEffect
  useEffect(() => {
    if (
      path === AppRoutesEnum.HOME &&
      Number(location.state?.reason) === HttpStatusCodes.Forbidden
    ) {
      updateLoginData();
    }

    // Check if navigating away from invoice-related pages
    const isNavigatingToInvoiceDetail =
      path === AppRoutesEnum.INVOICE_VERIFICATION ||
      path === AppRoutesEnum.COMPANY_INVOICES ||
      path === AppRoutesEnum.INVOICES ||
      path === AppRoutesEnum.INVOICES_ACCOUNTING ||
      path === AppRoutesEnum.INVOICES_APPROVAL ||
      path === AppRoutesEnum.INVOICES_PAYMENT ||
      path === AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING ||
      path === AppRoutesEnum.COMPANY_INVOICES_APPROVAL ||
      path === AppRoutesEnum.COMPANY_INVOICES_PAYMENT;

    const isOneIdUndefined =
      (prevCompanyId.current && !companyId) ||
      (!prevCompanyId.current && companyId);

    if (!isOneIdUndefined && prevCompanyId.current !== companyId) {
      setFilters(INITIAL_INVOICES_FILTERS);
      setPagination(INITIAL_PAGINATION_PROPS);
      setAreFiltersVisible(false);
    }

    if (
      prevPath.current === AppRoutesEnum.INVOICES &&
      path !== AppRoutesEnum.INVOICE_VERIFICATION
    ) {
      setFilters(INITIAL_INVOICES_FILTERS);
      setPagination(INITIAL_PAGINATION_PROPS);
      setAreFiltersVisible(false);
    }

    prevCompanyId.current = companyId as string | undefined;
    prevPath.current = path;

    if (!isNavigatingToInvoiceDetail) {
      // Reset filters and pagination if navigating away from invoice detail pages
      setFilters(INITIAL_INVOICES_FILTERS);
      setPagination(INITIAL_PAGINATION_PROPS);
      setAreFiltersVisible(false);
    }
  }, [path, location, setFilters, setPagination, updateLoginData]);

  const hasRoutePermission = useMemo(
    () => checkRoutePermission(path),
    [path, checkRoutePermission]
  );

  const companyExists = useMemo(
    () =>
      !companyId ||
      companiesList.some((company) => company.id === Number(companyId)),
    [companiesList, companyId]
  );

  if (!hasRoutePermission || !companyExists) {
    return <Navigate to={getHomePageRoute()} />;
  }

  return children;
};
