export const formSmallContainer = {
  display: 'flex',
  gap: '16px'
};

export const halfWidth = {
  width: '50%'
};

export const submitButton = {
  width: '45%',
  padding: '18px 0'
};

export const fullFieldWidth = {
  width: '100%'
};

export const arrayFieldWrapper = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 3
};

export const arrayFieldFormWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%'
};

export const arrayFieldFormContainer = {
  display: 'flex',
  alignItems: 'top',
  width: '100%'
};

export const labelSx = {
  fontSize: '0.75rem',
  transform: 'translate(12px, 12px) scale(1)',
  transition: 'transform 200ms ease-in-out',
  '&.Mui-focused': {
    transform: 'translate(12px, -6px) scale(0.75)'
  },
  '&.MuiInputLabel-shrink': {
    fontSize: '12px',
    transform: 'translate(14px, -6px) scale(0.75)'
  }
};

export const SIDEBAR_EXPANDED_WIDTH = '300px';
export const SIDEBAR_CLOSED_WIDTH = '120px';
