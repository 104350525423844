import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { UserManage, UserRoles } from 'openapi';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { mapUserValues } from 'utils/mappers/user';

import { formSmallContainer, submitButton } from 'styles/components/Common';

interface CreateUpdateUserFormProps {
  handleSave: (values: UserManage) => void;
  initialValues?: UserManage;
  isOnlySuperAdmin?: boolean;
  userEmails: string[];
}

export const CreateUpdateUserForm = ({
  handleSave,
  initialValues,
  isOnlySuperAdmin,
  userEmails
}: CreateUpdateUserFormProps) => {
  const { translate } = useTranslations();
  const { companiesList } = useCompanies();

  const initialValuesMapped = useMemo(
    () => mapUserValues(initialValues),
    [initialValues]
  );

  const schema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().trim().required(translate('errors.userName')),
      email: Yup.string()
        .trim()
        .email(translate('errors.email'))
        .notOneOf(userEmails, translate('errors.emailExists'))
        .required(translate('errors.email')),
      companyIds: Yup.array(Yup.number().required())
        .required()
        .when('role', (role, currentSchema) => {
          return role[0] === UserRoles.SUPER_ADMIN
            ? currentSchema
            : currentSchema.min(1, translate('errors.userCompanies'));
        }),
      role: Yup.string()
        .required()
        .oneOf(Object.values(UserRoles))
        .test(
          'isLastSuperAdmin',
          translate('errors.isLastSuperAdmin', {
            superAdminText: UserRoles.SUPER_ADMIN
          }),
          (value: UserRoles) => {
            if (
              isOnlySuperAdmin &&
              initialValuesMapped.role === UserRoles.SUPER_ADMIN
            ) {
              return value === UserRoles.SUPER_ADMIN;
            }
            return true;
          }
        )
    });
  }, [translate, isOnlySuperAdmin, initialValuesMapped.role]);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    control,
    formState: { errors }
  } = useForm<UserManage>({
    defaultValues: initialValuesMapped,
    resolver: yupResolver(schema)
  });

  const currentRole = watch('role');

  useEffect(() => {
    if (currentRole === UserRoles.SUPER_ADMIN) {
      setValue('companyIds', []);
    }
  }, [currentRole, setValue]);

  return (
    <Box
      component="form"
      style={{ ...formSmallContainer, flexDirection: 'column' }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Box height={75}>
        <TextField
          {...register('name')}
          fullWidth
          label={`${translate('labels.name')}*`}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </Box>
      <Box height={75}>
        <TextField
          {...register('email')}
          fullWidth
          label={`${translate('labels.email')}*`}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      </Box>
      <Box height={75}>
        <FormControl fullWidth variant="outlined" error={!!errors.role}>
          <InputLabel
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default
            })}
          >
            {`${translate('labels.role')}*`}
          </InputLabel>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger('role');
                }}
              >
                {Object.values(UserRoles).map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>{errors.role?.message}</FormHelperText>
        </FormControl>
      </Box>
      {currentRole !== UserRoles.SUPER_ADMIN && (
        <Box minHeight={80}>
          <FormControl fullWidth variant="outlined" error={!!errors.companyIds}>
            <InputLabel
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default
              })}
            >
              {`${translate('labels.companies')}*`}
            </InputLabel>
            <Controller
              name="companyIds"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            companiesList.find(
                              (company) => company.id === value
                            )?.alias
                          }
                        />
                      ))}
                    </Box>
                  )}
                >
                  {companiesList.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      <Checkbox
                        checked={
                          !!watch('companyIds').find((id) => company.id === id)
                        }
                      />
                      {company.alias}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.companyIds?.message}</FormHelperText>
          </FormControl>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button variant="contained" sx={submitButton} type="submit">
          {translate('buttons.save')}
        </Button>
      </Box>
    </Box>
  );
};
