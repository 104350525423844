import { v4 as uuidv4 } from 'uuid';

import {
  Counterparty,
  CounterpartyApproval,
  CounterpartyApprovalPatch,
  CounterpartyCreateUpdate,
  CounterpartySortColumns
} from 'openapi';

import {
  COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
  INITIAL_IBAN_ITEM
} from 'utils/constants/constants';

export const mapCounterPartyValues = (
  counterparty?: Counterparty
): CounterpartyCreateUpdate => {
  if (!counterparty) {
    return {
      name: '',
      registrationNumber: '',
      isWithoutRegistrationNumber: false,
      isValidated: false,
      vatNumber: '',
      expenseTypeId: '',
      ibanList: [
        {
          ...INITIAL_IBAN_ITEM,
          ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
          tmpId: uuidv4()
        }
      ]
    } as unknown as CounterpartyCreateUpdate;
  }

  const values = {
    name: counterparty.name || '',
    vatNumber: counterparty.vatNumber || '',
    isWithoutRegistrationNumber: counterparty.isWithoutRegistrationNumber,
    isValidated: counterparty.isValidated,
    registrationNumber: counterparty.isWithoutRegistrationNumber
      ? ''
      : counterparty.registrationNumber || '',
    expenseTypeId: counterparty.expenseType?.id,
    ibanList:
      counterparty?.ibanList && counterparty.ibanList.length > 0
        ? counterparty?.ibanList?.map((iban) => ({
            ...iban,
            tmpId: uuidv4()
          }))
        : [
            {
              ...INITIAL_IBAN_ITEM,
              ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
              tmpId: uuidv4()
            }
          ]
  };

  return values as CounterpartyCreateUpdate;
};

export const getMappedCounterParties = (counterParties: Counterparty[]) =>
  counterParties.map((counterparty: Counterparty) => {
    return {
      ...counterparty,
      [CounterpartySortColumns.NAME]: counterparty.name,
      [CounterpartySortColumns.REGISTRATION_NUMBER]:
        counterparty.registrationNumber,
      [CounterpartySortColumns.VAT_NUMBER]: counterparty.vatNumber
    };
  });

export const mapCounterpartyApprovalValues = (
  values: CounterpartyApproval
): CounterpartyApprovalPatch & { name: string } => {
  return {
    id: values.id,
    name: values.name as string,
    approvalThreshold: values.approvalThreshold,
    approverIds: values.approvers?.map((approver) => approver.id) || []
  };
};
