import { useCallback } from 'react';

import { IbanConsent, OpenBankingService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useOpenBankingController = () => {
  const { interceptRequest } = useInterceptor();

  const { consentIban, deleteIban, signUp, signOut } = OpenBankingService;

  const consentIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number, requestBody: IbanConsent) =>
      interceptRequest(consentIban, {}, companyId, ibanId, requestBody),
    [consentIban]
  );

  const deleteIbanByCompanyId = useCallback(
    (companyId: number, ibanId: number) =>
      interceptRequest(deleteIban, {}, companyId, ibanId),
    [deleteIban]
  );

  const signUpToOpenBanking = useCallback(
    (companyId: number) => interceptRequest(signUp, {}, companyId),
    [signUp]
  );

  const signOutFromOpenBanking = useCallback(
    (companyId: number) => interceptRequest(signOut, {}, companyId),
    [signOut]
  );

  return {
    consentIbanByCompanyId,
    deleteIbanByCompanyId,
    signUpToOpenBanking,
    signOutFromOpenBanking
  };
};
