import moment from 'moment';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { KeycloakConfigDefault } from 'virava';

import { OpenAPI } from 'openapi';

import { MOMENT_LOCALE_CONFIG } from 'utils/constants/momentConfigs';

import { viravaDefault } from './config/authConfig';
import { App } from './main';
import './index.css';
import reportWebVitals from './reportWebVitals';

moment.defineLocale('bg', MOMENT_LOCALE_CONFIG);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(() => {})
      .catch(() => {});
  });
}

async function getConfig() {
  const response = await fetch('/env.config.json');
  const config = await response.json();

  const authConfig: KeycloakConfigDefault = {
    clientId: config.keycloakClientId,
    baseUrl: config.keycloakBaseUrl,
    realm: config.keycloakRealm
  };
  viravaDefault.init(authConfig);
  await viravaDefault.login();

  OpenAPI.BASE = config.openApiBaseUrl;
  OpenAPI.TOKEN = async () => {
    const token = localStorage.getItem('access_token');
    return token ?? '';
  };
}

getConfig().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <Router>
      <App />
    </Router>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
