import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { CounterpartySortColumns } from 'openapi';

import {
  ACTIONS,
  COLUMNS_DEFAULT_OPTIONS,
  IBANS_LIST,
  VALIDATED
} from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getCounterPartyColumns = (
  translate: TranslateFunction,
  getCompanyRegistrationNumberValue: (params: GridValueGetterParams) => string
): GridColDef[] => [
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: CounterpartySortColumns.NAME,
    headerName: translate('labels.name'),
    minWidth: 250
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: VALIDATED,
    headerName: translate('labels.isValidated'),
    minWidth: 90,
    maxWidth: 90,
    align: 'center',
    headerAlign: 'center',
    sortable: false
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: CounterpartySortColumns.REGISTRATION_NUMBER,
    headerName: translate('labels.companyRegistrationNumberShort'),
    minWidth: 200,
    maxWidth: 250,
    valueGetter: getCompanyRegistrationNumberValue
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: CounterpartySortColumns.VAT_NUMBER,
    headerName: translate('labels.vatNumber'),
    minWidth: 250,
    maxWidth: 300
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: IBANS_LIST,
    headerName: translate('labels.iban'),
    sortable: false,
    disableColumnMenu: true,
    minWidth: 350
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: CounterpartySortColumns.EXPENSE_TYPE,
    headerName: translate('labels.expenseType'),
    valueGetter: ({ row }) => row.expenseType?.name,
    minWidth: 200,
    maxWidth: 400
  },
  {
    ...COLUMNS_DEFAULT_OPTIONS,
    field: ACTIONS,
    headerName: '',
    flex: 0.1,
    align: 'center',
    headerAlign: 'center',
    sortable: false
  }
];
