import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useUserController } from 'api/controllers/UserController';

import { Company } from 'openapi';

import { CreateUpdateCompanyForm } from 'components/forms/CreateUpdateCompanyForm';
import { Layout } from 'components/Layout/Layout';

import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

export const AddCompany: React.FC = (): JSX.Element | null => {
  const { translate } = useTranslations();
  const { getCompanyDetailsById, deleteCompany } = useCompanyController();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState<Company | undefined>();
  const { getHomePageRoute } = useRouteSecurity();

  const onDelete = async () => {
    await deleteCompany(Number(companyId));
    navigate(getHomePageRoute());
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const compDetails = await getCompanyDetailsById(Number(companyId));
      setCompanyDetails(compDetails[0]);
    };

    if (companyId) {
      fetchDetails();
    }
  }, []);

  return (
    <Layout pageTitle={translate('pages.addCompany')}>
      <Box sx={mediumVerificationFieldWidth}>
        <CreateUpdateCompanyForm
          companyDetails={companyDetails}
          onDelete={onDelete}
        />
      </Box>
    </Layout>
  );
};
