import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowSelectionModel
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useExpenseTypeController } from 'api/controllers/ExpenseTypeController';
import { useUserController } from 'api/controllers/UserController';

import { ExpenseType, ExpenseTypeCreate, ResourceTypes, User } from 'openapi';

import { CreateUpdateExpenseTypeForm } from 'components/forms/CreateUpdateExpenseTypeForm';
import { ListHoverGridCell } from 'components/shared/gridCells/ListHoverGridCell';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { Modal } from 'components/shared/Modal/Modal';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  ACTIONS,
  ACTIVE,
  APPROVERS,
  CHECK,
  DEFAULT_GRID_ROW_HEIGHT
} from 'utils/constants/constants';
import { Scopes } from 'utils/enums/Scopes';
import { getExpenseTypeColumns } from 'utils/helpers/expenseTypeHelpers';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import { modalButtonsWrapper } from 'styles/pages/InvoiceVerificationStyle';

interface ExpenseTypesDataGridProps {
  ignoreCreating?: boolean;
}

export const ExpenseTypesDataGrid = ({
  ignoreCreating
}: ExpenseTypesDataGridProps) => {
  const { companyId } = useParams();
  const { translate } = useTranslations();
  const { checkPermission } = usePermissions();
  const { permissions } = usePermissions();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();
  const {
    isOpen: isCreateEditModalOpen,
    closeModal: closeCreateEditModal,
    openModal: openCreateEditModal
  } = useModal();

  const {
    getExpenseTypes,
    createNewExpenseType,
    editExpenseType,
    deleteExpenseTypes,
    toggleActiveStatusForExpenseTypes
  } = useExpenseTypeController();

  const { getApprovers } = useUserController();

  const [expenseTypeForEdit, setExpenseTypeForEdit] = useState<ExpenseType>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>();
  const [selectedExpenseId, setSelectedExpenseId] = useState<number>();
  const [approvers, setApprovers] = useState<User[]>();
  const [isActiveToggleProcessing, setIsActiveToggleProcessing] =
    useState(false);

  const fetchExpenseTypes = useCallback(async () => {
    const data = await getExpenseTypes(Number(companyId));
    setExpenseTypes(data);
  }, [getExpenseTypes, companyId]);

  const fetchApprovers = useCallback(async () => {
    if (
      checkPermission(ResourceTypes.APPROVER_SETTINGS, [
        Scopes.CREATE,
        Scopes.DELETE
      ])
    ) {
      const data = await getApprovers(Number(companyId));
      setApprovers(data);
    } else {
      setApprovers([]);
    }
  }, [getExpenseTypes, companyId]);

  const handleCloseModal = () => {
    closeCreateEditModal();
    setExpenseTypeForEdit(undefined);
  };

  const handleOpenCreateEditModal = (params: GridCellParams) => {
    if (
      params.field !== CHECK &&
      params.field !== ACTIONS &&
      checkPermission(ResourceTypes.EXPENSE_TYPES, [Scopes.UPDATE]) &&
      params.row.isActive
    ) {
      setExpenseTypeForEdit(params.row);
      openCreateEditModal();
    }
  };

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams) =>
      !params.row.isActive ? 'inactive-grid-row' : '',
    []
  );

  const handleSubmit = useCallback(
    async (expenseType: ExpenseTypeCreate) => {
      const trimmedExpenseType = {
        ...expenseType,
        name: expenseType.name.trim()
      };
      handleCloseModal();

      if (expenseTypeForEdit) {
        await editExpenseType(
          Number(companyId),
          Number(expenseTypeForEdit.id),
          trimmedExpenseType
        );
      } else {
        await createNewExpenseType(Number(companyId), expenseType);
      }

      await fetchExpenseTypes();
    },
    [
      expenseTypeForEdit,
      editExpenseType,
      createNewExpenseType,
      companyId,
      expenseTypes
    ]
  );

  const handleCloseDeleteModal = () => {
    closeDeleteModal();
    setSelectedExpenseId(undefined);
  };

  const handleConfirmDelete = useCallback(async () => {
    await deleteExpenseTypes(
      Number(companyId),
      selectedExpenseId ? [selectedExpenseId] : (selectedRows as number[])
    );
    handleCloseDeleteModal();
    await fetchExpenseTypes();
  }, [
    deleteExpenseTypes,
    companyId,
    selectedExpenseId,
    handleCloseDeleteModal
  ]);

  const modalTitle = useMemo(
    () =>
      expenseTypeForEdit
        ? translate('labels.expenseTypeEdit')
        : translate('labels.expenseTypeAdd'),
    [expenseTypeForEdit, translate]
  );

  const handleActiveStatusToggle = useCallback(
    async (expenseType: ExpenseType) => {
      if (isActiveToggleProcessing) {
        return;
      }
      setIsActiveToggleProcessing(true);

      try {
        await toggleActiveStatusForExpenseTypes(Number(companyId), {
          ids: [Number(expenseType.id)]
        });
        if (expenseType.isActive) {
          setSelectedRows((rows) => rows.filter((id) => id !== expenseType.id));
        }

        await fetchExpenseTypes();
      } finally {
        setIsActiveToggleProcessing(false);
      }
    },
    [
      toggleActiveStatusForExpenseTypes,
      fetchExpenseTypes,
      companyId,
      isActiveToggleProcessing
    ]
  );

  const columns = useMemo(
    () =>
      getExpenseTypeColumns(translate)
        .map((column) => {
          switch (column.field) {
            case ACTIONS:
              return checkPermission(ResourceTypes.EXPENSE_TYPES, [
                Scopes.DELETE
              ]) && !ignoreCreating
                ? {
                    ...column,
                    renderCell: ({ row }: any) => (
                      <Tooltip
                        placement="left"
                        title={
                          !row.isDeletable
                            ? translate('messages.disabledDeleteExpenseType')
                            : ''
                        }
                      >
                        <span>
                          <GridActionsCellItem
                            disabled={!row.isDeletable || !row.isActive}
                            icon={
                              <DeleteTwoToneIcon
                                sx={(theme) => ({
                                  color:
                                    row.isDeletable && row.isActive
                                      ? theme.palette.error.main
                                      : 'default',
                                  fontSize: '1.5rem'
                                })}
                                titleAccess={translate('labels.delete')}
                              />
                            }
                            label="Delete"
                            onClick={() => {
                              if (row.isActive) {
                                openDeleteModal();
                              }
                              setSelectedExpenseId(row.id);
                            }}
                          />
                        </span>
                      </Tooltip>
                    )
                  }
                : null;
            case APPROVERS: {
              return {
                ...column,
                renderCell: ({ row }: GridRenderCellParams) => {
                  const approversList = row.approvers.map(
                    (approver: User) => approver.name
                  );
                  return <ListHoverGridCell list={approversList} />;
                }
              };
            }
            case ACTIVE: {
              return ignoreCreating
                ? null
                : {
                    ...column,
                    renderCell: ({ row }: GridRenderCellParams) => {
                      return (
                        <Switch
                          size="small"
                          checked={row.isActive}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleActiveStatusToggle(row as ExpenseType);
                          }}
                        />
                      );
                    }
                  };
            }
            default:
              return column;
          }
        })
        .filter((col) => col) as GridColDef[],
    [translate, openDeleteModal]
  );

  useEffect(() => {
    fetchExpenseTypes();
    fetchApprovers();
  }, [fetchExpenseTypes]);

  return expenseTypes && approvers ? (
    <Paper elevation={4} sx={commonDataGridContainerStyle}>
      <Modal
        headerTitle={
          ignoreCreating ? translate('approvals.editExpenseType') : modalTitle
        }
        isOpen={isCreateEditModalOpen}
        hide={handleCloseModal}
        maxWidth="sm"
      >
        <CreateUpdateExpenseTypeForm
          initialValues={expenseTypeForEdit}
          handleSubmit={handleSubmit}
          approvers={approvers}
          ignoreCreating={ignoreCreating}
        />
      </Modal>

      <DataGrid
        columns={columns}
        getRowId={(row) => row.id || 0}
        rows={expenseTypes}
        rowHeight={DEFAULT_GRID_ROW_HEIGHT}
        sx={invoicesDataGrid}
        checkboxSelection={permissions.EXPENSE_TYPES.delete && !ignoreCreating}
        disableRowSelectionOnClick
        pageSizeOptions={[]}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
        getRowClassName={getRowClassName}
        onCellClick={
          permissions.APPROVER_SETTINGS.update
            ? handleOpenCreateEditModal
            : undefined
        }
        isRowSelectable={(params) => params.row.isActive}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
        slots={{
          toolbar: ignoreCreating ? null : GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleAddClick: checkPermission(ResourceTypes.EXPENSE_TYPES, [
              Scopes.CREATE
            ])
              ? openCreateEditModal
              : null,
            handleDeleteClick: checkPermission(ResourceTypes.EXPENSE_TYPES, [
              Scopes.DELETE
            ])
              ? openDeleteModal
              : null,
            isDefaultToolbarHidden: true,
            selectedRows,
            entries: expenseTypes,
            deleteButtonTooltip: translate(
              'messages.disabledMassDeleteExpenseTypes'
            )
          }
        }}
      />
      {isDeleteModalOpen && (
        <Modal
          headerTitle={translate('titles.delete')}
          isOpen={isDeleteModalOpen}
          hide={handleCloseDeleteModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {selectedRows.length === 1 || selectedExpenseId
              ? translate('messages.deleteExpenseTypeConfirmation')
              : translate('messages.deleteExpenseTypesConfirmation', {
                  numberOfExpenseTypes: selectedRows.length.toString()
                })}
          </Typography>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={handleCloseDeleteModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              {translate('buttons.delete')}
            </Button>
          </Box>
        </Modal>
      )}
    </Paper>
  ) : (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
