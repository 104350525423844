import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

import { useTranslations } from 'context/TranslationContext';

import {
  closeIcon,
  dialogTitle,
  iconButton,
  modal
} from 'styles/components/ModalStyle';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  isConfirmButtonDisabled?: boolean;
  children?: string | JSX.Element | JSX.Element[];
  confirmationString?: string;
  showInputField?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sx?: { [key: string]: string };
}

export const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  children,
  confirmationString,
  showInputField = false,
  isConfirmButtonDisabled,
  size = 'sm',
  sx
}: ConfirmationDialogProps) => {
  const { translate } = useTranslations();

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    if (!showInputField) {
      onConfirm();
      return;
    }

    if (inputValue === confirmationString) {
      onConfirm();
      setInputValue('');
      setError(false);
      onClose();
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    setInputValue('');
    setError(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} sx={{ ...modal, ...sx }} maxWidth={size}>
      <DialogTitle sx={dialogTitle}>
        {title}
        <IconButton onClick={onClose} sx={iconButton}>
          <Close sx={closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: '1.5rem' }}>
        {showInputField ? (
          <>
            {children}
            <Box
              sx={{ display: 'inline', margin: '0 0.5rem', fontWeight: 'bold' }}
            >
              {confirmationString}
            </Box>
            <TextField
              autoFocus
              margin="dense"
              label={translate('labels.confirmationText')}
              fullWidth
              variant="standard"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              error={error}
              helperText={error && translate('errors.valuesDoNotMatch')}
            />
          </>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {translate('buttons.cancel')}
        </Button>
        <Button
          disabled={isConfirmButtonDisabled}
          onClick={handleConfirm}
          variant="contained"
        >
          {translate('buttons.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
