import { Box, CircularProgress, Grid, Paper, Stack } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams,
  GridRowClassNameParams
} from '@mui/x-data-grid';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useBudgetingController } from 'api/controllers/BudgetingController';
import { useExpenseTypeController } from 'api/controllers/ExpenseTypeController';

import { ExpenseType, ExpenseTypeBudget } from 'openapi';

import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { Stepper } from 'components/shared/Stepper/Stepper';

import { useTranslations } from 'context/TranslationContext';

import { useYearSelector } from 'hooks/useYearSelector';

import {
  DEFAULT_GRID_ROW_HEIGHT,
  EXPENSE_TYPE_NAME,
  INTEGER_INPUT_MAX_LENGTH
} from 'utils/constants/constants';
import { getBudgetingColumns } from 'utils/helpers/budgetingHelpers';
import { processMoneyInput } from 'utils/helpers/moneyHelper';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

export const Budgeting = () => {
  const { companyId } = useParams();
  const { translate } = useTranslations();
  const { getBudgets, updateBudget } = useBudgetingController();
  const { getExpenseTypes } = useExpenseTypeController();
  const { selectedYear, availableYears, setExistingYears, setSelectedYear } =
    useYearSelector(false);

  const [budgets, setBudgets] = useState<ExpenseTypeBudget[]>();
  const [expenseTypes, setExpenseTypes] = useState<{
    [key: string]: ExpenseType;
  }>();
  const [editCellIndexOfMonth, setEditCellIndexOfMonth] = useState<
    number | null
  >(null);

  const handleYearChange = (step: number) => {
    setSelectedYear(availableYears[step]);
  };

  const fetchBudgets = useCallback(async () => {
    const response = await getBudgets(Number(companyId), selectedYear);
    setBudgets(response.expenseTypeBudgets);
    setExistingYears(response.years);
  }, [getBudgets, companyId, selectedYear]);

  const fetchExpenseTypes = useCallback(async () => {
    const response = await getExpenseTypes(Number(companyId));
    const mappedExpenseTypes = response.reduce((acc, curr) => {
      return { ...acc, [curr.id!]: curr };
    }, {} as { [key: string]: ExpenseType });
    setExpenseTypes(mappedExpenseTypes);
  }, []);

  const handleBudgetUpdate = async (
    updatedRow: ExpenseTypeBudget,
    oldRow: ExpenseTypeBudget
  ) => {
    try {
      const rowToUpdate = updatedRow.budgets[editCellIndexOfMonth!];
      await updateBudget(Number(companyId), rowToUpdate.budgetId!, {
        amount: rowToUpdate.amount
      });

      return updatedRow;
    } catch (error) {
      return oldRow;
    }
  };

  const selectedYearIndex = useMemo(
    () => availableYears.indexOf(selectedYear),
    [availableYears, selectedYear]
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams) => {
      return !!expenseTypes && !expenseTypes![params.row.expenseTypeId].isActive
        ? 'inactive-grid-row'
        : '';
    },
    [expenseTypes]
  );

  const columns = useMemo((): GridColDef[] => {
    return getBudgetingColumns(translate).map((column) => {
      const month = Number(column.field);

      if (!Number.isNaN(month)) {
        return {
          ...column,
          renderEditCell: (params: GridRenderEditCellParams) => (
            <GridEditInputCell
              {...params}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setEditCellIndexOfMonth(month - 1);
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: processMoneyInput(
                    e.target.value,
                    params.value,
                    INTEGER_INPUT_MAX_LENGTH
                  )
                });
              }}
            />
          )
        };
      }

      if (column.field === EXPENSE_TYPE_NAME) {
        return {
          ...column,
          valueGetter: ({ row }) => expenseTypes![row.expenseTypeId].name
        };
      }

      return column;
    });
  }, [translate, expenseTypes]);

  useEffect(() => {
    fetchBudgets();
    fetchExpenseTypes();
  }, [selectedYear]);

  return !!budgets && !!expenseTypes ? (
    <Grid container direction="column" wrap="nowrap" height="100%">
      {!!availableYears.length && (
        <Stepper
          steps={availableYears}
          activeStepIndex={selectedYearIndex}
          setActiveStep={handleYearChange}
        />
      )}
      <Box sx={commonDataGridContainerStyle}>
        <Paper elevation={4} sx={commonDataGridContainerStyle}>
          <DataGrid
            rows={budgets}
            columns={columns}
            getRowId={(row) => row.expenseTypeId}
            rowHeight={DEFAULT_GRID_ROW_HEIGHT}
            pageSizeOptions={[]}
            processRowUpdate={handleBudgetUpdate}
            sx={invoicesDataGrid}
            getRowClassName={getRowClassName}
            isCellEditable={(params) =>
              !!expenseTypes![params.row.expenseTypeId].isActive
            }
            localeText={{
              noRowsLabel: translate('labels.noData')
            }}
            slots={{
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
                isDefaultToolbarHidden: true,
                entries: budgets
              }
            }}
          />
        </Paper>
      </Box>
    </Grid>
  ) : (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
