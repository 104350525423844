import { Box, BoxProps } from '@mui/material';

import { severityPillRootStyles } from 'styles/components/InvoicesDataGridStyle';

interface SeverityPillProps extends BoxProps {
  backgroundColor?: string;
}

export const SeverityPill = ({
  children,
  backgroundColor,
  color,
  ...other
}: SeverityPillProps) => (
  <Box
    sx={{
      ...severityPillRootStyles,
      backgroundColor,
      color
    }}
    {...other}
  >
    {children}
  </Box>
);
