import { GridColDef } from '@mui/x-data-grid';

import { PayrollSummary } from 'openapi';

import { COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';
import { formatMoney } from 'utils/helpers/moneyHelper';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getPayrollColumns = (
  translate: TranslateFunction
): GridColDef[] => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'name',
      headerName: translate('labels.name')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'nationalId',
      headerName: translate('labels.nationalId'),
      type: 'string'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'contractNumber',
      headerName: translate('labels.contractNumber'),
      type: 'string'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'position',
      headerName: translate('labels.position'),
      type: 'string'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'department',
      headerName: translate('labels.department'),
      type: 'string'
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'baseSalary',
      headerName: translate('labels.baseSalary'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'totalAccruals',
      headerName: translate('labels.totalAccruals'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'insuranceCost',
      headerName: translate('labels.insuranceCost'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'withheldTax',
      headerName: translate('labels.withheldTax'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'amountToBeReceived',
      headerName: translate('labels.amountToBeReceived'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'insuranceCoveredByEmployer',
      headerName: translate('labels.insuranceCoveredByEmployer'),
      type: 'number',
      valueFormatter: (params) =>
        params.value && formatMoney(params.value as number)
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'totalExpensesForEmployer',
      headerName: translate('labels.totalExpensesForEmployer'),
      type: 'number',
      valueFormatter: (params) => params.value && formatMoney(params.value)
    }
  ];
};

export const getPayrollSummaryTotal = (
  summaries: PayrollSummary[],
  year: number,
  month: number
): number => {
  const yearSummary = summaries.find((summary) => summary.year === year);
  if (!yearSummary) {
    return 0;
  }

  const monthSummary = yearSummary.months.find(
    (monthSum) => monthSum.month === month
  );
  if (!monthSummary) {
    return 0;
  }

  return monthSummary.totalExpensesForEmployer || 0;
};
